<script setup lang="ts">
defineProps({
    title: String,
    content: String,
    footer: String
});

defineOptions({
    inheritAttrs: false
});

const modalVisible = defineModel();
</script>

<template>
    <Teleport to="#teleports">
        <Transition name="modal">
            <div
                v-if="modalVisible"
                v-bind="$attrs"
                class="modal"
            >
                <div class="modal-content">
                    <h1
                        class="modal-content-header"
                        v-if="$slots.header"
                    >
                        <slot name="header">
                            {{ title }}
                        </slot>
                    </h1>
                    <div
                        class="modal-content-body"
                        v-if="$slots.body"
                    >
                        <slot name="body">
                            {{ content }}
                        </slot>
                    </div>
                    <div
                        class="modal-content-footer"
                        v-if="$slots.footer"
                    >
                        <slot name="footer">
                            {{ footer }}
                        </slot>
                    </div>
                    <button
                        @click="modalVisible = false"
                        aria-label="Закрити"
                        class="modal-close"
                    >
                        <IconCross />
                    </button>
                </div>
            </div>
        </Transition>
    </Teleport>
</template>

<style lang="scss">
@use '~/assets/styles/mixins.scss';

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 0 .25em;
    transition: opacity 0.4s ease;
    text-align: center;
    z-index: 100;

    $close-button-size: 1.5em;

    >.modal-content {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        background-color: #FFFFFF;
        padding: ($close-button-size + calc($close-button-size / 2)) max(3%, 1em) 1.5em max(3%, 1em);
        margin: 1.75em auto;
        border-radius: .75em;
        width: max-content;
        max-width: calc(100% - 1.5em);
        max-height: calc(100vh - 3.25em);
        overflow: auto;
        color: #484B4C;
        transition: transform 0.4s ease;

        >.modal-content-header {
            text-align: center;
            margin-bottom: .5em;
            font-weight: bold;
            font-size: 2.5em;
            line-height: 120%;
            color: #212E42;
            border: 0;
            overflow: hidden;
            text-overflow: ellipsis;

            @include mixins.respond-to(phone) {
                font-size: clamp(1.5em, 7vw, 2.5em);
            }
        }

        >.modal-content-footer {
            margin-top: 1.25em;
            padding-top: 1.25em;
            border-top: 1px solid #E7E9E9;
            display: flex;
            flex-direction: column;
        }

        .form-link {
            font-weight: 500;
            font-size: 1em;
            line-height: 120%;
            color: #10A5AA;
            cursor: pointer;
        }

        button.form-link {
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        :not(button).form-link {
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }

    .modal-close {
        position: absolute;
        top: calc($close-button-size / 2);
        right: calc($close-button-size / 2);
        cursor: pointer;
        width: $close-button-size;
        height: $close-button-size;

        * {
            stroke: #212E42;
        }
    }

    &.login-form {
        >.modal-content {
            padding: ($close-button-size + (calc($close-button-size / 2))) 4em;

            @include mixins.respond-to(phone) {
                width: min(calc(70vw + 10vh), 100%);
                padding: max(5%, ($close-button-size + (calc($close-button-size / 2)))) 5% 5% 5%;
            }
        }
    }

    &::before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
    }
}

.modal-enter-from,
.modal-leave-to {
    opacity: 0;

    >.modal-content {
        transform: translateY(-100%);
    }
}
</style>
