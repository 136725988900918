<script setup lang='ts'>
const emit = defineEmits(['showRegistration']);

const modalVisible = defineModel();

const { login } = useUser();
const { getValue, getErrors } = useForm();

const formNamespace = "loginForm";
const formPhoneInputId = "userPhoneNumber";
const formPasswordInputId = "userPassword";
const storePhoneOptions: Form.Options = {
    id: formPhoneInputId,
    namespace: formNamespace
};
const storePasswordOptions: Form.Options = {
    id: formPasswordInputId,
    namespace: formNamespace
};

const phone = computed(() => getValue(storePhoneOptions));
const password = computed(() => getValue(storePasswordOptions));

const formErrors = computed(() => getErrors(formNamespace));
const formFilled = computed(() => {
    for (const key in formErrors.value) {
        if (formErrors.value[key]) return false;
    }

    return true;
});

const handleSubmit = async () => {
    if (!formFilled.value) return;

    window.localStorage.removeItem('token');

    const response = await login({
        phone: phone.value?.replace(/[()\-\s]/g, '') as string,
        password: password.value as string
    });

    if (response?.token) modalVisible.value = false;
};
</script>

<template>
    <Modal
        v-model="modalVisible"
        class="login-form"
    >
        <template #header>
            Авторизація
        </template>

        <template #body>
            <form @submit.prevent="handleSubmit">
                <CommonFormInput
                    type="phone"
                    label="Номер телефону"
                    :id="formPhoneInputId"
                    :namespace="formNamespace"
                    :required="true"
                    :minlength=19
                    autocomplete="user-mobile-phone"
                />

                <CommonFormInput 
                    type="password" 
                    :id="formPasswordInputId" 
                    :namespace="formNamespace" 
                    :required="true" 
                    :minlength=8
                    autocomplete="current-password" 
                    placeholder="Введіть ваш пароль" 
                />

                <button
                    class="submit"
                    :disabled="!formFilled"
                >
                    Увійти
                </button>
            </form>
        </template>

        <template #footer>
            <button
                class="form-link"
                @click.prevent=""
            >
                Забули пароль?
            </button>
            <button
                class="form-link"
                @click.prevent="emit('showRegistration')"
            >
                Зареєструватись
            </button>
        </template>
    </Modal>
</template>

<style lang="scss">
.login-form {
    .submit {
        cursor: pointer;
        display: block;
        background: #EE3936;
        border-radius: .75em;
        font-weight: 500;
        line-height: 100%;
        color: #FFFFFF;
        padding: 1em;
        width: 100%;
        text-align: center;
        transition: background .3s ease;

        &:hover {
            background: #CF3836;
        }

        &:disabled {
            background: #4bc2c64d;
        }
    }

    .form-link:not(:last-child) {
        margin-bottom: 1.5em;
    }
}
</style>
