<template>
    <svg
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M18.375 8.75C18.375 14.875 10.5 20.125 10.5 20.125C10.5 20.125 2.625 14.875 2.625 8.75C2.625 6.66142 3.45469 4.65838 4.93153 3.18153C6.40838 1.70469 8.41142 0.875 10.5 0.875C12.5886 0.875 14.5916 1.70469 16.0685 3.18153C17.5453 4.65838 18.375 6.66142 18.375 8.75Z"
            stroke="#262626"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M10.5 11C11.8807 11 13 9.88071 13 8.5C13 7.11929 11.8807 6 10.5 6C9.11929 6 8 7.11929 8 8.5C8 9.88071 9.11929 11 10.5 11Z"
            stroke="#262626"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>
