export function useCategory() {
    const { public: { apiBase: APIPrefix } } = useRuntimeConfig();

    const rootCategories = useState<Categories.RootCategory[]>('rootCategories', () => []);
    const categories = useState<{
        [key: Categories.Category['id']]: Categories.Category | null;
    }>('categories', () => ({}));

    const fetchCategory = async (id: Categories.Category['id'], depth: number | null = null) => {
        const params: Record<string, any> = {};

        if (typeof depth === 'number') {
            params['depth'] = depth;
        }

        return await $fetch<Categories.Category>(
            `${APIPrefix}/categories/${id}`,
            {
                method: 'GET',
                params,
            }
        );
    };

    const fetchRootCategories = async () => {
        return await $fetch<Categories.RootCategory[]>(
            `${APIPrefix}/categories`,
            {
                method: 'GET',
                params: {
                    format: 'menu-root',
                },
            }
        );
    };

    return {
        rootCategories,
        categories,
        fetchCategory,
        fetchRootCategories,
    };
}
