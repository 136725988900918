<script setup lang="ts">
const { menusState, locale, settings } = useApp();
const { loggedIn, user, callMe } = useUser();
const {
    setValue,
    getValue,
    getError,
    resetValue,
    resetError,
} = useForm();

const formNamespace = "callMe";
const formInputId = "phoneNumber";
const formOptions = {
    id: formInputId,
    namespace: formNamespace
};
const phoneError = computed(() => getError(formOptions));
const phoneNumber = computed(() => getValue(formOptions));

const isModalOpened = ref(false);

const getWorkTime = (data: string = '{}') => {
    return JSON.parse(data)[locale.value]
}

const callUs = async (event: Event) => {
    if (!phoneNumber.value || phoneError.value || !Array.isArray(event.target)) return;

    const formElementsState = [];
    for (let index = 0; index < event.target.length; index++) {
        const formElement = event.target[index];
        formElementsState[index] = formElement.disabled;
        formElement.disabled = true;
    }

    try {
        await callMe(phoneNumber.value.replace(/[()\-\s]/g, ''));

        resetValue(formOptions);
        resetError(formOptions);

        isModalOpened.value = true;
    } catch (error) { return } finally {
        for (let index = 0; index < event.target.length; index++) {
            const formElement = event.target[index];
            formElement.disabled = formElementsState[index];
        }
    }
}

if (loggedIn.value && user.value.phone) {
    setValue(
        Object.assign({}, formOptions, {
            value: user.value.phone
        })
    );
}
</script>

<template>
    <transition name="city-searchbox-slide">
        <CommonCitySearchbox v-if="menusState.city" />
    </transition>

    <nav class="navbar container-fluid">
        <NuxtLink
            to="/"
            class="navbar-brand"
            rel="nofollow"
            aria-label="eTabletka"
        >
            <IconLogo />
        </NuxtLink>

        <HeaderNavbarLinks />

        <IconPhoneCall v-if="settings?.common?.support_phone" class="call-me-button" />

        <div v-if="settings?.common?.support_phone" class="call-me">
            <div class="call-me-header">
                <span>
                    <IconPhoneCall />
                </span>
                <div class="call-me-info">
                    <div class="phone-number">
                        <a :href="`tel:${settings.common.support_phone?.value.replace(/\s+/g, '')}`">
                            {{ settings.common.support_phone.value }}
                        </a>
                        <IconCaret />
                    </div>
                    <div
                        v-if="settings.common.work_time"
                        class="work-hours"
                    >
                        {{ getWorkTime(settings.common.work_time.value) }}
                    </div>
                </div>
            </div>

            <ClientOnly>
                <form class="call-me-form" @submit.prevent="callUs">
                    <div class="call-me-description">
                        <div>Безкоштовна консультація <br />фармацевта</div>
                    </div>

                    <div class="phone-form">
                        <CommonFormInput
                            type="phone"
                            :id="formInputId"
                            :namespace="formNamespace"
                            :required="true"
                            :disabled="loggedIn"
                        />
                    </div>

                    <button
                        class="submit-button"
                        :disabled="!phoneNumber || !!phoneError"
                    >
                        Передзвонити мені
                    </button>
                </form>
            </ClientOnly>

            <ModalCallMeResponse v-model="isModalOpened"/>
        </div>
    </nav>
</template>

<style lang="scss">
@use '~/assets/styles/mixins.scss';

.city-searchbox-slide-enter-active,
.city-searchbox-slide-leave-active {
    transition: transform .5s ease, opacity .5s ease;
    pointer-events: none !important;

    @include mixins.respond-to(desktop, tablet) {
        z-index: -1;
        transform: translateY(-100%);
    }

    @include mixins.respond-to(phone) {
        opacity: 0;
        transform: translateY(100%);
        z-index: 6;
    }
}

.city-searchbox-slide-enter,
.city-searchbox-slide-leave-to {
    pointer-events: auto;
}

.city-searchbox-slide-enter-to,
.city-searchbox-slide-leave {
    pointer-events: auto;
    transform: translateY(0);

    @include mixins.respond-to(phone) {
        opacity: 1;
    }
}

.navbar {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    align-items: center;
    padding-top: .5em;
    padding-bottom: .5em;
    z-index: 4;
    position: relative;

    &::after {
        content: '';
        background: #FFFFFF;
        position: absolute;
        height: 100%;
        width: 150%;
        top: 0;
        left: -25%;
        z-index: -1;
    }

    >*:not(:last-child) {
        margin-right: 5%;
    }

    >nav {
        display: flex;
        flex-grow: 1;

        ul.navbar-nav {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            list-style: none;
            margin: unset;
            padding: unset;

            a {
                display: block;
                font-weight: 500;
                line-height: 120%;
                transition: color .4s ease;

                &:hover {
                    color: #10A5AA;
                }
            }
        }

        @include mixins.respond-to(tablet, phone) {
            display: none;
        }
    }

    .navbar-brand {
        @include mixins.respond-to(desktop, tablet) {
            width: 17%;
        }

        @include mixins.respond-to(tablet) {
            width: 23%;
        }

        @include mixins.respond-to(phone) {
            width: 45%;
        }

        >* {
            width: 100%;
            height: 100%;
        }
    }

    .call-me-button {
        width: 2em;
        height: 2em;
        cursor: pointer;

        @include mixins.respond-to(desktop, tablet) {
            display: none;
        }

        @include mixins.respond-to(phone) {
            display: block;
        }

        &:hover+.call-me {
            right: 0;

            .call-me-form {
                max-height: 100vh;
                padding: 0 0.875em 1.25em 0.875em;
                pointer-events: auto;
                transition: none;
            }
        }
    }

    .call-me {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transition: box-shadow .3s ease;
        padding: 1em 0.875em;
        border-top-left-radius: 1.25em;

        @mixin small-text {
            text-align: right;
            margin-right: 1.38em;
            font-weight: 500;
            font-size: .815em;
            line-height: 115%;
            letter-spacing: .02em;
        }

        .call-me-header {
            display: flex;
            justify-content: end;
            align-items: center;

            >:first-child {
                margin-right: 2.75em;
            }

            >*>svg {
                width: 2em;
                height: auto;
            }

            .call-me-info {
                .phone-number {
                    display: inline-flex;
                    align-items: center;
                    font-weight: 800;
                    font-size: 1.125em;
                    line-height: 120%;

                    &:hover {
                        color: #4BC2C6;
                    }
                }

                .work-hours {
                    @include small-text;
                }

                svg {
                    width: .625em;
                    height: auto;
                    margin-left: .5em;
                    stroke-width: .1em;
                    transition: transform .4s ease;

                    * {
                        transition: stroke .4s ease;
                    }

                    @include mixins.respond-to(phone) {
                        display: none;
                    }
                }

                @include mixins.respond-to(phone) {
                    > :first-child {
                        margin-right: 1.1247em;
                    }
                }
            }

            @include mixins.respond-to(phone) {
                width: 100%;
                align-items: center;
                justify-content: space-between;
            }
        }

        .call-me-form {
            position: absolute;
            top: 80%;
            left: 0;
            width: 100%;
            background-color: white;
            z-index: 5;
            overflow: hidden;
            max-height: 0;
            transition: max-height 0.4s ease-in-out, padding 0.4s ease-in-out;
            padding: 0 0.875em;
            border-bottom-right-radius: 1.25em;
            pointer-events: none;

            >* {
                margin-top: .75em;
            }

            .call-me-description {
                @include small-text;
            }

            .phone-form {
                border-top: 1px solid #E7E9E9;
                padding-top: 1em;

                .input {
                    font-size: 1em;
                    padding: .75em;
                }
            }

            .submit-button {
                display: block;
                background: #EE3936;
                border-radius: .75em;
                font-weight: 500;
                line-height: 100%;
                color: #FFFFFF;
                padding: 1em 0.9375em;
                width: 100%;
                text-align: center;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;

                &:disabled {
                    background: #4bc2c64d;
                }
            }
        }

        &:hover {
            box-shadow: 0 .75em 1.5em rgba(0, 0, 0, 0.06);

            .call-me-info svg {
                transform: rotate(180deg);

                * {
                    stroke: #4BC2C6;
                }
            }

            .call-me-form {
                max-height: 100vh;
                padding: 0 0.875em 1.25em 0.875em;
                pointer-events: auto;
            }

            @include mixins.respond-to(phone) {
                right: 0;
            }
        }

        @include mixins.respond-to(phone) {
            position: fixed;
            transform: translateY(80%);
            background-color: #FFFFFF;
            max-width: 80%;
            right: -80%;
            z-index: 5;
            transition: right .4s ease;
        }
    }
}
</style>
