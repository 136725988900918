<script setup lang="ts">
const { getValue } = useForm();
const { setMenuState } = useApp();
const { cities, fetchCities } = useLocation();

const formOptions: Form.Options = {
    id: 'city',
    namespace: 'citySearchbox'
};

const query = computed(() => getValue(formOptions) || '');
const citiesList: ComputedRef<Locations.City[]> = computed(() => {
    if (!query.value.length) return cities.value.slice(0, 24);

    const regex = new RegExp(`^${query.value}`, 'i');
    return cities.value.filter((city) => regex.test(city.name));
});

const { data: citiesData } = await useAsyncData(`fetchCities`, fetchCities);

if (citiesData.value) {
    cities.value = citiesData.value;
}
</script>

<template>
    <div class="city-searchbox-wrapper">
        <div class="city-searchbox container-fluid">
            <button class="close-button" @click.prevent="setMenuState('city', false)">
                <IconCross />
            </button>

            <CommonFormInput 
                class="city-input" 
                label="Вкажіть свій населений пункт"
                placeholder="Введіть назву Вашого міста"
                :id="formOptions.id"
                :namespace="formOptions.namespace"
                :validators="false"
            />

            <figure class="city-dropdown">
                <figcaption class="city-title">Населенні пункти, де є наші аптеки</figcaption>
                <div class="search-results">
                    <template v-if="query.length && !citiesList.length">
                        У вказаному населеному пункті немає наших аптек
                    </template>
                    <template v-else>
                        <CommonCityList :cities="citiesList" />
                    </template>
                </div>
            </figure>
        </div>
    </div>
</template>

<style lang="scss">
@use '~/assets/styles/mixins.scss';

.city-searchbox-wrapper {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;

    @include mixins.respond-to(desktop) {
        z-index: 3;
    }

    @include mixins.respond-to(tablet) {
        z-index: 1;
    }

    @include mixins.respond-to(phone) {
        z-index: 6;
    }

    &::after {
        content: '';
        background: #F5F7FA;
        position: absolute;
        height: 100%;
        width: 150%;
        top: 0;
        left: -25%;
        z-index: -1;
    }

    @include mixins.respond-to(phone) {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .city-searchbox {
        position: relative;
        background: #F5F7FA;
        overflow: hidden;
        display: flex;
        flex-flow: column nowrap;
        padding-top: 1.25em;
        height: 100%;
        gap: 1em;

        .close-button {
            position: absolute;
            width: 1.5em;
            height: 1.5em;
            top: 1em;
            right: 1.25em;
            cursor: pointer;

            >svg>* {
                stroke: #212E42 !important;
            }
        }

        .city-input {
            margin: 0;
            align-items: flex-start;

            >label {
                font-weight: 600;
                font-size: 1em;
                line-height: 120%;
                color: #000000;
                margin-bottom: 1em;
                opacity: 1;

                @include mixins.respond-to(desktop, tablet) {
                    margin-left: 1.5em;
                }
            }

            >input {
                display: block;
                background: #FFFFFF;
                border: 1px solid #EDEBEB;
                border-radius: 1.75em;
                padding: 1.125em 1.5em;
                font-weight: 500;
                font-size: 1em;
                line-height: 125%;
                color: #212E42;

                @include mixins.respond-to(desktop) {
                    width: 50%;
                }

                &::placeholder {
                    opacity: .3;
                }
            }
        }

        .city-dropdown {
            position: relative;
            display: flex;
            flex-direction: column;
            overflow-y: hidden;

            .city-title {
                font-weight: bold;
                font-size: clamp(1.125em, 3vw, 1.75em);
                line-height: 120%;
                color: #000000;
                margin-bottom: clamp(1.5em, 2vw, 2.5em);
            }

            .search-results {
                margin-bottom: 2.25em;
                overflow-y: auto;
            }
        }
    }
}
</style>
