<script setup lang="ts">
defineProps({
    order: {
        type: Object as PropType<Cart.Order>,
        required: true,
    },

    product: {
        type: Object as PropType<Products.Product>,
        required: true,
    }
});

const modalVisible = defineModel();
const router = useRouter();

const redirectToCart = () => {
    router.push({ name: 'cart' });
}
</script>

<template>
    <Modal
        v-model="modalVisible"
        class="cart-item-correction-modal"
    >
        <template #header>
            <IconCheckBox class="icon"/>
            Товар додано в кошик
        </template>

        <template #body>
            <NuxtImg
                v-if="product.cdnData?.url"
                format="webp"
                class="image"
                sizes="xs:130 sm:210 md:100 lg:100"
                :src="product.cdnData.url"
                :alt="`Фото товару «${product.shortName || product.name}»`"
            />
            <IconProductFallback
                v-else
                class="image"
            />

            <div class="name">
                {{ product?.shortName }}
            </div>

            <div
                v-if="product"
                class="available"
            >
                Доступно - {{
                    product.productRemnants?.reduce((count, remnant) => {
                        return count += Math.floor(remnant.quantity);
                    }, 0)
                }} шт.
            </div>

            <div
                v-if="product && product.id in order.items"
                class="in-cart"
            >
                У кошику - {{ order.items[product.id].quantity }} шт.
            </div>

            <ProductQuantity
                v-if="product.id in order.items"
                class="quantity"
                :product="product"
                :order="order"
            />
        </template>

        <template #footer>
            <span class="label">Разом:</span>

            <CommonPrice class="price" :price="order.items[product.id].price"/>

            <button
                class="dismiss"
                @click.prevent="modalVisible = false"
            >
                Продовжити покупки
            </button>

            <button
                class="go-to-cart"
                @click.prevent="redirectToCart(); modalVisible = false"
            >
                Перейти до кошика
            </button>
        </template>
    </Modal>
</template>

<style lang="scss">
@use '~/assets/styles/mixins.scss';

.cart-item-correction-modal {
    >.modal-content>.modal-content-header {
        font-size: clamp(1.25em, 3vw, 1.5em);
        display: flex;
        align-items: center;
        text-align: left;
        gap: .75rem;
        padding-bottom: 1.25rem;
        border-bottom: 1px solid #E7E9E9;

        >.icon {
            width: 1.5rem;
            height: 1.5rem;
        }
    }

    >.modal-content>.modal-content-body {
        display: grid;
        row-gap: 1.5em;
        column-gap: clamp(1.25em, 3vw, 2.5em);
        align-items: center;
        justify-items: start;
        padding: 1.25em 0 1.75em 0;
        text-align: left;

        @include mixins.respond-to(phone) {
            grid-template-columns: 1fr 2fr;
            row-gap: .5em;
            padding-bottom: 0;
        }

        >.image {
            grid-column: 1;
            grid-row: 1 / 4;
            object-fit: cover;
            object-position: center;
            aspect-ratio: 1 / 1;
            width: 6em;
            height: 6em;
            border-radius: .5em;

            @include mixins.respond-to(phone) {
                width: 100%;
                height: auto;
            }
        }

        >.name {
            grid-column: 2 / 4;
            grid-row: 1;
            color: #000;
            font-size: 1em;
            font-weight: 600;
            line-height: 1.3;

            @include mixins.respond-to(phone) {
                grid-column: 2;
                grid-row: 1;
                text-align: left;
            }
        }

        >.available {
            grid-column: 2;
            grid-row: 2;
            font-weight: 500;
            color: #27AE60;

            @include mixins.respond-to(phone) {
                grid-column: 2;
                grid-row: 2;
            }
        }

        >.in-cart {
            grid-column: 3;
            grid-row: 2;
            font-weight: 500;
            line-height: 1.3;
            color: #27AE60;
            background: #27AE6014;
            padding: .25em .5em;
            border-radius: .25em;

            @include mixins.respond-to(phone) {
                grid-column: 2;
                grid-row: 3;
            }
        }

        >.quantity {
            grid-column: 2 / 4;
            grid-row: 3;
            justify-self: center;
            min-width: 7em;

            @include mixins.respond-to(phone) {
                grid-column: 1 / 3;
                grid-row: 4;
                margin-top: 1em;
                min-width: 10em;
            }
        }
    }

    >.modal-content>.modal-content-footer {
        display: grid;
        column-gap: 1.5em;
        row-gap: clamp(.75em, 3vw, 1.5em);
        
        >.label {
            grid-column: 1;
            grid-row: 1;
            color: #212E42;
            font-size: 1.25em;
            font-weight: 700;
            line-height: 1.3;
            justify-self: start;
        }

        >.price {
            grid-column: 2;
            grid-row: 1;
            font-weight: 700;
            font-size: 1.5em;
            line-height: 1;
            color: #212E42;
            justify-self: end;
        }

        >.dismiss {
            grid-column: 1;
            grid-row: 2;
            padding: .75em;
            border-radius: .75em;
            border: 2px solid #EE3936;
            line-height: 1;
            justify-self: stretch;
            font-weight: 500;
            color: #212E42;
            transition: background .25s ease, border-color .25s ease;

            @include mixins.respond-to(phone) {
                grid-column: 1 / 3;
                grid-row: 3;
            }

            &:hover {
                background: #f5f7fa;
                border-color: #CF3836;
            }
        }

        >.go-to-cart {
            grid-column: 2;
            grid-row: 2;
            border-radius: .75em;
            padding: .75em;
            line-height: 1;
            justify-self: stretch;
            background: #EE3936;
            border: 2px solid #EE3936;
            font-weight: 500;
            color: #FFFFFF;
            transition: background .25s ease, border-color .25s ease;

            @include mixins.respond-to(phone) {
                grid-column: 1 / 3;
                grid-row: 2;
            }

            &:hover {
                background: #CF3836;
                border-color: #CF3836;
            }
        }
    }
}
</style>
