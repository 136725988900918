<template>
	<svg
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		viewBox="0 0 14 14"
		style="enable-background:new 0 0 14 14;"
		xml:space="preserve"
	>
		<path
			style="fill: #484B4C;opacity: 0.2;enable-background: new"
			d="M3.4,11.9L1,13.9C0.9,13.9,0.8,14,0.7,14c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2-0.1-0.2-0.2C0,13.7,0,13.5,0,13.4V2.3
		C0,2.2,0.1,2,0.2,1.9c0.1-0.1,0.3-0.2,0.4-0.2h12.8c0.2,0,0.3,0.1,0.4,0.2C13.9,2,14,2.2,14,2.3v9c0,0.1-0.1,0.3-0.2,0.4
		c-0.1,0.1-0.3,0.2-0.4,0.2H3.6L3.4,11.9z"
		/>
		<path d="M9.6,5.8H4.4c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5h5.3c0.3,0,0.5,0.2,0.5,0.5S9.9,5.8,9.6,5.8z" />
		<path d="M9.6,8.4H4.4c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5h5.3c0.3,0,0.5,0.2,0.5,0.5S9.9,8.4,9.6,8.4z" />
	</svg>
</template>