<script setup lang='ts'>
const props = defineProps({
    type: {
        type: String,
        default: 'text',
    },
    name: {
        type: String,
        default: null,
    },
    label: {
        type: String,
        default: null,
    },
    minlength: {
        type: Number,
        default: 3,
    },
    maxlength: {
        type: Number,
        default: 128,
    },
    min: {
        type: Number,
        default: 0,
    },
    max: {
        type: Number,
        default: 100,
    },
    step: {
        type: Number,
        default: 1,
    },
    required: {
        type: Boolean,
        default: false,
    },
    placeholder: {
        type: String,
        default: null,
    },
    id: {
        type: String as PropType<Form.Id>,
        default: null,
    },
    namespace: {
        type: String as PropType<Form.Namespace>,
        default: null,
    },
    controls: {
        type: Boolean,
        default: true,
    },
    validators: {
        type: [Array, Boolean] as PropType<Form.Validator[] | Boolean>,
        default: [] as Form.Validator[],
    },
});

const { type, minlength, namespace, id } = toRefs(props);
const attrs = useAttrs();
const {
    getValue,
    getError,
    setValue,
    setError,
    resetError,
} = useForm();

const formOptions: Form.Options = {
    id: id.value,
    namespace: namespace.value,
};

const formatInput = (inputValue: string | null): string => {
    if (!inputValue) return '';

    switch (type.value) {
        case 'phone':
            return formatPhone(inputValue, phoneMask);
        default:
            return inputValue;
    }
};

const inputValue = computed(() => formatInput(getValue(formOptions)));

const phoneMask = '+38 (___) ___-__-__';
const cursor = { start: 5, end: 6 };
const codes = ["050", "066", "095", "099", "063", "073", "093", "067", "068", "096", "097", "098", "091", "092", "094"];
const errorLabels = {
    invalidCode: 'Неправильний код',
    typePhone: 'Введіть номер телефону',
    inputLength: `Мінімальна довжина ${minlength.value} символів`,
    requiredField: 'Це поле обов\'язкове',
    invalidStep: 'Число не відповідає кроку',
    invalidNumber: 'Некоректне число',
    invalidEmail: 'Неправильний формат електронної пошти',
};

const uniqueId = ref(Math.random().toString(36).substring(2) + Date.now().toString(36));
const computedId = computed(() => (id.value || uniqueId.value).toString());
const errorValue = computed(() => getError(formOptions));
const showError = ref(false);

const formatPhone = (value: string, mask: string): string => {
    if (!value) return '';

    let maskedValue = "";
    let valueIndex = 0;
    let currentValue = value.replace(/\D/g, '');
    currentValue = currentValue.replace(/^(038|308)/, '0').replace(/^(38(038|38)?)?/, '');

    for (let i = 0; i < mask.length; i++) {
        if (mask[i] === '_') {
            if (valueIndex < currentValue.length) {
                maskedValue += currentValue[valueIndex];
                valueIndex++;
            } else {
                maskedValue += '_';
            }
        } else {
            maskedValue += mask[i];
        }
    }
    return maskedValue;
};

const inputPhone = (e: Event) => {
    const target = e.target as HTMLInputElement;
    const formattedValue = formatPhone(target.value, phoneMask);
    let cursorPosition = target.selectionStart || 0;

    for (let i = 0; i < cursorPosition; i++) {
        if (phoneMask[i] !== '_' && target.value[i] !== phoneMask[i]) {
            cursorPosition++;
        }
    }

    target.value = formattedValue;
    target.setSelectionRange(cursorPosition, cursorPosition);

    setValue({
        ...formOptions,
        value: formattedValue,
    });

    validateInput(target.value, 'input');
};

const onFocusPhone = (e: FocusEvent) => {
    const target = e.target as HTMLInputElement;
    if (!target.value.replace(/\D/g, '')) {
        const { start, end } = cursor;
        target.value = phoneMask;
        target.setSelectionRange(start, end);
    }
};

const defaultValidators: Form.Validator[] = [
    {
        type: 'focus',
        validate: (value: string) => (props.required || attrs.required) && !value ? errorLabels.requiredField : null,
    }
];

const textValidators: Form.Validator[] = [
    {
        type: 'focus',
        validate: (value: string) => minlength.value > value.length ? errorLabels.inputLength : null,
    }
];

const phoneValidators: Form.Validator[] = [
    {
        type: 'focus',
        validate: (value: string) => {
            return value.replace(/_/g, '').length !== 19 ? errorLabels.typePhone : null;
        }
    },
    {
        type: 'input',
        validate: (value: string) => {
            const extractedCode = value.substring(5, 8).replace(/_/g, '');
            return (extractedCode.length === 3 && !codes.includes(extractedCode)) ? errorLabels.invalidCode : null;
        }
    }
];

const emailValidators: Form.Validator[] = [
    {
        type: 'focus',
        validate: (value: string) => {
            const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return !emailRegex.test(value) ? errorLabels.invalidEmail : null;
        }
    }
];

const numberValidators: Form.Validator[] = [
    {
        type: 'input',
        validate: (value: string) => {
            const numericValue = parseFloat(value);
            return isNaN(numericValue) ? errorLabels.invalidNumber : null;
        },
    },
];

const validators = computed(() => {
    let validators: Form.Validator[] = [];

    if (props.validators) {
        validators.push(...defaultValidators);

        if (Array.isArray(props.validators)) {
            validators.push(...props.validators);
        }

        if (type.value === 'phone') {
            validators.push(...phoneValidators);
        }

        if (type.value === 'email') {
            validators.push(...emailValidators);
        }

        if (type.value === 'number') {
            validators.push(...numberValidators);
        } else {
            validators.push(...textValidators);
        }
    }

    return validators;
});

const validateInput = (value: string, validateType: string): void => {
    value = value || '';

    let errorValue = null;
    showError.value = false;

    if (value || props.required) {
        for (let index = 0; index < validators.value.length; index++) {
            const validator = validators.value[index];
            const error = validator.validate(value);
            if (!error) continue;

            errorValue = error;

            if (validateType && validateType === validator.type) {
                showError.value = true;
                break;
            }
        }
    }

    if (errorValue) {
        setError({ ...formOptions, error: errorValue });
    } else {
        resetError(formOptions);
    }
};

const onInput = (e: Event) => {
    const target = e.target as HTMLInputElement;

    if (props.type === 'number') {
        let newValue = parseFloat(target.value.replace(/(?!^-)[^\d.,]/g, '')) || 0;

        newValue = Math.max(
            Math.min(newValue, props.max), props.min
        );

        if (props.step % 1 === 0 && newValue % 1 !== 0) {
            newValue = Math.round(newValue);
        }

        target.value = newValue.toString();
    }

    setValue({
        ...formOptions,
        value: target.value,
    });

    validateInput(target.value, 'input');
};

const onBlur = () => {
    validateInput(inputValue.value, 'focus');
};

watch(() => props, () => {
    validateInput(inputValue.value, 'init');
}, {
    deep: true,
    immediate: true,
});
</script>

<template>
    <div class="input-container">
        <label v-if="label" :for="computedId">
            {{ label }}

            <span
                v-if="$props.required || $attrs.required"
                class="asterisk"
            >*</span>
        </label>

        <template v-if="type === 'textarea'">
            <textarea 
                v-bind="$attrs" 
                class="input"
                :id="computedId"
                :minlength="minlength"
                :maxlength="maxlength"
                :value="inputValue"
                :required="required"
                @input="onInput"
                @blur="onBlur"
            ></textarea>
        </template>

        <template v-else-if="type === 'phone'">
            <input
                v-bind="$attrs"
                class="input"
                :id="computedId"
                :type="type"
                :placeholder="phoneMask"
                :value="inputValue"
                :required="required"
                @input="inputPhone"
                @focus="onFocusPhone"
                @blur="onBlur"
            />
        </template>

        <template v-else-if="type === 'number'">
            <button
                v-if="controls"
                class="decrement-button"
                aria-label="Зменшити значення"
                :disabled="parseFloat(inputValue) <= props.min"
                @click.prevent="() => {
                    const newValue = `${(parseFloat(inputValue) - props.step) || props.min}`;

                    validateInput(newValue, 'init');

                    if (!errorValue) {           
                    setValue({
                        ...formOptions,
                        value: newValue,
                    });
                    }
                }"
            >
            -
            </button>

            <input
                v-bind="$attrs"
                class="input"
                :id="computedId"
                :type="type" 
                :placeholder="placeholder"
                :value="inputValue"
                :min="min"
                :max="max"
                :step="step"
                :required="required"
                @input="onInput"
                @blur="onBlur"
            />

            <button
                v-if="controls"
                class="increment-button"
                aria-label="Збільшити значення"
                :disabled="parseFloat(inputValue) >= props.max"
                @click.prevent="() => {
                    const newValue = `${(parseFloat(inputValue) + props.step) || props.min}`;

                    validateInput(newValue, 'init');

                    if (!errorValue) {
                    setValue({
                        ...formOptions,
                        value: newValue,
                    });
                    }
                }"
            >
            +
            </button>
        </template>

        <template v-else-if="type === 'email'">
            <input
                v-bind="$attrs"
                class="input"
                :id="computedId"
                :type="type"
                :placeholder="placeholder"
                :value="inputValue"
                :required="required"
                @input="onInput"
                @blur="onBlur"
            />
        </template>

        <template v-else>
            <input 
                v-bind="$attrs"
                class="input"
                :id="computedId"
                :type="type" 
                :name="name"
                :placeholder="placeholder"
                :value="inputValue"
                :minlength="minlength"
                :maxlength="maxlength"
                :required="required"
                @input="onInput"
                @blur="onBlur"
            />
        </template>

        <div
            v-if="!!showError"
            class="input-error"
        >
            {{ errorValue }}
        </div>
    </div>
</template>

<style lang="scss">
.input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1rem auto;

    >label {
        align-self: flex-start;
        margin-bottom: .5em;
        font-size: .875em;
        line-height: 100%;
        color: #484B4C;
        opacity: .6;

        .asterisk {
            color: #EE3936;
        }
    }

    >.input {
        background: #F5F7FA;
        border: 1px solid #EDEBEB;
        border-radius: .85em;
        font-size: .875em;
        line-height: 100%;
        color: #484B4C;
        padding: .875em 1em;
        display: block;
        width: 100%;
        transition: border-color .25s ease;

        &.error {
            border-color: #EE3936;
        }

        &::placeholder {
            color: #484B4C;
            font-family: 'Gilroy';
            opacity: 0.6;
        }

        &:focus {
            border-color: #4BC2C6;

            &::placeholder {
                color: transparent;
            }
        }
    }

    >.input-error {
        align-self: flex-end;
        font-size: .75em;
        line-height: .6875em;
        margin-top: .5em;
        color: #CF3836;
    }

    &:has(input[type=number]) {
        display: grid;
        grid-template-columns: 27fr 46fr 27fr;
        justify-content: center;
        align-items: stretch;
        font-size: 1em;
        line-height: 240%;
        margin: unset;

        >button:first-of-type,
        >button:last-of-type {
            border: 1px solid #EDEBEB;
            background: #F5F7FA;
            color: #000000;
            font-size: 1.25em;

            &:disabled {
                background: #F5F7FA !important;
                color: #8a8a8a;
            }
        }

        >button:first-of-type {
            border-width: 1px 0 1px 1px;
            border-top-left-radius: .6em;
            border-bottom-left-radius: .6em;
            grid-column: 1;
        }

        >button:last-of-type {
            border-width: 1px 1px 1px 0;
            border-top-right-radius: .6em;
            border-bottom-right-radius: .6em;
            grid-column: 3;
        }

        &:not(:has(button)) {
            >input {
                grid-column: 1 / 4;
                border-width: 1px;
                border-radius: .6em;
            }
        }

        >input {
            background: #F5F7FA;
            grid-column: 2;
            border-width: 1px 0 1px 0;
            border-radius: 0;
            appearance: textfield;
            padding: 0;
            font-weight: 700;
            text-align: center;
            color: #000000;

            &:focus {
                border-color: #EDEBEB;
            }
        }

        >.input-error {
            grid-row: 2;
            grid-column: 1 / 4;
            text-align: center;
            line-height: 100%;
        }
    }
}
</style>

