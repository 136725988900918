<script setup lang="ts">
useHead({
    title: 'eTabletka',
    meta: [
        {
            name: 'description',
            content: 'єТаблетка - сервіс з бронювання ліків в найближчих до себе аптеках по мінімальним цінам. Постійні акційні пропозиції та широкий асортимент',
        },
    ],
    bodyAttrs: {
        class: 'main-head'
    },
    script: [{ innerHTML: '' }]
});
</script>

<template>
    <NuxtLoadingIndicator color="#EE3936"/>
    <NuxtLayout>
        <NuxtPage />
    </NuxtLayout>
</template>

