<script setup lang="ts">
const props = defineProps({
    category: {
        type: Object as PropType<Categories.Category>,
        required: true,
    },
});

const subcategories = computed(() => props.category.children);
const expandStates = reactive<Record<number, boolean>>({});

watch(
    subcategories,
    (newMenuItems: Categories.Category[]) => {
        for (const menuItem of newMenuItems) {
            if (!expandStates[menuItem.id]) {
                expandStates[menuItem.id] = false;
            }
        }
    },
    { immediate: true }
);

const toggleExpandState = (menuId: number) => {
    if (typeof expandStates[menuId] === 'boolean') {
        expandStates[menuId] = !expandStates[menuId];
    }
};
</script>

<template>
    <div class="menu-dropdown">
        <div class="menu-title">{{ category?.name }}</div>

        <div class="menu-wrapper">
            <div
                v-for="subcategory of subcategories"
                :key="'subcategory-' + subcategory.id"
            >
                <NuxtLink
                    :to="`/catalog/products/${category.id}/${subcategory.id}`"
                    rel="canonical"
                    class="menu-subtitle"
                >
                    {{ subcategory?.name }}
                </NuxtLink>

                <div v-if="subcategory?.children?.length > 0">
                    <div
                        v-for="(item, index) in subcategory?.children"
                        v-show="index < 2 || expandStates[subcategory.id]"
                        :key="'amich-' + item.id"
                    >
                        <NuxtLink
                            :to="`/catalog/products/${category.id}/${subcategory?.id}/${item.id}`"
                            rel="canonical"
                            class="menu-link"
                        >
                            {{ item?.name }}
                        </NuxtLink>
                    </div>

                    <button
                        v-if="subcategory?.children?.length > 2"
                        @click.prevent="toggleExpandState(subcategory.id)"
                        class="menu-expand"
                    >
                        {{ expandStates[subcategory.id] ? 'Приховати' : 'Показати ще' }}
                    </button>
                </div>
            </div>
        </div>

        <NuxtLink
            :to="'/categories/' + category?.id"
            rel="canonical"
            class="menu-footer"
        >
            <IconFourSquares />
            <span class="value">
                {{ 'Переглянути всі' }}
            </span>
        </NuxtLink>
    </div>
</template>
