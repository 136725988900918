<template>
    <svg
        viewBox="0 0 495 596"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M14.96 511.64C16.8 518.45 21.88 521.8 30.2 521.8C35.49 521.8 39.6 519.96 42.3 516.4L53.54 522.88C48.25 530.55 40.36 534.44 29.98 534.44C21.01 534.44 13.88 531.74 8.48001 526.34C3.08001 520.93 0.380005 514.13 0.380005 505.91C0.380005 497.81 3.08 491 8.38 485.59C13.68 480.08 20.59 477.38 28.91 477.38C36.8 477.38 43.39 480.08 48.47 485.59C53.66 491.1 56.25 497.8 56.25 505.91C56.25 507.75 56.03 509.58 55.71 511.64H14.96ZM42.29 500.83C40.67 493.48 35.27 489.92 28.89 489.92C21.43 489.92 16.35 493.92 14.73 500.83H42.29Z"
            fill="#3C3C3B"
        />
        <path
            d="M117.73 457.28V471.55H97.3V534.43L82.39 534.44V471.55H62.07V457.28H117.73Z"
            fill="#3C3C3B"
        />
        <path
            d="M157.71 478.9H171.65V534.44H157.71V526.56C153.5 531.86 147.66 534.45 140.1 534.45C132.86 534.45 126.7 531.75 121.51 526.24C116.43 520.73 113.84 513.92 113.84 505.92C113.84 497.92 116.43 491.22 121.51 485.71C126.7 480.2 132.85 477.39 140.1 477.39C147.66 477.39 153.5 479.99 157.71 485.28V478.9ZM142.69 521.15C147.01 521.15 150.58 519.75 153.39 516.94C156.31 514.02 157.71 510.35 157.71 505.92C157.71 501.49 156.31 497.82 153.39 495.01C150.58 492.09 147.01 490.69 142.69 490.69C138.37 490.69 134.8 492.09 131.99 495.01C129.18 497.82 127.78 501.49 127.78 505.92C127.78 510.35 129.18 514.02 131.99 516.94C134.8 519.74 138.37 521.15 142.69 521.15Z"
            fill="#3C3C3B"
        />
        <path
            d="M235.41 485.7C240.6 491.21 243.19 497.91 243.19 505.91C243.19 513.91 240.6 520.72 235.41 526.23C230.33 531.74 224.07 534.44 216.82 534.44C209.26 534.44 203.42 531.85 199.32 526.55V532.93H185.38V457.29H199.32V485.28C203.43 479.99 209.26 477.39 216.82 477.39C224.07 477.38 230.34 480.19 235.41 485.7ZM214.23 521.15C218.55 521.15 222.12 519.75 224.93 516.94C227.85 514.02 229.25 510.35 229.25 505.92C229.25 501.49 227.85 497.82 224.93 495.01C222.12 492.09 218.55 490.69 214.23 490.69C209.91 490.69 206.34 492.09 203.53 495.01C200.72 497.82 199.32 501.49 199.32 505.92C199.32 510.35 200.72 514.02 203.53 516.94C206.35 519.74 209.91 521.15 214.23 521.15Z"
            fill="#3C3C3B"
        />
        <path
            d="M254.11 534.43V457.28H268.05V534.43H254.11Z"
            fill="#3C3C3B"
        />
        <path
            d="M293.67 511.64C295.51 518.45 300.59 521.8 308.91 521.8C314.2 521.8 318.31 519.96 321.01 516.4L332.25 522.88C326.96 530.55 319.07 534.44 308.69 534.44C299.72 534.44 292.59 531.74 287.19 526.34C281.79 520.93 279.09 514.13 279.09 505.91C279.09 497.81 281.79 491 287.09 485.59C292.39 480.08 299.3 477.38 307.62 477.38C315.51 477.38 322.1 480.08 327.18 485.59C332.37 491.1 334.96 497.8 334.96 505.91C334.96 507.75 334.74 509.58 334.42 511.64H293.67ZM321.01 500.83C319.39 493.48 313.99 489.92 307.61 489.92C300.15 489.92 295.07 493.92 293.45 500.83H321.01Z"
            fill="#3C3C3B"
        />
        <path
            d="M375.04 492.3H362.83V514.78C362.83 520.62 367.04 520.73 375.04 520.29V534.44C355.9 534.82 348.89 529.59 348.89 514.78V492.3H339.49V478.9H348.89V467.99L362.83 463.78V478.91H375.04V492.3V492.3Z"
            fill="#3C3C3B"
        />
        <path
            d="M435.78 534.43H419.57L399.9 508.39V534.43L385.96 534.45V457.28H399.9V502.67L418.49 478.9H435.13L413.41 505.59L435.78 534.43Z"
            fill="#3C3C3B"
        />
        <path
            d="M480.95 478.9H494.89V534.44H480.95V526.56C476.74 531.86 470.9 534.45 463.34 534.45C456.1 534.45 449.94 531.75 444.75 526.24C439.67 520.73 437.08 513.92 437.08 505.92C437.08 497.92 439.67 491.22 444.75 485.71C449.94 480.2 456.09 477.39 463.34 477.39C470.9 477.39 476.74 479.99 480.95 485.28V478.9ZM465.93 521.15C470.25 521.15 473.82 519.75 476.63 516.94C479.55 514.02 480.95 510.35 480.95 505.92C480.95 501.49 479.55 497.82 476.63 495.01C473.82 492.09 470.25 490.69 465.93 490.69C461.61 490.69 458.04 492.09 455.23 495.01C452.42 497.82 451.02 501.49 451.02 505.92C451.02 510.35 452.42 514.02 455.23 516.94C458.04 519.74 461.61 521.15 465.93 521.15Z"
            fill="#3C3C3B"
        />
        <path
            d="M347.76 89.44H317.68C311.43 89.44 306.36 94.51 306.36 100.76V130.84C306.36 137.09 311.43 142.16 317.68 142.16H347.76C354.01 142.16 359.08 137.09 359.08 130.84V100.76C359.08 94.51 354.01 89.44 347.76 89.44Z"
            fill="#74BFBB"
        />
        <path
            d="M325.96 42.45V57.57C325.96 63.62 330.87 68.53 336.92 68.53H352.04C358.09 68.53 363 63.62 363 57.57V42.45C363 36.4 358.09 31.49 352.04 31.49H336.92C330.86 31.49 325.96 36.4 325.96 42.45Z"
            fill="#ADDCE5"
        />
        <path
            d="M395.87 28.37H386.92C383.42 28.37 380.58 31.21 380.58 34.71V43.66C380.58 47.16 383.42 50 386.92 50H395.87C399.37 50 402.21 47.16 402.21 43.66V34.71C402.22 31.21 399.38 28.37 395.87 28.37Z"
            fill="#ADDCE5"
        />
        <path
            d="M386.36 107.73H407C413.25 107.73 418.31 102.66 418.31 96.42V75.78C418.31 69.53 413.24 64.47 407 64.47H386.36C380.11 64.47 375.05 69.54 375.05 75.78V96.42C375.05 102.66 380.11 107.73 386.36 107.73Z"
            fill="#74BFBB"
        />
        <path
            d="M321.27 181.12H275V134.85C275 127.1 268.71 120.81 260.96 120.81H234.3C226.55 120.81 220.26 127.1 220.26 134.85V181.12H173.99C166.24 181.12 159.95 187.41 159.95 195.16V221.82C159.95 229.57 166.24 235.86 173.99 235.86H220.26V282.13C220.26 289.88 226.55 296.17 234.3 296.17H260.96C268.71 296.17 275 289.88 275 282.13V235.86H321.27C329.02 235.86 335.31 229.57 335.31 221.82V195.16C335.31 187.4 329.02 181.12 321.27 181.12Z"
            fill="url(#paint0_linear_1_3)"
        />
        <path
            d="M440.41 188.31H385.65C382.56 188.31 381.02 192.04 383.2 194.23L397.46 208.49L247.62 358.31L97.8 208.48L247.63 58.65L261.17 72.19C263.62 74.64 267.81 72.9 267.81 69.44V15.88C267.81 14.85 267.4 13.86 266.67 13.13L257.75 4.21002C252.16 -1.37998 243.11 -1.37998 237.52 4.21002L43.35 198.37C37.76 203.96 37.76 213.01 43.35 218.6L237.51 412.77C243.1 418.36 252.15 418.36 257.74 412.77L451.91 218.6C457.5 213.01 457.5 203.96 451.91 198.37L442.87 189.33C442.21 188.67 441.33 188.31 440.41 188.31Z"
            fill="url(#paint1_linear_1_3)"
        />
        <path
            d="M22.82 567.68V588.25H18V571.76H10.29L10.1 575.84C9.97001 578.56 9.72001 580.84 9.35001 582.68C8.98001 584.52 8.34999 585.97 7.45999 587.03C6.56999 588.1 5.36001 588.63 3.82001 588.63C3.10001 588.63 2.21 588.49 1.16 588.21L1.39001 584.16C1.75001 584.24 2.06 584.28 2.31 584.28C3.29 584.28 4.02001 583.89 4.51001 583.1C5.00001 582.32 5.31001 581.38 5.45001 580.29C5.59001 579.2 5.71001 577.64 5.82001 575.61L6.17001 567.67H22.82V567.68Z"
            fill="#3C3C3B"
        />
        <path
            d="M48.51 563.47C47.92 562.92 47.62 562.23 47.62 561.41C47.62 560.59 47.92 559.9 48.51 559.35C49.1 558.8 49.83 558.52 50.71 558.52C51.58 558.52 52.31 558.78 52.91 559.31C53.5 559.84 53.8 560.5 53.8 561.29C53.8 562.14 53.51 562.85 52.93 563.43C52.35 564.01 51.61 564.3 50.71 564.3C49.84 564.29 49.1 564.02 48.51 563.47ZM48.28 567.68H53.1V588.25H48.28V567.68Z"
            fill="#3C3C3B"
        />
        <path
            d="M87.15 580.01H83.41V588.25H78.59V567.68H83.41V576H87.26L93.35 567.68H98.51L91.04 577.58L99.01 588.25H93.35L87.15 580.01Z"
            fill="#3C3C3B"
        />
        <path
            d="M121.4 567.68H126.22V581.24L137.58 567.68H141.97V588.25H137.15V574.7L125.82 588.26H121.39V567.68H121.4Z"
            fill="#3C3C3B"
        />
        <path
            d="M216.79 567.68V588.25H212.01V571.76H201.88V588.25H197.06V567.68H216.79Z"
            fill="#3C3C3B"
        />
        <path
            d="M245.84 587.18C244.17 586.28 242.87 585.03 241.93 583.42C240.99 581.82 240.52 580 240.52 577.97C240.52 575.94 240.99 574.13 241.93 572.54C242.87 570.95 244.17 569.7 245.84 568.8C247.51 567.9 249.38 567.45 251.46 567.45C253.57 567.45 255.45 567.9 257.12 568.8C258.79 569.7 260.09 570.94 261.03 572.54C261.97 574.13 262.44 575.94 262.44 577.97C262.44 580 261.97 581.82 261.03 583.42C260.09 585.03 258.79 586.28 257.12 587.18C255.45 588.08 253.56 588.53 251.46 588.53C249.38 588.53 247.51 588.08 245.84 587.18ZM255.85 582.63C257.01 581.45 257.58 579.89 257.58 577.97C257.58 576.04 257 574.49 255.85 573.31C254.69 572.13 253.23 571.54 251.46 571.54C249.69 571.54 248.23 572.13 247.09 573.31C245.95 574.49 245.38 576.05 245.38 577.97C245.38 579.9 245.95 581.45 247.09 582.63C248.23 583.81 249.69 584.4 251.46 584.4C253.23 584.4 254.7 583.81 255.85 582.63Z"
            fill="#3C3C3B"
        />
        <path
            d="M324.35 580.01H320.61V588.25H315.79V567.68H320.61V576H324.46L330.55 567.68H335.71L328.24 577.58L336.21 588.25H330.55L324.35 580.01Z"
            fill="#3C3C3B"
        />
        <path
            d="M377.24 567.68V588.25H372.42V571.76H364.72L364.53 575.84C364.4 578.56 364.15 580.84 363.78 582.68C363.41 584.52 362.78 585.97 361.89 587.03C361 588.1 359.79 588.63 358.25 588.63C357.53 588.63 356.64 588.49 355.59 588.21L355.82 584.16C356.18 584.24 356.49 584.28 356.74 584.28C357.72 584.28 358.45 583.89 358.94 583.1C359.43 582.32 359.74 581.38 359.88 580.29C360.02 579.2 360.14 577.64 360.25 575.61L360.6 567.67H377.24V567.68Z"
            fill="#3C3C3B"
        />
        <path
            d="M402.94 563.47C402.35 562.92 402.05 562.23 402.05 561.41C402.05 560.59 402.35 559.9 402.94 559.35C403.53 558.8 404.26 558.52 405.14 558.52C406.01 558.52 406.74 558.78 407.34 559.31C407.93 559.84 408.23 560.5 408.23 561.29C408.23 562.14 407.94 562.85 407.36 563.43C406.78 564.01 406.04 564.3 405.14 564.3C404.26 564.29 403.53 564.02 402.94 563.47ZM402.71 567.68H407.53V588.25H402.71V567.68Z"
            fill="#3C3C3B"
        />
        <path
            d="M441.58 580.01H437.84V588.25H433.02V567.68H437.84V576H441.69L447.78 567.68H452.94L445.47 577.58L453.44 588.25H447.78L441.58 580.01Z"
            fill="#3C3C3B"
        />
        <path
            d="M494.09 567.68L484.46 589.91C483.56 592.14 482.47 593.72 481.19 594.63C479.91 595.54 478.35 596 476.53 596C475.5 596 474.49 595.83 473.49 595.5C472.49 595.17 471.67 594.7 471.02 594.11L472.95 590.57C473.41 591.01 473.96 591.35 474.59 591.61C475.22 591.87 475.85 592 476.5 592C477.35 592 478.05 591.78 478.6 591.35C479.15 590.91 479.66 590.18 480.12 589.15L480.47 588.34L471.49 567.69H476.5L482.97 582.91L489.48 567.69H494.09V567.68Z"
            fill="#3C3C3B"
        />
        <path
            d="M374.94 0.0100098H364.58C361.47 0.0100098 358.94 2.53001 358.94 5.65001V16.01C358.94 19.12 361.46 21.65 364.58 21.65H374.94C378.05 21.65 380.58 19.13 380.58 16.01V5.65001C380.58 2.54001 378.05 0.0100098 374.94 0.0100098Z"
            fill="#CCE8EF"
        />
        <defs>
            <linearGradient
                id="paint0_linear_1_3"
                x1="159.948"
                y1="208.487"
                x2="335.307"
                y2="208.487"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#74BFBB" />
                <stop
                    offset="0.4729"
                    stop-color="#74BFBB"
                />
                <stop
                    offset="1"
                    stop-color="#ADDCE5"
                />
            </linearGradient>
            <linearGradient
                id="paint1_linear_1_3"
                x1="39.1606"
                y1="208.487"
                x2="456.095"
                y2="208.487"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#E73A38" />
                <stop
                    offset="0.5088"
                    stop-color="#E73A38"
                />
                <stop
                    offset="1"
                    stop-color="#EF7881"
                />
            </linearGradient>
        </defs>
    </svg>
</template>