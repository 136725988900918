<script setup lang='ts'>
const route = useRoute();

const { orders } = useCart();
const { loggedIn } = useUser();
const { getFavouritesCount } = useFavourites();
const { menusState, setMenuState } = useApp();

type ModalObject = {
    login?: boolean;
    registration?: boolean;
}

const isModalVisible = ref<ModalObject>({
    login: false,
    registration: false
});
const stickyBarSticky = ref(false);
const stickyBarRef = ref<HTMLElement | null>(null);
const categoryMenuListRef = ref<HTMLElement | null>(null);

const isHomePage = computed(() => route.path === '/');
const productsInFavourites = computed(() => getFavouritesCount('products'));
const productsInCart = computed(() => {
    return Object.values(orders.value).reduce((acc, order) => {
        return acc += Object.keys(order.items).length;
    }, 0);
});

type DeviceType = 'DESKTOP' | 'TABLET' | 'PHONE';
const getDeviceType = (): DeviceType => {
    const windowWidth = window?.innerWidth || 0;

    let deviceType: DeviceType = 'DESKTOP';

    if (windowWidth <= 1200) {
        deviceType = 'TABLET';

        if (windowWidth <= 700) {
            deviceType = 'PHONE';
        }
    }

    return deviceType;
};

const deviceType = ref(getDeviceType());

const updateCategoryMenuListHeight = () => {
    if (!categoryMenuListRef.value) return;

    deviceType.value = getDeviceType();

    switch (deviceType.value) {
        case 'DESKTOP':
            const categoryMenuListRefHeight = categoryMenuListRef.value.getBoundingClientRect().height || 0;

            if (!menusState.value.catalog || categoryMenuListRefHeight <= 0) return;

            categoryMenuListRef.value.style.height = categoryMenuListRefHeight + 'px';
            categoryMenuListRef.value.style.maxHeight = categoryMenuListRefHeight + 'px';
            break;
        case 'TABLET':
            if (!stickyBarRef.value) return;

            const offsetTop = Math.max(stickyBarRef.value.getBoundingClientRect().top, 0);
            const headerHeight = stickyBarRef.value.getBoundingClientRect().height;
            const categoryMenuOffsetY = offsetTop + headerHeight;

            categoryMenuListRef.value.style.height = (window.innerHeight - categoryMenuOffsetY) + 'px';
            categoryMenuListRef.value.style.maxHeight = '';
            break;
        case 'PHONE':
            categoryMenuListRef.value.style.height = '';
            categoryMenuListRef.value.style.maxHeight = '';
            break;
    }
}

const toggleCatalog = (isActive?: boolean) => {
    setMenuState('catalog', typeof isActive === 'boolean' ? isActive : !menusState.value.catalog);
    categoryMenuListRef.value?.addEventListener('transitionend', updateCategoryMenuListHeight, { once: true });
}

const toggleModal = (value: ModalObject | undefined) => {
    if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
        isModalVisible.value = value;
    } else {
        for (const key in isModalVisible) {
            isModalVisible.value[key as keyof ModalObject] = !isModalVisible.value[key as keyof ModalObject];
        }
    }
}

const handleScroll = () => {
    if (stickyBarRef.value) {
        const parentHeight = stickyBarRef.value.parentElement?.clientHeight || 0;
        const stickyBarOffsetY = parentHeight - stickyBarRef.value.clientHeight;

        stickyBarSticky.value = window.scrollY > stickyBarOffsetY;
    }

    updateCategoryMenuListHeight();
};

watch(() => route.fullPath, () => {
    toggleCatalog(false);
});

onMounted(() => {
    handleScroll();

    window.addEventListener('resize', updateCategoryMenuListHeight);
    window.addEventListener('scroll', handleScroll, { passive: true });
});

onUnmounted(() => {
    window.removeEventListener('resize', updateCategoryMenuListHeight);
    window.removeEventListener('scroll', handleScroll);
});
</script>

<template>
    <!-- Dublicate element for smooth sticky transition -->
    <div
        v-if="stickyBarSticky"
        class="container-fluid sticky-bar"
        :style="{ visibility: 'hidden' }"
    >
        <div class="catalog-header"></div>
    </div>
    <!-- -->

    <div
        ref="stickyBarRef"
        class="container-fluid sticky-bar"
        :class="{ 'sticky': stickyBarSticky }"
    >
        <div class="catalog-header">
            <button 
                class="menu-catalog" 
                @click="() => { toggleCatalog() }"
                :aria-label="menusState.catalog ? 'Закрити каталог товарів' : 'Відкрити каталог товарів'"
            >
                <span class="hamburger-icon" :class="{ active: menusState.catalog }"></span>
                <span class="value">Каталог товарів</span>
            </button>
            <div class="header-wrapper">
                <HeaderSearchBox/>
                <div class="header-icons">
                    <template v-if="loggedIn">
                        <NuxtLink
                            to="/profile"
                            rel="nofollow"
                            class="header-auth"
                        >
                            <IconUser />
                            <span class="value">Кабінет</span>
                        </NuxtLink>

                        <NuxtLink
                            to="#"
                            class="header-favorites"
                            rel="nofollow"
                            aria-label="Обране"
                        >
                            <IconHeart/>
                        </NuxtLink>

                        <NuxtLink
                            href="/cart"
                            rel="nofollow"
                            class="header-cart"
                            aria-label="Кошик"
                            :data-badge="productsInCart || null"
                        >
                            <IconCart />
                        </NuxtLink>
                    </template>
                    <template v-else>
                        <button
                            class="header-auth"
                            @click.prevent="() => toggleModal({ 'login': true })"
                        >
                            <IconUser />
                            <span class="value">Вхід<br>Реєстрація</span>
                        </button>
                        
                        <button
                            class="header-favorites"
                            aria-label="Обране"
                            :data-badge="productsInFavourites || null"
                            @click.prevent=""
                        >
                            <IconHeart />
                        </button>

                        <NuxtLink
                            href="/cart"
                            rel="nofollow"
                            class="header-cart"
                            aria-label="Кошик"
                            :data-badge="productsInCart || null"
                        >
                            <IconCart />
                        </NuxtLink>
                    </template>
                </div>
            </div>
        </div>

        <Transition name="main-menu-slide" ref="categoryMenuListRef">
            <CategoryMenuList 
                v-show="menusState.catalog && ((isHomePage ? stickyBarSticky : true) || deviceType !== 'DESKTOP')" 
                class="slide"
            />
        </Transition>

        <ClientOnly>
            <ModalUserLogin
                v-model="isModalVisible.login"
                @showRegistration="() => toggleModal({
                    'login': false,
                    'registration': true
                })"
            />
            <ModalUserRegister
                v-model="isModalVisible.registration"
                @showLogin="() => toggleModal({
                    'login': true,
                    'registration': false
                })"
            />
        </ClientOnly>
    </div>
</template>

<style lang="scss">
@use '~/assets/styles/mixins.scss';

.sticky-bar {
    position: relative;

    &.sticky {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        z-index: 3;

        @include mixins.respond-to(phone) {
            z-index: 4;
        }
    }

    .catalog-header {
        position: relative;
        display: flex;
        height: 4.5em;
        top: auto;

        &::after {
            content: '';
            background: #4BC2C6;
            position: absolute;
            height: 100%;
            width: 150%;
            top: 0;
            left: -25%;
            z-index: -1;
        }

        > :first-child {
            flex: 25% 0 0;
            margin-right: 1em;

            @include mixins.respond-to(phone, tablet) {
                flex: 0 0 0;
            }
        }

        > :nth-child(2) {
            flex: 75%;
        }

        .menu-catalog {
            background: #44B8BC;
            height: 100%;
            padding: 0 0 0 1.875em;
            display: flex;
            align-items: center;
            cursor: pointer;

            > :first-child {
                margin-right: 1em;

                @include mixins.respond-to(phone, tablet) {
                    margin-right: auto;
                }
            }

            .value {
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                letter-spacing: 0.04em;
                text-transform: uppercase;
                color: #FFFFFF;

                @include mixins.respond-to(phone, tablet) {
                    display: none;
                }
            }

            @include mixins.respond-to(phone, tablet) {
                background: none;
            }

            @include mixins.respond-to(tablet) {
                padding: 0 1.875em;
            }

            @include mixins.respond-to(phone) {
                position: absolute;
                top: 1.75em;
                left: 0;
                height: .5em;
                padding: 0 0 0 .5em;
            }
        }

        .header-wrapper {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: .75em 0;

            .header-icons {
                display: flex;
                align-items: center;
                justify-content: flex-end;

                >* {
                    position: relative;

                    &[data-badge]::after {
                        content: attr(data-badge);
                        position: absolute;
                        top: -.25em;
                        right: -.5em;
                        background: #EE3936;
                        min-width: 1.375em;
                        min-height: 1.375em;
                        border-radius: 50%;
                        text-align: center;
                        font-weight: 600;
                        color: #FFFFFF;
                        aspect-ratio: 1 / 1;
                        padding: .1em;
                        padding-bottom: .2em;
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                    }

                    &:not(:last-child) {
                        @include mixins.respond-to(desktop, tablet) {
                            margin-right: 2.25em;
                        }

                        @include mixins.respond-to(phone) {
                            margin-right: 5%;
                        }
                    }

                    >svg {
                        width: 2.25em;
                        height: 2.25em;

                        * {
                            fill: #FFFFFF !important;
                        }
                    }
                }

                .header-auth {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    text-align: left;

                    > :first-child {
                        margin-right: 0.625em;
                    }
                }

                .value {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 120%;
                    color: #FFFFFF;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            @include mixins.respond-to(phone) {
                flex-direction: column-reverse;
                align-items: stretch;

                >* {
                    flex: 50%;
                }

                > :first-child {
                    margin-right: unset;
                }

                > :nth-child(2) {
                    margin-bottom: 1.25em;
                }
            }
        }

        @include mixins.respond-to(phone) {
            height: 8em;
        }
    }
}
</style>
