<script setup lang='ts'>
const emit = defineEmits(['showLogin']);

const modalVisible = defineModel();

const { register } = useUser();
const { getValue, getErrors } = useForm();

const formNamespace = "registrationForm";
const formFirstNameInputId = "userRegFirstName";
const formLastNameInputId = "userRegLastName";
const formPhoneInputId = "userRegPhoneNumber";
const formPasswordInputId = "userRegPassword";
const formConfirmPasswordInputId = "userRegConfirmPassword";

const storeFirstNameOptions = {
    id: formFirstNameInputId,
    namespace: formNamespace
};
const storeLastNameOptions = {
    id: formLastNameInputId,
    namespace: formNamespace
};
const storePhoneOptions = {
    id: formPhoneInputId,
    namespace: formNamespace
};
const storePasswordOptions = {
    id: formPasswordInputId,
    namespace: formNamespace
};

const firstName = computed(() => getValue(storeFirstNameOptions));
const lastName = computed(() => getValue(storeLastNameOptions));
const phone = computed(() => getValue(storePhoneOptions));
const password = computed(() => getValue(storePasswordOptions));

const formErrors = computed(() => getErrors(formNamespace));
const formFilled = computed(() => {
    for (const key in formErrors.value) {
        if (formErrors.value[key]) return false;
    }

    return true;
});

const formConfirmPasswordValidator: Form.Validator = {
    type: 'input',
    validate: (value) => {
        return password.value !== value ? 'Пароль має співпадати' : null;
    }
}

const handleSubmit = async () => {
    if (!formFilled.value) return;

    const response = await register({
        phone: phone.value?.replace(/[()\-\s]/g, '') as string,
        firstName: firstName.value as string,
        lastName: lastName.value as string,
        password: password.value as string,
    });

    if (response?.id) modalVisible.value = false;
}
</script>

<template>
    <Modal
        v-model="modalVisible"
        class="register-form"
    >
        <template #header>
            Персональні дані
        </template>

        <template #body>
            <form @submit.prevent="handleSubmit" class="form">
                <CommonFormInput
                    type="text"
                    :id="formFirstNameInputId"
                    :namespace="formNamespace"
                    :label="'Прізвище'"
                    :label-class="'label'"
                    :required="true"
                    autocomplete="user-first-name"
                />

                <CommonFormInput
                    type="text"
                    :id="formLastNameInputId"
                    :namespace="formNamespace"
                    :label="'Ім’я'"
                    :label-class="'label'"
                    :required="true"
                    autocomplete="user-last-name"
                />

                <CommonFormInput
                    type="phone"
                    :id="formPhoneInputId"
                    :namespace="formNamespace"
                    :required="true"
                    :minlength=19
                    autocomplete="user-mobile-phone"
                />

                <CommonFormInput
                    type="password"
                    :id="formPasswordInputId"
                    :namespace="formNamespace"
                    :required="true"
                    :minlength=8
                    autocomplete="current-password"
                    placeholder="Введіть ваш пароль"
                />

                <CommonFormInput
                    type="password"
                    :id="formConfirmPasswordInputId"
                    :namespace="formNamespace"
                    :required="true"
                    :minlength=8
                    :validators="[formConfirmPasswordValidator]"
                    autocomplete="confirm-password"
                    placeholder="Підтвердіть свій пароль"
                />

                <button
                    class="submit"
                    :disabled="!formFilled"
                >
                    Зареєструватися
                </button>
            </form>
        </template>

        <template #footer>
            <button
                class="form-link"
                @click.prevent="emit('showLogin')"
            >
                Увійти
            </button>

            <span>
                Продовжуючи, Ви погоджуєтесь
                <NuxtLink to="#" target="_blank" class="form-link">
                    з умовами угоди користувача
                </NuxtLink>
            </span>
        </template>
    </Modal>
</template>

<style lang="scss">
.register-form {
    .submit {
        cursor: pointer;
        display: block;
        background: #EE3936;
        border-radius: .75em;
        font-weight: 500;
        line-height: 100%;
        color: #FFFFFF;
        padding: 1em;
        width: 100%;
        text-align: center;
        transition: background .3s ease;

        &:hover {
            background: #CF3836;
        }

        &:disabled {
            background: #4bc2c64d;
        }
    }

    .form-link:not(:last-child) {
        margin-bottom: 1.5em;
    }
}
</style>
