<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        version="1.1"
        viewBox="4 6.65 22.68 18.68"
    >
        <path
            d="M 17.332031 25.332031 C 16.933594 25.332031 16.667969 25.199219 16.398438 24.933594 C 15.867188 24.398438 15.867188 23.601562 16.398438 23.066406 L 23.464844 16 L 16.398438 8.933594 C 15.867188 8.398438 15.867188 7.601562 16.398438 7.066406 C 16.933594 6.535156 17.734375 6.535156 18.265625 7.066406 L 26.265625 15.066406 C 26.800781 15.601562 26.800781 16.398438 26.265625 16.933594 L 18.265625 24.933594 C 18 25.199219 17.734375 25.332031 17.332031 25.332031 Z M 5.332031 25.332031 C 4.933594 25.332031 4.667969 25.199219 4.398438 24.933594 C 3.867188 24.398438 3.867188 23.601562 4.398438 23.066406 L 11.464844 16 L 4.398438 8.933594 C 3.867188 8.398438 3.867188 7.601562 4.398438 7.066406 C 4.933594 6.535156 5.734375 6.535156 6.265625 7.066406 L 14.265625 15.066406 C 14.800781 15.601562 14.800781 16.398438 14.265625 16.933594 L 6.265625 24.933594 C 6 25.199219 5.734375 25.332031 5.332031 25.332031 Z M 5.332031 25.332031 "
        />
    </svg>
</template>