<script setup lang="ts">
import type { NuxtError } from '#app';

useHead({
    title: 'eTabletka',
});

defineProps({
    error: {
        type: Object as PropType<NuxtError>
    }
})
</script>

<template>
    <NuxtLayout>
        <div class="error-page container-inner-fluid">
            <h1 class="status-code">{{ error?.statusCode }}</h1>
            <h2 class="message">{{ error?.message }}</h2>
            <p class="text">Радимо <NuxtLink to="/" class="link">перейти на головну сторінку</NuxtLink>, або скористатися пошуком вище</p>
        </div>
    </NuxtLayout>
</template>

<style lang="scss">
@use '~/assets/styles/mixins.scss';

.error-page {
    position: relative;
    padding-top: 4.375em;
    padding-bottom: 4.375em;
    text-align: center;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url('/icons/pill.svg') repeat;
        background-size: clamp(5em, 10vw, 7em);
        background-position: left center;
        background-repeat: space;
        opacity: .1;
        z-index: -1;
    }

    >.status-code {
        font-size: clamp(7.5em, 15vw, 18em);
        font-weight: 700;
        color: transparent;
        margin-bottom: 0;
        -webkit-text-stroke: 3px #4BC2C6;
    }

    >.message {
        font-size: clamp(1.25em, 3vw, 3em);
        font-weight: 700;
        color: #484B4C;
    }

    >.text {
        font-size: clamp(1em, 3vw, 1.125em);
        color: #484B4C;

        .link {
            color: #EE3936;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}
</style>
