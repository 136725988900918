<script setup lang='ts'>
const modalVisible = defineModel();

const { getErrors } = useForm();

const formNamespace = "askPharmacistForm";

const formErrors = computed(() => getErrors(formNamespace));
const formFilled = computed(() => {
    for (const key in formErrors.value) {
        if (formErrors.value[key]) return false;
    }

    return true;
});

const sendRequest = () => {
    if (!formFilled.value) return;

    // TODO: send 'ask pharmacist' request
};
</script>

<template>
    <Modal
        v-model="modalVisible"
        class="ask-pharmacist-modal"
    >
        <template #header>
            Запитай професійного фармацевта
        </template>

        <template #body>
            <form @submit.prevent="sendRequest">
                <CommonFormInput
                    label="Ваше ім’я"
                    :id="`${formNamespace}Name`"
                    :namespace="formNamespace"
                    :required="true"
                />

                <CommonFormInput
                    label="Ваш email"
                    type="email"
                    :id="`${formNamespace}Email`"
                    :namespace="formNamespace"
                />

                <CommonFormInput
                    label="Номер телефону"
                    type="phone"
                    :id="`${formNamespace}Phone`"
                    :namespace="formNamespace"
                    :required="true"
                />

                <CommonFormInput
                    label="Ваш коментар"
                    type="textarea"
                    rows="7"
                    :id="`${formNamespace}Comment`"
                    :namespace="formNamespace"
                    :required="true"
                />

                <button :disabled="!formFilled" class="submit">Задати питання</button>
            </form>
        </template>
    </Modal>
</template>

<style lang="scss">
.ask-pharmacist-modal {
    textarea {
        resize: none;
    }

    .submit {
        cursor: pointer;
        display: block;
        background: #EE3936;
        border-radius: .75em;
        font-weight: 500;
        line-height: 100%;
        color: #FFFFFF;
        padding: 1em;
        width: 100%;
        text-align: center;
        transition: background .3s ease;

        &:hover {
            background: #CF3836;
        }

        &:disabled {
            background: #4bc2c64d;
        }
    }
}
</style>