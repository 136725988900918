<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        version="1.1"
        viewBox="2.65 2.67 26.68 26.67"
    >
        <path
            d="M 4 29.332031 C 3.601562 29.332031 3.332031 29.199219 3.066406 28.933594 C 2.667969 28.535156 2.535156 28 2.800781 27.601562 L 5.199219 20.535156 C 4.398438 18.933594 4 17.066406 4 15.332031 C 4 12.933594 4.667969 10.667969 5.867188 8.667969 C 7.066406 6.667969 8.933594 5.066406 11.066406 4 C 12.800781 3.066406 14.800781 2.667969 16.667969 2.667969 L 17.332031 2.667969 C 20.535156 2.800781 23.464844 4.132812 25.601562 6.398438 C 27.867188 8.667969 29.066406 11.464844 29.332031 14.667969 L 29.332031 15.464844 C 29.332031 17.464844 28.933594 19.332031 28 21.199219 C 26.933594 23.332031 25.332031 25.066406 23.332031 26.265625 C 21.332031 27.464844 19.066406 28.132812 16.667969 28.132812 C 14.667969 28.132812 12.800781 27.734375 10.933594 26.800781 C 10.265625 26.535156 10 25.601562 10.398438 25.066406 C 10.800781 24.398438 11.601562 24.132812 12.132812 24.535156 C 13.464844 25.199219 15.066406 25.601562 16.535156 25.601562 C 18.398438 25.601562 20.265625 25.066406 21.734375 24.132812 C 23.332031 23.199219 24.535156 21.734375 25.464844 20.132812 C 26.132812 18.800781 26.535156 17.199219 26.535156 15.601562 L 26.535156 14.667969 C 26.398438 12.265625 25.332031 10 23.601562 8.265625 C 21.867188 6.535156 19.601562 5.464844 17.066406 5.332031 L 16.535156 5.332031 C 14.933594 5.332031 13.464844 5.734375 12.132812 6.398438 C 10.398438 7.199219 9.066406 8.535156 8.132812 10.132812 C 7.199219 11.601562 6.667969 13.464844 6.667969 15.332031 C 6.667969 16.933594 7.066406 18.398438 7.734375 19.734375 C 7.867188 20 7.867188 20.398438 7.867188 20.800781 L 6.132812 25.867188 L 7.464844 25.464844 C 8.132812 25.199219 8.933594 25.601562 9.199219 26.265625 C 9.332031 27.066406 8.933594 27.734375 8.265625 28 L 4.398438 29.332031 C 4.265625 29.332031 4.132812 29.332031 4 29.332031 Z M 4 29.332031 "
        />
    </svg>
</template>