<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
    >
        <path
            d="M25.94 17.85H5.73c-1.02 0-1.69-.67-1.69-1.68 0-1.01.67-1.69 1.69-1.69h20.2c1.02 0 1.7.68 1.7 1.69 0 1.01-.68 1.68-1.7 1.68Zm-8.26 8.26c-.5 0-.84-.17-1.18-.5a1.63 1.63 0 0 1 0-2.37l7.08-7.07-7.08-7.08c-.67-.67-.67-1.85 0-2.35.68-.51 1.7-.68 2.36 0l8.26 8.25c.33.34.5.67.5 1.18 0 .5-.17.84-.5 1.18l-8.26 8.25c-.33.34-.67.5-1.18.5Zm0 0"
        />
    </svg>
</template>
