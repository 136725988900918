export function useLocation() {
    const { public: { apiBase: APIPrefix } } = useRuntimeConfig();

    const cities = useState<Locations.City[]>('cities', () => []);

    const fetchCities = async () => {
        const response = await $fetch<Locations.City[]>(`${APIPrefix}/cities/stores`, {
            method: "GET",
        });

        if (!response) return null;

        response.sort((a, b) => (b.stores?.length || 0) - (a.stores?.length || 0));

        return response;
    };

    return {
        cities,
        fetchCities,
    };
}
