<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xml:space="preserve"
        version="1.1"
        style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        xmlns:xodm="http://www.corel.com/coreldraw/odm/2003"
        viewBox="3726.67 12662.2 13546.68 4375.58"
    >
        <path
            style="fill:#E0E0E2;fill-rule:nonzero"
            d="M8870.45 12739.54l-1773.48 4231.64 -1157.12 0 -872.06 -3377.07c-53.62,-208.28 -99.34,-283.92 -260.77,-372.53 -263.6,-142.24 -697.66,-276.02 -1080.35,-359.55l26.53 -123.05 1862.1 0c237.63,0 450.99,158.61 504.61,431.24l461.15 2449.12 1139.05 -2880.36 1150.34 0 0 0.56zm4533.05 2849.32c5.08,-1115.91 -1544.32,-1176.87 -1533.03,-1676.4 2.82,-152.4 147.32,-313.83 463.97,-354.47 156.92,-20.88 589.28,-36.12 1079.78,188.52l191.91 -896.9c-263.6,-95.96 -602.83,-187.4 -1023.9,-187.4 -1082.04,0 -1843.48,575.17 -1849.68,1398.69 -7.34,609.04 543.56,948.83 958.43,1151.47 426.16,207.72 568.96,339.8 567.27,526.06 -2.82,283.92 -340.36,408.09 -654.76,413.74 -550.33,8.47 -869.81,-149.01 -1124.94,-267.55l-198.12 927.95c255.13,117.4 728.13,219 1217.51,225.21 1149.77,0.01 1902.18,-569.52 1905.56,-1448.92l0 0zm2857.22 1381.76l1012.61 0 -883.36 -4231.64 -934.71 0c-209.97,0 -387.21,122.48 -466.23,310.44l-1641.4 3921.2 1149.2 0 228.04 -632.18 1404.33 0 131.52 632.18zm-1221.46 -1499.17l576.3 -1588.91 331.33 1588.91 -907.63 0zm-4604.74 -2731.91l-905.93 4231.64 -1094.46 0 905.93 -4231.64 1094.46 0z"
        />
    </svg>
</template>