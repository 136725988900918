const DEFAULT_KEY: unique symbol = Symbol('default');

export function useForm() {
    const errors = useState<Form.Errors>('errors', () => ({}));
    const values = useState<Form.Values>('values', () => ({}));

    const getValue = (options: Form.Options = {}) => {
        if (!options.namespace) options.namespace = DEFAULT_KEY;
        if (!options.id) options.id = DEFAULT_KEY;

        return values.value?.[options.namespace]?.[options.id] || null;
    };

    const getValues = (namespace: Form.Namespace = DEFAULT_KEY) => {
        return values.value?.[namespace] || {};
    };

    const getError = (options: Form.Options = {}) => {
        if (!options.namespace) options.namespace = DEFAULT_KEY;
        if (!options.id) options.id = DEFAULT_KEY;

        return errors.value?.[options.namespace]?.[options.id] || null;
    };

    const getErrors = (namespace: Form.Namespace = DEFAULT_KEY) => {
        return errors.value?.[namespace] || {};
    };

    const setValue = ({ namespace = DEFAULT_KEY, id = DEFAULT_KEY, value }: Form.SetVOptions) => {
        if (!values.value[namespace]) {
            values.value[namespace] = {};
        }
        values.value[namespace][id] = value;
    };

    const resetValue = ({ namespace = DEFAULT_KEY, id = DEFAULT_KEY }: Form.Options = {}) => {
        if (values.value[namespace] && values.value[namespace][id]) {
            delete values.value[namespace][id];
            if (Object.keys(values.value[namespace]).length === 0) {
                delete values.value[namespace];
            }
        }
    };

    const resetValues = (namespace: Form.Namespace = DEFAULT_KEY) => {
        delete values.value[namespace];
    };

    const setError = ({ namespace = DEFAULT_KEY, id = DEFAULT_KEY, error }: Form.SetEOptions) => {
        if (!errors.value[namespace]) {
            errors.value[namespace] = {};
        }
        errors.value[namespace][id] = error;
    };

    const resetError = ({ namespace = DEFAULT_KEY, id = DEFAULT_KEY }: Form.Options = {}) => {
        if (errors.value[namespace] && errors.value[namespace][id]) {
            delete errors.value[namespace][id];
            if (Object.keys(errors.value[namespace]).length === 0) {
                delete errors.value[namespace];
            }
        }
    };

    const resetErrors = (namespace: Form.Namespace = DEFAULT_KEY) => {
        delete errors.value[namespace];
    };

    return {
        errors,
        values,
        getValue,
        getValues,
        getError,
        getErrors,
        setValue,
        resetValue,
        resetValues,
        setError,
        resetError,
        resetErrors,
    };
}
