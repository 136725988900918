
import * as ipxRuntime$OiZ9Vso0Tp from '/usr/home/jenkins/app/services/etabletka-app/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 100,
    "sm": 450,
    "md": 700,
    "lg": 1200,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [
    "cdn.konex.com.ua"
  ],
  "alias": {},
  "densities": [
    1,
    1.5,
    1.6,
    1.75,
    1.875,
    2,
    1,
    2
  ],
  "format": [
    "webp"
  ],
  "quality": 80
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$OiZ9Vso0Tp, defaults: {} }
}
        