export function useProduct() {
    const { public: { apiBase: APIPrefix } } = useRuntimeConfig();

    const product = useState<Products.Product | null>('product', () => null);
    const instruction = useState<string | null>('instruction', () => null);

    const getRemnantsByCityId = (product: Products.Product, cityId: Locations.City['id']) => {
        return product.productRemnants.filter(remnant => 
            (remnant.store.city?.id || remnant.store.cityId) === cityId
        ) || null;
    };

    const fetchProduct = async (id: Products.Product['id']) => {
        return await $fetch<Products.Product>(`${APIPrefix}/product/${id}`, {
            method: "GET",
        });
    };

    const fetchInstruction = async (url: string) => {
        return await $fetch<string>(`/product/instruction${new URL(url).pathname}`);;
    };

    return {
        product,
        instruction,
        getRemnantsByCityId,
        fetchInstruction,
        fetchProduct,
    };
}
