<script setup lang="ts">
const props = defineProps({
    productId: {
        type: Number as PropType<Products.Product['id']>,
        default: null,
    },
    product: {
        type: Object as PropType<Products.Product>,
        default: null,
    },
    baseQuantity: {
        type: Number,
        default: 1,
    },
    partialQuantity: {
        type: Number,
        default: 0,
    },
});

watchEffect(() => {
    if ((props.productId && props.product) || (!props.productId && !props.product)) {
        throw new Error('You must provide either a productId or a product for AddToCart component');
    }
});

const { fetchProduct } = useProduct();
const { orders, addProduct, createOrder } = useCart();

const product = ref(props.product);
const loading = ref(false);
const modalVisibility = reactive({
    orderSelect: false,
    orderItemCorrection: false,
});

const cartItem = computed(() => {
    let existingItem = null;
    
    for (const order of Object.values(orders.value)) {
        const item = order.items[props.productId] || order.items[product.value?.id];

        if (item) {
            existingItem = item;
            break;
        }
    }

    return existingItem;
});

const buttonCaption = computed(() => {
    return (
        !cartItem.value?.baseQuantity && 
        !cartItem.value?.partialQuantity
    ) ? 'В кошик' : 'У кошику';
});

const addToCart = async (order: Cart.Order | null = null) => {
    if (!product.value) {
        loading.value = true;
        product.value = await fetchProduct(props.productId);
        loading.value = false;
    }

    if (!product.value) return;

    const ordersCount = Object.keys(orders.value).length;

    if (ordersCount && !order) {
        if (ordersCount > 1) {
            modalVisibility.orderSelect = true;
            return;
        }

        order = Object.values(orders.value)[0];

        if (order.pharmacy) {
            modalVisibility.orderSelect = true;
            return;
        } else if (product.value.id in order.items) {
            modalVisibility.orderItemCorrection = true;
            return;
        }
    } else {
        order = order || await createOrder();
    }

    if (!order) return;

    const cartItem = {
        product: product.value,
        baseQuantity: props.baseQuantity,
        partialQuantity: props.partialQuantity,
    }

    addProduct(order, cartItem);    
}
</script>

<template>
    <button
        class="add-to-cart"
        :class="{
            active: cartItem
        }"
        :disabled="loading"
        @click.prevent="() => addToCart(null)"
    >
        {{ loading ? 'Зачекайте...' : buttonCaption }}

        <ModalOrderSelect
            v-model="modalVisibility.orderSelect"
            :product="product"
        />

        <ModalOrderItemCorrection
            v-if="Object.values(orders)[0]"
            v-model="modalVisibility.orderItemCorrection"
            :product="product"
            :order="Object.values(orders)[0]"
        />
    </button>
</template>

<style lang="scss">
.add-to-cart {
    background-color: #EE3936;
    padding: .375em 1.375em;
    text-align: center;
    font-weight: bold;
    font-size: .875em;
    border-radius: .875em;
    cursor: pointer;
    border: none;
    color: #FFF;
    transition:
        background .4s ease,
        color .4s ease;

    &.active {
        $border-width: 2px;

        background-color: transparent;
        padding: calc(.375em - $border-width) calc(1.375em - $border-width);
        border: $border-width solid #EE3936;
        color: #212E42;
    }

    &:hover {
        background-color: #CF3836;
        color: #FFFFFF;
    }

    &:disabled {
        background: #4bc2c64d;
    }
}
</style>
