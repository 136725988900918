<script setup lang="ts">
const props = defineProps({
    product: {
        type: [Object, null] as PropType<Products.Product | null>,
        required: true,
    },
});

const modalVisible = defineModel();
const router = useRouter();

const { orders, createOrder, addProduct } = useCart();

const hasItemInOrders = computed(() => {
    if (!props.product) return false;

    return Object.values(orders.value).some(order => props.product && props.product.id in order.items);
});

const hasUnfinishedOrder = computed(() => {
    return Object.values(orders.value).some(order => !order.pharmacy);
});

const selectOrder = async (order: Cart.Order | null) => {
    if (!props.product) return;

    if (!order) {
        order = await createOrder();
    }

    const cartItem = {
        product: props.product,
        baseQuantity: 1,
        partialQuantity: 0,
    }

    addProduct(order, cartItem);
}

const redirectToCart = () => {
    router.push({ name: 'cart' });
}

const getOrderItemsCountText = (itemsCount: number) => {
    const lastDigit = itemsCount % 10;
    const lastTwoDigits = itemsCount % 100;

    if (!itemsCount || itemsCount === 0) return ''

    if (lastTwoDigits >= 11 && lastTwoDigits <= 14) {
        return `${itemsCount} товарів`;
    } else if (lastDigit === 1) {
        return `${itemsCount} товар`;
    } else if (lastDigit >= 2 && lastDigit <= 4) {
        return `${itemsCount} товари`;
    } else {
        return `${itemsCount} товарів`;
    }
}
</script>

<template>
    <Modal
        v-model="modalVisible"
        class="order-select-modal"
    >
        <template #header>
            <span class="title">
                {{ hasItemInOrders ? 'Ваші замовлення:' : 'В яке із замовлень Ви бажаєте додати товар?' }}
            </span>
        </template>

        <template #body>
            <span
                v-if="!hasItemInOrders"
                class="label"
            >
                Ваші замовлення:
            </span>

            <ul class="orders">
                <li
                    v-for="order in orders"
                    :key="order.id"
                >
                    <div class="order">
                        <template v-if="order.pharmacy">
                            <div class="pharmacy-title">
                                {{ order.pharmacy.name }}
                            </div>

                            <div class="pharmacy-work-time">
                                {{ order.pharmacy.workTime }}
                            </div>

                            <div class="pharmacy-address">
                                {{ order.pharmacy.address }}
                            </div>

                            <template v-if="
                                product?.productRemnants.some(remnant => remnant.storeId === order.pharmacy?.id)
                            ">
                                <div class="status-available">
                                    Доступно - {{
                                    product.productRemnants.find(remnant => remnant.storeId ===
                                    order.pharmacy?.id)?.quantity
                                    }} шт.
                                </div>
                            </template>

                            <template v-else>
                                <div class="status-empty">
                                    Немає в наявності
                                </div>
                            </template>
                        </template>

                        <template v-else>
                            <div class="no-selection">
                                Спосіб отримання не вибраний
                            </div>

                            <div
                                v-if="product"
                                class="status-available"
                            >
                                Доступно - {{
                                product.productRemnants?.reduce((count, remnant) => {
                                return count += Math.floor(remnant.quantity);
                                }, 0)
                                }} шт.
                            </div>
                        </template>

                        <div class="count">
                            {{ getOrderItemsCountText(Object.keys(order.items).length) }}
                        </div>

                        <div class="product-title">
                            {{ product?.shortName }}
                        </div>

                        <div
                            v-if="product && product.id in order.items"
                            class="in-cart"
                        >
                            У кошику - {{ order.items[product.id].quantity }} шт.
                        </div>

                        <template v-if="product">
                            <button
                                v-if="
                                    !(product.id in order.items) && 
                                    product.productRemnants.some(remnant => remnant.storeId === order.pharmacy?.id)
                                "
                                class="add-to-order"
                                @click.prevent="selectOrder(null)"
                            >
                                <IconPlus class="icon" />
                                Додати до замовлення
                            </button>

                            <ProductQuantity
                                v-if="product.id in order.items"
                                class="product-quantity"
                                :product="product"
                                :order="order"
                            />

                            <CommonPrice
                                v-if="product.id in order.items"
                                class="product-price"
                                :price="order.items[product.id].price"
                            />
                        </template>
                    </div>
                </li>
            </ul>

            <button
                v-if="!hasUnfinishedOrder"
                class="add-new-order"
                @click.prevent="selectOrder(null)"
            >
                Додати в нове замовлення
            </button>

            <button
                class="dismiss"
                @click.prevent="modalVisible = false"
            >
                Продовжити покупки
            </button>

            <button
                class="go-to-cart"
                @click.prevent="redirectToCart(); modalVisible = false"
            >
                Перейти до кошика
            </button>
        </template>
    </Modal>
</template>

<style lang="scss">
@use '~/assets/styles/mixins.scss';

.order-select-modal {
    .modal-content-header,
    .modal-content-body,
    .modal-content-footer {
        text-align: left;
        width: 50vw;

        @include mixins.respond-to(tablet) {
            width: 75vw;
        }

        @include mixins.respond-to(phone) {
            width: 100%;        
        }
    }

    >.modal-content>.modal-content-header {
        text-align: left;
    }

    >.modal-content>.modal-content-body {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: start;
        justify-items: start;
        column-gap: 1.5em;

        @include mixins.respond-to(phone) {
            grid-template-columns: 1fr;
        }
    }

    .title {
        font-size: 1.5rem;
        line-height: 1;
        font-weight: 700;
        text-align: left;
    }

    .label {
        grid-row: 1;
        grid-column: 1;
    }

    .orders {
        grid-column: 1 / 3;
        grid-row: 2;
        display: flex;
        flex-direction: column;
        gap: 1em;
        margin-top: 0.75em;
        padding: 0;
        list-style: none;
        max-height: 60vh;
        overflow: auto;

        @include mixins.respond-to(phone) {
            grid-column: 1;
        }

        .order {
            display: grid;
            grid-template-columns: 1.4fr 1.4fr 1fr;
            column-gap: 4em;
            row-gap: .135em;
            padding: 1.25em;
            border: 1px solid #E7E9E9;
            border-radius: .75em;
            cursor: pointer;
            transition: border-color .25s ease;
            line-height: 1.5;

            @include mixins.respond-to(phone) {
                grid-template-columns: unset;
            }

            &:hover {
                border-color: #4BC2C6;
            }

            &:has(>.status-empty) {
                pointer-events: none;

                *:not(.status-empty) {
                    opacity: .5;
                }
            }

            >.pharmacy-title, .no-selection {
                grid-column: 1;
                grid-row: 1;
                color: #000000;
                font-weight: 700;
            }

            >.pharmacy-work-time {
                grid-column: 1;
                grid-row: 2;
                color: #484B4C;
                font-size: .875em;
            }

            >.pharmacy-address {
                grid-column: 1;
                grid-row: 3;
                color: #000000;
                font-size: .875em;
                font-weight: 500;

                @include mixins.respond-to(phone) {
                    margin-bottom: 1em;
                }
            }

            >.count {
                grid-column: 1;
                grid-row: 4;
                color: #4BC2C6;
                font-weight: 600;
                margin-top: .5em;

                @include mixins.respond-to(phone) {
                    grid-column: 2;
                    grid-row: 1 / 4;
                    margin: unset;
                    align-self: center;
                    margin-bottom: 1em;
                }
            }

            >.product-title {
                grid-column: 2;
                grid-row: 1 / 4;
                color: #000000;
                font-weight: 500;

                @include mixins.respond-to(phone) {
                    grid-column: 1 / 3;
                    grid-row: 4;

                    padding-top: 1em;
                    border-top: 1px solid #D4D4D4;
                }
            }

            >.in-cart {
                grid-column: 3;
                grid-row: 2 / 4;
                font-weight: 500;
                line-height: 1.3;
                text-align: right;
                color: #27AE60;
                background: #27AE6014;
                padding: .25em .5em;
                border-radius: .25em;
                align-self: start;
                justify-self: end;
                margin-top: .5em;
                font-size: clamp(.875em, 3vw, 1em);

                @include mixins.respond-to(phone) {
                    grid-column: 1;
                    grid-row: 7;
                    justify-self: start;
                }
            }

            >.product-quantity {
                grid-column: 2;
                grid-row: 4;

                @include mixins.respond-to(phone) {
                    grid-column: 1 / 3;
                    grid-row: 6;
                    margin: 1em 0;
                }
            }

            >.product-price {
                grid-column: 3;
                grid-row: 4;
                font-size: 1.25em;
                align-self: end;
                justify-self: end;
                line-height: 1;
                font-weight: 700;

                @include mixins.respond-to(phone) {
                    grid-column: 2;
                    grid-row: 7;
                    color: #000;
                }
            }

            >.add-to-order {
                grid-column: 2;
                grid-row: 4;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: .625em;
                border-radius: 0.75rem;
                padding: .5em 1em;
                color: #4BC2C6;
                font-weight: 500;
                border: 2px solid #4BC2C6;
                transition: background .3s ease, color .3s ease;

                @include mixins.respond-to(phone) {
                    grid-column: 1 / 3;
                    grid-row: 6;
                    margin: 1em 0;
                }

                &:hover {
                    color: #FFFFFF;
                    background: #4BC2C6;

                    .icon {
                        transform: rotate(-360deg) scale(1.1);

                        path {
                            fill: #FFFFFF;
                        }
                    }
                }

                .icon {
                    width: 1em;
                    height: 1em;
                    transition: transform .3s ease;

                    path {
                        transition: fill .3s ease;
                        fill: #4BC2C6;
                    }
                }
            }

            >.status-available, >.status-empty {
                grid-column: 3;
                grid-row: 1 / 3;
                text-align: right;
                font-size: clamp(.875em, 3vw, 1em);

                @include mixins.respond-to(phone) {
                    grid-column: 1 / 3;
                    grid-row: 5;
                    font-weight: 500;
                    text-align: left;
                }
            }

            >.status-available {
                color: #27AE60;
            }

            >.status-empty {
                color: #000;
            }
        }
    }

    .add-new-order {
        grid-column: 1;
        grid-row: 3;
        color: #4BC2C6;
        font-weight: 600;
        line-height: 1;
        text-decoration: underline;
        margin-bottom: 1.5em;

        &:hover {
            text-decoration: none;
        }
    }

    .dismiss {
        grid-column: 1;
        grid-row: 4;
        padding: .75em;
        border-radius: .75em;
        border: 2px solid #EE3936;
        line-height: 1;
        justify-self: stretch;
        font-weight: 500;
        color: #212E42;
        transition: background .25s ease, border-color .25s ease;

        @include mixins.respond-to(phone) {
            grid-column: 1;
            grid-row: 5;
            margin-top: .5em;
        }

        &:hover {
            background: #f5f7fa;
            border-color: #CF3836;
        }
    }

    .go-to-cart {
        grid-column: 2;
        grid-row: 4;
        border-radius: .75em;
        padding: .75em;
        line-height: 1;
        justify-self: stretch;
        background: #EE3936;
        border: 2px solid #EE3936;
        font-weight: 500;
        color: #FFFFFF;
        transition: background .25s ease, border-color .25s ease;

        @include mixins.respond-to(phone) {
            grid-column: 1;
            grid-row: 4;
        }

        &:hover {
            background: #CF3836;
            border-color: #CF3836;
        }
    }
}
</style>
