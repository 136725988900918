export function useFooter() {
    const { public: { apiBase: APIPrefix } } = useRuntimeConfig();

    const footerItems = useState<[Footer.Item[], Footer.Item[]]>(
        'footerItems',
        () => [[], []]
    );

    const fetchFooterItems = async (): Promise<[Footer.Item[], Footer.Item[]]> => {
        const [ firstColumnPromise, secondColumnPromise ] =
            await Promise.all([
                $fetch<Footer.Item[]>(`${APIPrefix}/pages/menu/1`, {
                    method: 'GET',
                }),
                $fetch<Footer.Item[]>(`${APIPrefix}/pages/menu/2`, {
                    method: 'GET',
                }),
            ]);

        return [
            firstColumnPromise || [],
            secondColumnPromise || [],
        ];
    };

    return {
        footerItems,
        fetchFooterItems,
    };
}
