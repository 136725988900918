<script setup lang="ts">
const props = defineProps({
    price: {
        type: Number as PropType<number>,
        required: true,
    },
    currency: {
        type: String as PropType<'грн'>,
        default: 'грн',
    }
});

const total = computed(() => {
    return {
        integer: !isNaN(props.price) ? Math.floor(props.price) : '???',
        decimal:  `.${!isNaN(props.price) ? props.price.toFixed(2).slice(-2) : '??'}`
    }
});
</script>

<template>
    <div class="price">
        <span class="integer">{{ total.integer }}</span>
        <span class="decimal">{{ total.decimal }}</span>
        <span class="currency">{{ ' ' + currency }}</span>
    </div>
</template>

<style lang="scss">
.price {
    >.integer, >.currency {
        font-size: 1em;
    }

    >.decimal {
        font-size: .8em;
    }
}
</style>
