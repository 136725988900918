<script setup lang="ts">
defineProps({
    items: {
        type: Object as PropType<[
            Footer.Item[],
            Footer.Item[],
            Categories.RootCategory[],
        ]>,
        required: true,
    },
});

const { settings, locale } = useApp();

const socialIcons: { [key in string]: any } = {};

for (const socialLink in settings.value?.social || {}) {
    const iconLabel = socialLink.split('_url')[0];

    socialIcons[socialLink] = defineAsyncComponent(() => import(`~/components/Icon/Social/${iconLabel.charAt(0).toUpperCase() + iconLabel.slice(1)}.vue`));
}

const getWorkTime = (data: string = '{}') => {
    return JSON.parse(data)[locale.value];
}
</script>

<template>
    <div class="container-fluid">
        <div class="footer">
            <div>
                <div class="footer-left">
                    <NuxtLink
                        to="/"
                        rel="nofollow"
                        aria-label="eTabletka"
                    >
                        <IconFooterLogo class="logo"/>
                    </NuxtLink>

                    <a
                        v-if="settings?.common?.support_phone?.value"
                        :href="`tel:+${settings.common.support_phone.value}`"
                        class="phone"
                    >
                        {{ settings.common.support_phone.value }}
                    </a>

                    <div v-if="settings?.common?.work_time?.value" class="work-time">
                        {{ getWorkTime(settings.common.work_time.value) }}
                        <br />
                        Безкоштовно в межах України
                    </div>

                    <NuxtLink
                        v-if="settings?.common?.['support_email']?.value"
                        :to="`mailto:${settings.common?.['support_email'].value}`"
                        class="email"
                        external
                    >
                        {{ settings.common?.['support_email'].value }}
                    </NuxtLink>

                    <div class="social-links">
                        <h1 class="title">Ми в соціальних мережах:</h1>

                        <div class="links-container">
                            <NuxtLink 
                                v-for="socLink in settings?.social || {}"
                                :key="`social-link-${socLink.id}`"
                                :to="socLink.value"
                                rel="nofollow" 
                                target="_blank"
                                :class="socLink.type"
                                :aria-label="`eTabletka у ${socLink.title}`"
                                external
                            >
                                <component :is="socialIcons[socLink.key]" />
                            </NuxtLink>
                        </div>
                    </div>

                </div>

                <div class="footer-right">
                    <div class="navs">
                        <div class="navs-column" v-if="items[0]">
                            <h1 class="title">Покупцеві</h1>

                            <ul>
                                <li v-for="link in items[0]" :key="link.id">
                                    <NuxtLink :to="'/page/'+link.slug">
                                        {{ link?.title }}
                                    </NuxtLink>
                                </li>
                            </ul>
                        </div>

                        <div class="navs-column" v-if="items[1]">
                            <h1 class="title">Компанія</h1>

                            <ul>
                                <li v-for="link in items[1]" :key="link.id">
                                    <NuxtLink :to="'/page/'+link.slug">
                                        {{ link?.title }}
                                    </NuxtLink>
                                </li>
                            </ul>
                        </div>

                        <div class="navs-column" v-if="items[2]">
                            <h1 class="title">Категорії</h1>

                            <ul>
                                <li
                                    v-for="category in items[2]"
                                    :key="`footerCategory-${category.id}`"
                                >
                                    <NuxtLink :to="'/categories/'+category?.id">
                                        {{ category?.name }}
                                    </NuxtLink>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="contacts">
                        <h1 class="title">Способи оплати</h1>

                        <div class="payment">
                            <IconMastercard />
                            <IconVisa />
                        </div>

                        <div class="license">
                            Приватне підприємство Конекс
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <IconFooterOne />
                <IconFooterTwo />
            </div>
        </div>
    </div>
</template>

<style lang="scss">
@use '~/assets/styles/mixins.scss';

.footer {
    $border-size: .375em;

    background: #212E42;
    border-top: $border-size solid #4BC2C6;
    padding: 4.625em 0 0 0;
    color: #fff;
    position: relative;

    @mixin dimmed {
        fill: #FFFFFF;
        color: #FFFFFF;
        opacity: .2;
    }

    &::after {
        content: '';
        background: #212E42;
        position: absolute;
        height: calc(100% + $border-size);
        width: 150%;
        top: calc($border-size * -1);
        left: -25%;
        z-index: -1;
        border-top: $border-size solid #4BC2C6;
    }

    > :first-child {
        display: flex;
        justify-content: space-between;
        padding-bottom: 3.5em;
        border-bottom: 1px solid rgba(231, 233, 233, 0.1);
        
        @include mixins.respond-to(desktop) {
            > :first-child {
                margin-right: 5%;
            }
        }

        @include mixins.respond-to(phone) {
            flex-direction: column;

            > :first-child {
                margin-bottom: 1em;
            }
        }

        .footer-left {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-self: flex-start;

            @include mixins.respond-to(desktop) {
                flex: 20%;
            }

            @include mixins.respond-to(tablet) {
                flex: 15%;
            }

            @include mixins.respond-to(phone) {
                align-items: center;
                text-align: center;
                align-self: stretch;
            }

            > * {
                margin-bottom: .75em;
            }

            .logo {
                width: 5.25em;
                height: auto;

                @include mixins.respond-to(desktop, tablet) {
                    margin-left: .625em;
                }

                [fill="#3C3C3B"] {
                    @include dimmed;
                }
            }

            .phone {
                font-weight: bold;
                font-size: 1.375em;
                line-height: 120%;
                color: #FFFFFF;

                &:hover {
                    text-decoration: underline;
                }
            }

            .work-time {
                @include dimmed;
                font-weight: 500;
                font-size: .875em;
                line-height: 160%;
            }

            .email {
                font-weight: bold;
                font-size: 1.125em;
                line-height: 120%;
                color: #FFFFFF;

                &:hover {
                    text-decoration: underline;
                }
            }

            .social-links {
                .title {
                    @include dimmed;
                    font-weight: 500;
                    font-size: .875em;
                    line-height: 160%;
                    margin-bottom: .75em;
                }

                .links-container {
                    display: flex;

                    @include mixins.respond-to(tablet) {
                        flex-flow: row wrap;
                    }

                    a {
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        width: 2.25em;
                        height: 2.25em;
                        border-radius: 50%;
                        box-shadow: 0px .5em 1.5em rgba(0, 0, 0, 0.08);
                        border: 1px solid #FFFFFF;
                        margin-right: .375em;
                        padding: .5em;

                        @include mixins.respond-to(tablet) {
                            margin: 0 .375em .375em 0;
                        }
    
                        &:hover {
                            opacity: 0.7;
                        }

                        > svg {
                            width: 100%;
                            height: 100%;

                            * {
                                fill: #FFFFFF;
                            }
                        }
                    }
                }
            }
        }

        .footer-right {
            display: flex;
            justify-content: space-between;

            @include mixins.respond-to(desktop) {
                flex: 75%;
            }

            @include mixins.respond-to(tablet) {
                flex: 80%;
            }

            > .navs {
                flex: 80%;
                display: flex;
                justify-content: space-between;
                
                @include mixins.respond-to(desktop) {
                    margin-right: 10%;
                }

                @include mixins.respond-to(tablet) {
                    > .navs-column {
                        flex: 1;
                        margin-right: .5em;
                    }
                }

                @include mixins.respond-to(phone) {
                    display: none;
                }

                h1 {
                    @include dimmed;
                    margin: 0 0 .75em 0;
                    font-weight: bold;
                    font-size: 1.125em;
                    line-height: 120%;
                }

                ul {
                    list-style: none;
                    padding: unset;
                    margin: unset;

                    a {
                        font-weight: 500;
                        font-size: 0.875em;
                        line-height: 135%;
                        color: #FFFFFF;
                        padding: .75em 0;
                        display: block;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }

            > .contacts {
                flex: 25%;
                display: flex;
                flex-direction: column;

                @include mixins.respond-to(phone) {
                    align-items: center;
                }

                > * {
                    margin-bottom: 1em;
                }
                
                .title {
                    font-weight: bold;
                    font-size: 1.125em;
                    line-height: 115%;
                    color: #FFFFFF;
                    margin: 0 0 1em 0;
                }

                .payment {
                    display: flex;

                    > *:not(:last-child) {
                        margin-right: 1em;
                    }

                    > svg {
                        width: 2.5em;
                        height: 2.5em;
                    }
                }

                .license {
                    font-size: .75em;
                    line-height: 140%;
                }
            }
        }
    }

    > :nth-child(2) {
        display: flex;
        flex-direction: column;
        align-items: center;
        opacity: .12;

        @include mixins.respond-to(desktop) {
            padding: 3.5em 5%;
        }

        @include mixins.respond-to(tablet, phone) {
            padding: 3.5em 1%;
        }

        > * {
            width: 100%;
            height: auto;
        }

        > :nth-child(2) {
            padding: 0 2%;
        }
    }
}
</style>
