<template>
    <header>
        <HeaderNavbar />
        <HeaderStickyBar />
    </header>
</template>

<style lang="scss">
header {
    position: relative;
    z-index: 11;
}
</style>
