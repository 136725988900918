<template>
    <svg
        viewBox="0 0 10 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M0.500123 0.75L5.00012 5.25L9.50012 0.75"
            stroke="black"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>
