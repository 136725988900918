<script setup lang="ts">
const { setMenuState } = useApp();
const { city: userCity } = useUser();

const props = defineProps({
    cities: {
        type: Object as PropType<Locations.City[]>,
        requred: true,
    }
});

const setCity = (city: Locations.City) => {
    userCity.value = city;
    setMenuState('city', false);
};
</script>

<template>
    <div class="city-list">
        <div 
            v-for="city in props.cities" 
            class="city-item" 
            @click.prevent="setCity(city)"
        >
            <IconCityLogo />
            <span class="value">
                {{ city.name }}
            </span>
        </div>
    </div>
</template>

<style lang="scss">
@use '~/assets/styles/mixins.scss';

.city-list {
    $gap: clamp(.5em, 3vw, 2.25em);

    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    gap: $gap;

    @include mixins.respond-to(phone) {
        justify-content: space-evenly;
    }

    .city-item {
        display: inline-flex;
        align-items: center;
        font-weight: 600;
        font-size: 1em;
        line-height: 100%;
        word-break: break-word;
        color: #000000;
        transition: color .25s ease;
        cursor: pointer;
        gap: .625em;

        @include mixins.respond-to(desktop) {
            flex: calc(16.666% - $gap) 0 0;
        }

        @include mixins.respond-to(tablet) {
            flex: calc(25% - $gap) 0 0;
        }

        @include mixins.respond-to(phone) {
            flex: calc(50% - $gap) 0 0;
        }

        >svg {
            width: 1.5em;
            height: 1.5em;
            flex-shrink: 0;
        }

        &:hover {
            color: #10A5AA;
        }
    }
}
</style>