<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 32 32"
    >
        <path
            d="M 14.558594 26.558594 C 7.839844 26.558594 2.398438 21.121094 2.398438 14.398438 C 2.398438 7.679688 7.839844 2.238281 14.558594 2.238281 C 21.28125 2.238281 26.71875 7.679688 26.71875 14.398438 C 26.71875 21.121094 21.121094 26.558594 14.558594 26.558594 Z M 14.558594 5.601562 C 9.601562 5.601562 5.601562 9.601562 5.601562 14.558594 C 5.601562 19.519531 9.601562 23.519531 14.558594 23.519531 C 19.519531 23.519531 23.519531 19.519531 23.519531 14.558594 C 23.519531 9.601562 19.359375 5.601562 14.558594 5.601562 Z M 28 29.601562 C 27.519531 29.601562 27.199219 29.441406 26.878906 29.121094 L 20.800781 23.039062 C 20.160156 22.398438 20.160156 21.441406 20.800781 20.800781 C 21.441406 20.160156 22.398438 20.160156 23.039062 20.800781 L 29.121094 26.878906 C 29.761719 27.519531 29.761719 28.480469 29.121094 29.121094 C 28.800781 29.441406 28.480469 29.601562 28 29.601562 Z M 28 29.601562 "
        />
    </svg>
</template>
