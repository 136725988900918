<script setup lang='ts'>
const { city: userCity } = useUser();
const {
    menusState,
    headerMenuItems,
    setMenuState,
    fetchHeaderMenuItems,
} = useApp();

if (!headerMenuItems.value.length) {
    const { data } = await useAsyncData('fetchHeaderMenuItems', fetchHeaderMenuItems);
    headerMenuItems.value = data.value || [];
}
</script>

<template>
    <nav>
        <ul class="navbar-nav">
            <li class="nav-item" v-if="userCity">
                <button 
                    class="location"
                    @click="() => { setMenuState('city', !menusState.city) }" 
                >
                    <IconMapPin />
                    <span v-if="userCity?.name" class="city-name">{{ userCity?.name }}</span>
                </button>
            </li>
            <li
                v-if="headerMenuItems.length"
                v-for="(menuItem, index) in headerMenuItems"
                :key="index"
                class="nav-item"
            >
                <NuxtLink :to="`/${menuItem.slug}`">{{ menuItem?.title }}</NuxtLink>
            </li>
        </ul>
    </nav>
</template>

<style lang="scss">
.location {
    svg {
        width: 1.25em;
        height: auto;
        margin-right: .5em;
        stroke-width: .13em;

        * {
            stroke: #4bc2c6;
        }
    }

    .city-name {
        font-weight: 600;
        border-bottom: 1px solid #484B4C;
        transition: 0.4s;
        color: #484B4C;
    }

    &:hover .city-name {
        color: #10A5AA;
        border-color: transparent;
    }
}
</style>
