<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 32 32"
        version="1.1"
    >
        <path
            style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;"
            d="M 14 15.066406 L 6 15.066406 C 5.464844 15.066406 4.933594 14.667969 4.933594 14 L 4.933594 6 C 4.933594 5.464844 5.332031 4.933594 6 4.933594 L 14 4.933594 C 14.535156 4.933594 15.066406 5.332031 15.066406 6 L 15.066406 14 C 15.066406 14.535156 14.535156 15.066406 14 15.066406 Z M 7.066406 13.066406 L 13.066406 13.066406 L 13.066406 7.066406 L 7.066406 7.066406 Z M 26 15.066406 L 18 15.066406 C 17.464844 15.066406 16.933594 14.667969 16.933594 14 L 16.933594 6 C 16.933594 5.464844 17.332031 4.933594 18 4.933594 L 26 4.933594 C 26.535156 4.933594 27.066406 5.332031 27.066406 6 L 27.066406 14 C 27.066406 14.535156 26.535156 15.066406 26 15.066406 Z M 19.066406 13.066406 L 25.066406 13.066406 L 25.066406 7.066406 L 19.066406 7.066406 Z M 14 27.066406 L 6 27.066406 C 5.464844 27.066406 4.933594 26.667969 4.933594 26 L 4.933594 18 C 4.933594 17.464844 5.332031 16.933594 6 16.933594 L 14 16.933594 C 14.535156 16.933594 15.066406 17.332031 15.066406 18 L 15.066406 26 C 15.066406 26.535156 14.535156 27.066406 14 27.066406 Z M 7.066406 25.066406 L 13.066406 25.066406 L 13.066406 19.066406 L 7.066406 19.066406 Z M 26 27.066406 L 18 27.066406 C 17.464844 27.066406 16.933594 26.667969 16.933594 26 L 16.933594 18 C 16.933594 17.464844 17.332031 16.933594 18 16.933594 L 26 16.933594 C 26.535156 16.933594 27.066406 17.332031 27.066406 18 L 27.066406 26 C 27.066406 26.535156 26.535156 27.066406 26 27.066406 Z M 19.066406 25.066406 L 25.066406 25.066406 L 25.066406 19.066406 L 19.066406 19.066406 Z M 19.066406 25.066406 "
        />
    </svg>
</template>
