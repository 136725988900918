export function useSearch() {
    const { public: { apiBase } } = useRuntimeConfig();

    const searchResults = useState<Products.SearchResult[]>('searchResults', () => []);
    const facetFilters = useState<{ [key: Products.Filter['alias']]: string }>('facetFilters', () => ({}));

    const getFacetFiltersString = computed(() => {
        if (!Object.keys(facetFilters.value).length) return '';

        return Object.entries(facetFilters.value).map(([key, value]) => `${key}:${value}`).join('/');
    });

    const searchByString = async (queryString: string) => {
        const response = await $fetch<Products.SearchQueryResponse>(`${apiBase}/search`, {
            method: "POST",
            body: {
                text: queryString
            }
        });

        for (const searchItem of response.hits) {
            Object.assign(searchItem, {
                cdnData: searchItem.cdn_data
            });
            delete searchItem.cdn_data;
        }

        return response.hits as Products.SearchResult[];
    };

    const searchByFilters = async (
        options: {
            page?: number,
            limit?: number,
            sort?: string,
            text?: string,
            filter?: typeof facetFilters.value,
        } = {
            page: 1,
            limit: 16,
            sort: '',
            text: '',
            filter: {},
        }
    ) => {
        const requestOptions: {
            sort?: string[];
            filter?: string;
            limit?: number;
            offset?: number;
            text?: string;
            lang: string;
        } = {
            filter: options.filter && Object.keys(options.filter).length
                ? Object.entries(options.filter).map(([key, value]) => `${key}:${value}`).join('/')
                : undefined,
            limit: options.limit || 16,
            offset: ((typeof options.page === 'number' ? options.page : 1) - 1) * (options.limit || 16),
            sort: options.sort ? [options.sort] : undefined,
            text: options.text || '',
            lang: useApp().locale.value,
        };

        const response = await $fetch<Products.FacetSearchResult>(`${apiBase}/facet-search`, {
            method: "POST",
            body: requestOptions
        });

        return response;
    };

    const searchByPharmacy = async (pharmacyId: number, query: string) => {
        // TODO: add search by pharmacy request
        return [];
    }

    return {
        facetFilters,
        searchResults,
        getFacetFiltersString,
        searchByString,
        searchByFilters,
        searchByPharmacy,
    };
}
