import { jwtDecode } from "jwt-decode";

export function useUser() {
    const { public: { apiBase: APIPrefix } } = useRuntimeConfig();

    const loggedIn = useState('loggedIn', () => false);
    const city = useState<Locations.City | null>('city', () => useApp().defaultCity.value);
    const user = useState('user', () => ({}));

    const register = async (data: Auth.RegisterRequest) => {
        const response = await $fetch<Auth.RegisterResponse>(`${APIPrefix}/user/create`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        return response;
    };

    const login = async (data: Auth.LoginRequest) => {
        const response = await $fetch<Auth.LoginResponse>(`${APIPrefix}/login`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        if (response?.token) {
            const decodeToken = jwtDecode<Auth.TokenPayload>(response.token);
            if (decodeToken?.id) {
                user.value = await getMe(decodeToken.id);
            }
            loggedIn.value = true;
        }

        return response;
    };

    const getMe = async (id: number) => {
        return await $fetch(`${APIPrefix}/user/${id}`, {
            method: "GET",
        });
    };

    const callMe = async (phone: string) => {
        await $fetch(`${APIPrefix}/callme`, {
            method: "POST",
            body: {
                phone,
            },
        });
    };

    return {
        loggedIn,
        city,
        user,
        register,
        login,
        getMe,
        callMe,
    };
}
