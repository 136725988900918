<script setup lang="ts">

const props = defineProps({
    articles: {
        type: Object as PropType<Blog.Article[]>,
        default: []
    }
});
</script>

<template>
    <div class="blog-items">
        <div v-for="blogArticle of props.articles" :key="'blog-item-' + blogArticle.id" class="blog-item">
            <NuxtLink 
                :to="`/blog/${blogArticle?.categories[0]?.slug}/${blogArticle?.slug}`" 
                class="item-image"
            >
                <NuxtImg
                    format="webp"
                    loading="lazy"
                    sizes="xs:430 sm:680 md:580 lg:410"
                    :src="blogArticle?.cdnData?.url"
                    :alt="blogArticle?.alt || `Ілюстрація до статті «${blogArticle.title}»`"
                />
            </NuxtLink>

            <div class="item-info">
                <div class="tags">
                    <NuxtLink
                        v-for="category in blogArticle?.categories"
                        :to="`/blog/${category.slug}`"
                        :key="'blog-category-' + category.id"
                        class="tag"
                    >
                        {{ category.title }}
                    </NuxtLink>
                </div>

                <div class="meta">
                    <span class="date">{{ blogArticle?.publishedAt }}</span>

                    <div class="comments">
                        <IconComment />
                        <span class="value">{{ blogArticle?.commentCount || 0 }}</span>
                    </div>
                </div>

                <NuxtLink
                    :to="`/blog/${blogArticle?.categories[0]?.slug}/${blogArticle?.slug}`"
                    class="title"
                >
                    {{ blogArticle?.title }}
                </NuxtLink>

                <div class="author">
                    <IconUser class="author-photo" />
                    <div class="author-name">{{ blogArticle?.author?.firstName }} {{ blogArticle?.author?.lastName }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
@use '~/assets/styles/mixins';

.blog-items {
    display: grid;
    column-gap: 2em;
    row-gap: 2.5em;
    margin-bottom: max(3%, 1em);

    @include mixins.respond-to(desktop) {
        grid-template-columns: 1fr 1fr 1fr;
    }

    @include mixins.respond-to(tablet) {
        grid-template-columns: 1fr 1fr;
    }

    @include mixins.respond-to(phone) {
        grid-template-columns: 1fr;
    }

    >.blog-item {
        position: relative;
        display: flex;
        flex-direction: column;

        >:first-child {
            margin-bottom: 1.25em;
        }

        .item-image {
            position: relative;
            display: flex;
            align-items: center;
            overflow: hidden;
            border-radius: .75em;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                aspect-ratio: 31 / 15;
            }
        }

        .item-info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex: 1;
            gap: .5em;

            .meta {
                display: flex;
                align-items: center;

                > :first-child {
                    margin-right: .625em;
                }

                .comments {
                    display: flex;
                    align-items: center;

                    > :first-child {
                        margin-right: .375em;
                    }

                    >svg {
                        width: 1.125em;
                        height: 1.125em;
                    }
                }

                .value,
                .date {
                    font-weight: 500;
                    font-size: .875em;
                    line-height: 115%;
                }
            }

            .tags {
                display: flex;
                flex-flow: row wrap;
                align-items: flex-start;

                > :not(:last-child) {
                    margin: 0 .5em .5em 0;
                }

                .tag {
                    background: #4BC2C6;
                    border-radius: .35em;
                    padding: .5em 1em;
                    font-weight: 500;
                    font-size: .75em;
                    line-height: 100%;
                    color: #FFFFFF;

                    &:hover {
                        color: #4BC2C6;
                        background: #FFFFFF;
                    }
                }
            }

            .title {
                display: block;
                font-weight: bold;
                font-size: 1.5em;
                line-height: 130%;
                color: #212E42;
                margin-bottom: auto;

                &:hover {
                    color: #10A5AA;
                }

                @include mixins.respond-to(phone) {
                    font-size: 1.25em;
                    margin-bottom: .75em;
                }
            }

            .author {
                display: flex;
                align-items: center;

                .author-photo {
                    border: 1px solid #000000;
                    border-radius: 50%;
                    width: 2.25em;
                    height: 2.25em;
                    overflow: hidden;
                    position: relative;
                    margin-right: .75em;
                }

                .author-name {
                    font-weight: 600;
                    font-size: .875em;
                    line-height: 120%;
                }
            }
        }
    }
}
</style>