export function useFavourites() {
    const favourites = useState<Favourites.FavouritesObject>('favourites', () => ({
        '': {},
        products: {},
        pharmacies: {},
    }));

    const getFavouritesCount = (type: Favourites.Type = '') => {
        return Object.keys(type ? favourites.value[type] : favourites.value).length;
    };

    return {
        favourites,
        getFavouritesCount,
    };
}
