<script setup lang="ts">
const { setMenuState } = useApp();
const {
    categories,
    rootCategories,
    fetchCategory
} = useCategory();

const activeCategoryId = ref<Categories.Category['id'] | null>(null);
const hovered = ref(false);

const activeCategory = computed(() => {
    return activeCategoryId.value
        ? categories.value[activeCategoryId.value]
        : null;
});

const updateDropdown = async (categoryId: Categories.Category['id']) => {
    if (!(categoryId in categories.value)) {
        categories.value[categoryId] = await fetchCategory(categoryId);
    }

    activeCategoryId.value = categoryId;
    hovered.value = true;
}
</script>

<template>
    <div class="main-menu" @mouseleave="hovered = false">
        <HeaderNavbarLinks />

        <ul class="list-group">
            <li
                v-for="rootCategory of rootCategories"
                :key="rootCategory.id"
                class="main-menu-item" 
                :class="{ hover: hovered && activeCategoryId === rootCategory.id }"
                @mouseenter="updateDropdown(rootCategory.id)"
            >
                <template v-if="rootCategory.cdnIcon">
                    <!-- 
                        Do not use NuxtImg here! (yet.)
                        see https://github.com/nuxt/image/issues/869
                    -->
                    <img
                        class="nav-icon"
                        width="28px"
                        height="28px"
                        alt="🖼"
                        loading="lazy"
                        :src="rootCategory.cdnIcon"
                    />
                </template>
                <template v-else>
                    <IconImage class="nav-icon"/>
                </template>

                <NuxtLink :to="'/categories/' + rootCategory.id" class="value">
                    {{ rootCategory?.name }}
                </NuxtLink>

                <IconChevronRight class="nav-drop" />
            </li>
        </ul>

        <button @click="setMenuState('catalog', false)" aria-label="Закрити" class="close-button">
            <IconCross />
        </button>

        <CategoryMenuDropdown v-if="activeCategory" :category="activeCategory"/>
    </div>
</template>

<style lang="scss">
@use '~/assets/styles/mixins.scss';

.main-menu {
    $fluid-container-width: calc(100% - 1.5rem);

    position: absolute;
    display: flex;
    width: $fluid-container-width;
    
    @include mixins.respond-to(desktop, phone) {
        min-height: 20em;
    }

    >nav {
        >.navbar-nav {
            list-style: none;
            margin: unset;
        }

        @include mixins.respond-to(desktop) {
            display: none;
        }

        @include mixins.respond-to(tablet) {
            flex-grow: 0 !important;
            border-bottom: 1px solid #E7E9E9;

            >ul.navbar-nav {
                padding: 1em 0 .5em .75em;

                >li {
                    padding: .375em 0;
                }

                a {
                    font-weight: 500;
                    color: #212E42;

                    &:hover {
                        color: #10A5AA;
                    }
                }
            }
        }

        @include mixins.respond-to(phone) {
            flex-grow: 0 !important;
            border-bottom: 1px solid #E7E9E9;

            >ul.navbar-nav {
                padding: 1em .5em 1em 1.25em;

                >li {
                    padding: .625em 0;
                }

                a {
                    font-weight: 500;
                    color: #212E42;

                    &:hover {
                        color: #10A5AA;
                    }
                }
            }
        }
    }

    >ul.list-group {
        background-color: #FFFFFF;
        border-radius: 0 !important;
        padding: .625em 0 .625em .5em;
        box-shadow: 0 .25em .875em rgba(0, 0, 0, 0.03);
        display: flex;
        flex-direction: column;
        flex: 25% 0 0;
        z-index: 2;
        margin: unset;

        @include mixins.respond-to(desktop) {
            overflow: auto;
        }

        @include mixins.respond-to(desktop, tablet) {
            justify-content: space-evenly;
        }

        @include mixins.respond-to(phone) {
            gap: .25em;

            &:first-child {
                margin-top: 2.625em;
            }
        }

        >.main-menu-item {
            background-color: #FFFFFF;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: space-between;
            padding: 0.625em 1.75em 0.625em 1em;
            border-radius: .625em 0 0 .625em;
            transition: background-color .4s ease;
            cursor: pointer;

            &:hover,
            &.hover {
                background-color: rgba(75, 194, 198, 0.1);
            }

            @include mixins.respond-to(phone) {
                & {
                    margin-bottom: .625em;
                    padding: .25em .625em;
                    background-color: rgba(75, 194, 198, 0.1);
                }
            }

            >.nav-icon {
                position: relative;
                min-width: 1.75rem;
                min-height: 1.75rem;
                width: 1.75rem;
                height: 1.75rem;
                max-width: 1.75rem;
                max-height: 1.75rem;
                font-size: 1.75em;
                object-fit: contain;
                display: inline-flex;
                align-items: center;
                justify-content: center;
            }

            >.value {
                font-weight: 500;
                font-size: 1em;
                line-height: 120%;
                margin: auto .7em;
                color: #212E42;
                flex: 100%;

                @include mixins.respond-to(phone) {
                    & {
                        font-weight: 700;
                    }
                }
            }

            >.nav-drop {
                width: 1.5em;

                @include mixins.respond-to(phone) {
                    width: 1.75em;
                    height: 1.75em;
                }

                >path {
                    stroke: #ee3936;
                }
            }
        }
    }

    >.menu-dropdown {
        background: #F5F7FA;
        flex: 75% 0 0;
        display: flex;
        flex-direction: column;
        pointer-events: none;
        transition: opacity 0.2s ease-in;
        padding: 2em 2em .75em 3.75em;
        opacity: 0;
        z-index: 1;

        $marginValue: 1.25em;

        @include mixins.respond-to(tablet, phone) {
            display: none;
        }

        .empty-space {
            min-width: 300px;
        }

        .menu-wrapper {
            flex: 100%;
            display: flex;
            flex-flow: row wrap;
            overflow-x: hidden;
            overflow-y: scroll;
            align-items: flex-start;
            align-content: flex-start;
            min-height: calc($marginValue * 2 + 1em); // Value of neighbor margins + inline margin

            >* {
                flex: 25% 0 0;
                margin-bottom: 1em;
                padding-right: .75em;
            }
        }

        .menu-title,
        .menu-subtitle {
            font-weight: bold;
            color: #212E42;
        }

        .menu-title,
        .menu-link {
            line-height: 120%;
        }

        .menu-title {
            font-size: 2em;
            margin-bottom: $marginValue;
        }

        .menu-subtitle {
            font-size: 1em;
            line-height: 100%;
            transition: color .4s ease;

            &:hover {
                color: #4BC2C6;
            }
        }

        .menu-link,
        .menu-expand {
            display: block;
            font-weight: 500;
            font-size: .875em;

            &:hover {
                text-decoration: underline;
            }
        }

        .menu-link {
            padding: .5em 0;
            color: #484B4C;
        }

        .menu-expand {
            color: #4bc2c6;
        }

        .menu-footer {
            display: flex;
            align-items: center;
            justify-content: center;
            border: 2px solid #4BC2C6;
            margin-top: $marginValue;
            border-radius: .75em;
            font-size: 1em;
            line-height: 100%;
            color: #212E42;
            padding: .875em 1em;
            text-align: center;
            cursor: pointer;

            svg {
                width: 1.5em;
                height: 1.5em;
                margin-right: .25em;

                * {
                    transition: fill .4s;
                    fill: #4bc2c6 !important;
                }
            }

            .value {
                font-weight: 500;
            }

            &:hover {
                background: #4BC2C6;
                color: #FFFFFF;

                svg * {
                    fill: #FFFFFF !important;
                }
            }
        }
    }

    >.close-button {
        position: absolute;
        top: 1em;
        right: 1em;
        width: 1.75em;
        height: 1.75em;
        cursor: pointer;
        z-index: 2;

        @include mixins.respond-to(desktop, tablet) {
            display: none;
        }

        * {
            stroke: #484b4c;
        }
    }

    &:has(li.hover):hover {
        >.menu-dropdown {
            opacity: 1;
            pointer-events: auto;
        }
    }

    &.main-menu-slide-enter-active,
    &.main-menu-slide-leave-active {
        transition: transform .5s ease, opacity .5s ease;
        pointer-events: none !important;
        opacity: 0;
        z-index: -1;

        @include mixins.respond-to(desktop, phone) {
            transform: translateY(-100%);
        }

        @include mixins.respond-to(phone) {
            z-index: 5;
        }

        @include mixins.respond-to(tablet) {
            transform: translateX(-100%);
        }
    }

    &.main-menu-slide-enter,
    &.main-menu-slide-leave-to {
        position: absolute;
        pointer-events: auto;

        @include mixins.respond-to(desktop) {
            width: $fluid-container-width;
        }

        @include mixins.respond-to(phone) {
            width: 100%;
        }
    }

    &.main-menu-slide-enter-to,
    &.main-menu-slide-leave {
        pointer-events: auto;
        opacity: 1;

        @include mixins.respond-to(desktop, phone) {
            transform: translateY(0);
        }

        @include mixins.respond-to(tablet) {
            transform: translateX(0);
        }
    }

    @include mixins.respond-to(phone, tablet) {
        overflow-y: auto;
        overscroll-behavior: none;
    }

    @include mixins.respond-to(tablet) {
        position: absolute;
        flex-direction: column;
        background: #FFFFFF;
        width: clamp(30%, 80vh - 20vw, 70%);
        left: 0;
    }

    @include mixins.respond-to(phone) {
        position: fixed;
        flex-direction: column;
        background: #FFFFFF;
        width: 100vw;
        height: 100vh;
        left: 0;
        top: 0;
        z-index: 5;
    }
}
</style>
