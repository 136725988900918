<script setup lang="ts">
const props = defineProps({
    hasLabel: {
        type: Boolean,
        default: false
    },
    product: {
        type: Object,
        default: null
    },
    pharmacy: {
        type: Object,
        default: null
    },
});

watchEffect(() => {
    if ((props.product && props.pharmacy) || (!props.product && !props.pharmacy)) {
        throw new Error('You must provide either a product or a pharmacy for AddToFavourites component, but not both.');
    }
});

const favouritesType: Favourites.Type = props.product ? 'products' : 'pharmacies';
const item: Favourites.FavouriteObject = props.product as Products.Product || props.pharmacy;

const { favourites } = useFavourites();
const existingItem = computed(() => favourites.value[favouritesType][item.id]);

const toggleFavourites = () => {
    if (existingItem.value) {
        delete favourites.value[favouritesType][item.id];
    } else {
        favourites.value[favouritesType][item.id] = item;
    }
}
</script>

<template>
    <button
        class="add-to-favourites"
        :aria-label="`Додати ${product ? 'продукт' : 'аптеку'} в обране`"
        :class="{ added: existingItem }"
        @click.prevent="toggleFavourites"
    >
        <IconFilledHeart />
        <span v-if="props.hasLabel" class="value">{{ `${ existingItem ? 'Додано' : 'Додати'} в обране` }}</span>
    </button>
</template>

<style lang="scss">
 
.add-to-favourites {
    display: flex;
    gap: .75em;
    cursor: pointer;

    >svg {
        fill: #e7e9e9;
        transition: fill .25s ease;
    }

    &:hover,
    &.added {
        >svg {
            fill: #EE3936;
        }
    }
}
</style>
