<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
        style="enable-background:new 0 0 32 32"
        xml:space="preserve"
    >
        <path
            d="M27 13.1c-.4 0-.8-.3-1-.7-.4-1.5-1.2-2.9-2.3-4s-2.5-1.9-4-2.3c-.5-.1-.8-.7-.7-1.2.1-.5.7-.8 1.2-.7 1.9.5 3.6 1.5 4.9 2.8 1.4 1.4 2.3 3.1 2.8 4.9.1.5-.2 1.1-.7 1.2H27zm-3.9 1c-.4 0-.8-.3-1-.7-.2-.8-.7-1.6-1.3-2.2-.6-.6-1.4-1.1-2.2-1.3-.5-.1-.8-.7-.7-1.2s.7-.8 1.2-.7c1.2.3 2.3.9 3.1 1.8.9.9 1.5 2 1.8 3.1.1.5-.2 1.1-.7 1.2h-.2zM22 28c-4.8 0-9.3-1.9-12.7-5.3C5.9 19.3 4 14.8 4 10c0-1.7.6-3.3 1.7-4.6 1.1-1.3 2.7-2.1 4.4-2.3.4-.1.9 0 1.2.3.4.2.7.6.8.9l2.5 5.9c.1.3.2.6.2 1 0 .3-.1.6-.3.9l-2.1 3.2c.9 1.9 2.5 3.5 4.4 4.4l3.1-2.1c.3-.2.6-.3.9-.3.3 0 .7 0 1 .2l5.9 2.5c.4.2.7.5.9.8.2.4.3.8.3 1.2-.2 1.7-1 3.2-2.3 4.4-1.3 1-2.9 1.6-4.6 1.6zM10.4 5c-1.2.2-2.3.8-3.2 1.7C6.4 7.6 6 8.8 6 10c0 4.3 1.7 8.3 4.7 11.3S17.7 26 22 26c1.2 0 2.4-.4 3.3-1.2.9-.8 1.5-1.9 1.7-3.1l-5.9-2.5-3.1 2c-.3.2-.6.3-1 .3-.3 0-.7 0-1-.2-2.3-1.1-4.2-3-5.3-5.3-.1-.3-.2-.7-.2-1 0-.3.1-.7.3-1l2.1-3.2L10.4 5z"
            style="fill:#4bc2c6"
        />
    </svg>
</template>