
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91article_93fM0C9uuJIEMeta } from "/usr/home/jenkins/app/services/etabletka-app/pages/blog/[category]/[article].vue?macro=true";
import { default as indexEqY6c4Pqv3Meta } from "/usr/home/jenkins/app/services/etabletka-app/pages/blog/[category]/index.vue?macro=true";
import { default as indexWSYa7a1HAKMeta } from "/usr/home/jenkins/app/services/etabletka-app/pages/blog/index.vue?macro=true";
import { default as cartOWVfhbzByuMeta } from "/usr/home/jenkins/app/services/etabletka-app/pages/cart.vue?macro=true";
import { default as _91_46_46_46route_938ZDEe41hF7Meta } from "/usr/home/jenkins/app/services/etabletka-app/pages/catalog/[...route].vue?macro=true";
import { default as _91id_937SjcCKbrtTMeta } from "/usr/home/jenkins/app/services/etabletka-app/pages/categories/[id].vue?macro=true";
import { default as indexUJ3ttuxmsRMeta } from "/usr/home/jenkins/app/services/etabletka-app/pages/categories/index.vue?macro=true";
import { default as _91id_93VPb7IYYZxNMeta } from "/usr/home/jenkins/app/services/etabletka-app/pages/checkout/[id].vue?macro=true";
import { default as indexbhfkyQXs71Meta } from "/usr/home/jenkins/app/services/etabletka-app/pages/index.vue?macro=true";
import { default as orderQ5mg2gvGEIMeta } from "/usr/home/jenkins/app/services/etabletka-app/pages/order.vue?macro=true";
import { default as _91slug_939V2bQDHycyMeta } from "/usr/home/jenkins/app/services/etabletka-app/pages/page/[slug].vue?macro=true";
import { default as indexMRuRKwdrOQMeta } from "/usr/home/jenkins/app/services/etabletka-app/pages/product/[id]/index.vue?macro=true";
import { default as reviewsI0dpGoF3K7Meta } from "/usr/home/jenkins/app/services/etabletka-app/pages/product/[id]/reviews.vue?macro=true";
import { default as _91_46_46_46route_93U4lVla7TnHMeta } from "/usr/home/jenkins/app/services/etabletka-app/pages/search/[...route].vue?macro=true";
import { default as storesD9MndMr90rMeta } from "/usr/home/jenkins/app/services/etabletka-app/pages/stores.vue?macro=true";
export default [
  {
    name: "blog-category-article",
    path: "/blog/:category()/:article()",
    component: () => import("/usr/home/jenkins/app/services/etabletka-app/pages/blog/[category]/[article].vue")
  },
  {
    name: "blog-category",
    path: "/blog/:category()",
    component: () => import("/usr/home/jenkins/app/services/etabletka-app/pages/blog/[category]/index.vue")
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/usr/home/jenkins/app/services/etabletka-app/pages/blog/index.vue")
  },
  {
    name: "cart",
    path: "/cart",
    component: () => import("/usr/home/jenkins/app/services/etabletka-app/pages/cart.vue")
  },
  {
    name: "catalog-route",
    path: "/catalog/:route(.*)*",
    component: () => import("/usr/home/jenkins/app/services/etabletka-app/pages/catalog/[...route].vue")
  },
  {
    name: "categories-id",
    path: "/categories/:id()",
    component: () => import("/usr/home/jenkins/app/services/etabletka-app/pages/categories/[id].vue")
  },
  {
    name: "categories",
    path: "/categories",
    component: () => import("/usr/home/jenkins/app/services/etabletka-app/pages/categories/index.vue")
  },
  {
    name: "checkout-id",
    path: "/checkout/:id()",
    component: () => import("/usr/home/jenkins/app/services/etabletka-app/pages/checkout/[id].vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/usr/home/jenkins/app/services/etabletka-app/pages/index.vue")
  },
  {
    name: "order",
    path: "/order",
    component: () => import("/usr/home/jenkins/app/services/etabletka-app/pages/order.vue")
  },
  {
    name: "page-slug",
    path: "/page/:slug()",
    component: () => import("/usr/home/jenkins/app/services/etabletka-app/pages/page/[slug].vue")
  },
  {
    name: "product-id",
    path: "/product/:id()",
    component: () => import("/usr/home/jenkins/app/services/etabletka-app/pages/product/[id]/index.vue")
  },
  {
    name: "product-id-reviews",
    path: "/product/:id()/reviews",
    component: () => import("/usr/home/jenkins/app/services/etabletka-app/pages/product/[id]/reviews.vue")
  },
  {
    name: "search-route",
    path: "/search/:route(.*)*",
    component: () => import("/usr/home/jenkins/app/services/etabletka-app/pages/search/[...route].vue")
  },
  {
    name: "stores",
    path: "/stores",
    component: () => import("/usr/home/jenkins/app/services/etabletka-app/pages/stores.vue")
  }
]