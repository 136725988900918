<script setup lang="ts">

const props = defineProps({
  product: {
    type: Object as PropType<Products.Product>,
    required: true
  },
  scaleFactor: {
    type: Number,
    default: 1
  },
});

const intOldPrice = computed(() => Math.floor(props.product.price * props.scaleFactor));
const decOldPrice = computed(() => `.${(props.product.price * props.scaleFactor)?.toFixed(2).slice(-2)}`);

const intDiscountPrice = computed(() => Math.floor((props.product?.discounts?.[0]?.value || 0) * props.scaleFactor));
const decDiscountPrice = computed(() => {
  if (props.product?.discounts?.[0]?.type === 0) return '';
  return `.${((props.product?.discounts?.[0]?.value || 0) * props.scaleFactor)?.toFixed(2).slice(-2)}`
});
const discountSymbol = computed(() => {
  switch (props.product.discounts?.[0]?.type) {
    case 0:
      return '%';
    case 1:
      return ' грн';
    default:
      return '';
  }
});

const intTotalPrice = computed(() => {
  if (props.product?.discounts?.[0])
    return Math.floor(props.product?.discounts[0]?.discountPrice * props.scaleFactor)
  return Math.floor(props.product?.price * props.scaleFactor);
});
const decTotalPrice = computed(() => {
  if (props.product?.discounts?.[0]) {
    const decimals = +(props.product?.discounts[0]?.discountPrice * props.scaleFactor).toFixed(2).slice(-2)
    if (1 * decimals > 0) {
      return `.${decimals}`
    }
  } else {
    const decimals = +(props.product?.price * props.scaleFactor).toFixed(2).slice(-2)
    if (1 * decimals > 0) {
      return `.${decimals}`
    }
  }
  return ''
}); 
</script>

<template>
  <div class="price-block">
    <div v-if="props.product?.discounts?.[0]" class="old-price">
      <span class="integer">{{ intOldPrice }}</span>
      <span class="decimal">{{ decOldPrice }}</span>
    </div>
    <div v-if="props.product?.discounts?.[0]" class="discount">
      <span class="integer">-{{ intDiscountPrice }}</span>
      <span class="decimal">{{ decDiscountPrice }}</span>
      <span class="currency">{{ discountSymbol }}</span>
    </div>
    <div class="price">
      <span class="integer">{{ intTotalPrice }}</span>
      <span class="decimal">{{ decTotalPrice }}</span>
      <span class="currency"> грн</span>
    </div>
  </div>
</template>

<style lang="scss">
.price-block {
  display: grid;
  line-height: 100%;
  column-gap: .5em;
  row-gap: .25em;

  .price {
    grid-column: 1 / 3;
    grid-row: 2;

    .integer {
      font-size: 1em;
    }

    .decimal {
      font-size: .8em;
    }

    .currency {
      font-size: 1em;
    }
  }

  .old-price {
    grid-column: 1 / 2;
    grid-row: 1;
    font-weight: 500;
    color: #484B4C;
    position: relative;
    margin: auto 0;
    text-align: center;

    .integer {
      font-size: 1em;
    }

    .decimal {
      font-size: 0.8em;
    }

    > span {
      opacity: 0.5;
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: calc(50% - 1px);
      left: -2px;
      right: -2px;
      border-top: 2px solid #EE3936;
      height: 2px;
    }
  }

  .discount {
    grid-column: 2 / 2;
    grid-row: 1;
    background: #4BC2C6;
    border-radius: .25em;
    padding: .25em .5em;
    font-weight: bold;
    color: #FFFFFF;
    font-size: .75em;
  }
}
</style>
