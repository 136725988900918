<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 32 32"
        version="1.1"
    >
        <path
            d="M 16 20.890625 C 11.109375 20.890625 7.109375 16.890625 7.109375 12 C 7.109375 7.109375 11.109375 3.109375 16 3.109375 C 20.890625 3.109375 24.890625 7.109375 24.890625 12 C 24.890625 16.890625 20.890625 20.890625 16 20.890625 Z M 16 4.890625 C 12.089844 4.890625 8.890625 8.089844 8.890625 12 C 8.890625 15.910156 12.089844 19.109375 16 19.109375 C 19.910156 19.109375 23.109375 15.910156 23.109375 12 C 23.109375 8.089844 19.910156 4.890625 16 4.890625 Z M 28.089844 27.910156 C 27.820312 27.910156 27.464844 27.734375 27.289062 27.464844 C 26.132812 25.511719 24.445312 23.820312 22.488281 22.667969 C 18.488281 20.355469 13.332031 20.355469 9.332031 22.667969 C 7.378906 23.820312 5.6875 25.511719 4.535156 27.464844 C 4.265625 27.910156 3.734375 28 3.289062 27.820312 C 2.84375 27.554688 2.753906 27.023438 2.933594 26.578125 C 4.265625 24.355469 6.132812 22.398438 8.355469 21.15625 C 12.890625 18.578125 18.753906 18.578125 23.289062 21.15625 C 25.511719 22.488281 27.464844 24.355469 28.710938 26.578125 C 28.976562 27.023438 28.800781 27.554688 28.355469 27.820312 C 28.445312 27.820312 28.265625 27.910156 28.089844 27.910156 Z M 28.089844 27.910156 "
        />
    </svg>
</template>
