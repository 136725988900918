<script setup lang="ts">
import 'keen-slider/keen-slider.min.css';
import KeenSlider from 'keen-slider';
import type { KeenSliderInstance } from 'keen-slider/vue.es';

defineProps({
    banners: {
        type: Object as PropType<Banners.Banner[]>,
        required: true,
    },
});

const container = ref<HTMLElement | null>(null);
const slider = ref<KeenSliderInstance | null>(null);

onMounted(() => {
    if (!container.value) return;

    slider.value = new KeenSlider(
        container.value,
        {
            loop: true,
            mode: 'free-snap',
        },
        [
            (slider) => {
                let timeout: NodeJS.Timeout;
                let mouseOver = false;

                function nextTimeout() {
                    clearTimeout(timeout);

                    if (mouseOver) return;

                    timeout = setTimeout(() => {
                        slider.next();
                    }, 5000);
                }

                slider.on('created', () => {
                    slider.container.addEventListener('mouseover', () => {
                        mouseOver = true;
                        clearTimeout(timeout);
                    });

                    slider.container.addEventListener('mouseout', () => {
                        mouseOver = false;
                        nextTimeout();
                    });

                    nextTimeout();
                });

                slider.on('dragStarted', () => clearTimeout(timeout));
                slider.on('animationEnded', nextTimeout);
                slider.on('updated', nextTimeout);
            },
        ]
    );
});
</script>

<template>
    <div class="home-carousel">
        <Transition name="fade">
            <button
                v-if="slider"
                class="arrow left"
                aria-label="Попередній банер"
                @click.prevent="slider.prev()"
            >
                <IconArrowLeft class="icon"/>
            </button>
        </Transition>

        <ul
            ref="container"
            class="keen-slider carousel-container"
        >
            <li
                v-for="(banner, index) in banners"
                :key="`banner-${banner.id}`"
                class="keen-slider__slide item"
            >
                <template v-if="banner.url">
                    <NuxtLink
                        :to="banner.url"
                    >
                        <NuxtImg
                            class="image"
                            format="webp"
                            sizes="xs:430 sm:680 md:1180 lg:950"
                            :preload="index === 0"
                            :loading="index === 0 ? 'eager' : 'lazy'"
                            :src="banner.cdnData?.url"
                            :alt="`Банер «${banner.name}»`"
                        />
                    </NuxtLink>
                </template>

                <template v-else>
                    <NuxtImg
                        class="image"
                        format="webp"
                        sizes="xs:430 sm:680 md:1180 lg:950"
                        :preload="index === 0"
                        :loading="index === 0 ? 'eager' : 'lazy'"
                        :src="banner.cdnData?.url"
                        :alt="`Банер «${banner.name}»`"
                    />
                </template>
            </li>
        </ul>

        <Transition name="fade">
            <button
                v-if="slider"
                class="arrow right"
                aria-label="Наступний банер"
                @click.prevent="slider.next()"
            >
                <IconArrowRight class="icon"/>
            </button>
        </Transition>
    </div>
</template>

<style lang="scss">
@use '~/assets/styles/mixins.scss';

.home-carousel {
    position: relative;
    overflow: hidden;
    width: 100%;
    aspect-ratio: 1.74 / 1;

    @include mixins.respond-to(tablet, phone) {
        margin-top: .75em;
    }

    @keyframes wiggle-left {
        0% {
            transform: translateX(0);
        }
        50% {
            transform: translateX(-10%);
        }
        100% {
            transform: translateX(0);
        }
    }

    @keyframes wiggle-right {
        0% {
            transform: translateX(0);
        }
        50% {
            transform: translateX(10%);
        }
        100% {
            transform: translateX(0);
        }
    }

    >.arrow {
        position: absolute;
        width: 2.5em;
        height: 2.5em;
        background-color: rgba(255,255,255,0.7);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;

        @include mixins.respond-to(tablet, phone) {
            display: none;
        }

        &:hover {
            background-color: #FFFFFF;

            &.left>.icon {
                animation: wiggle-left .5s ease infinite;
            }

            &.right>.icon {
                animation: wiggle-right .5s ease infinite;
            }
        }

        >.icon {
            width: 1.125em;
            height: 1.125em;
        }

        &.left {
            bottom: 2%;
            left: 5%;
        }

        &.right {
            bottom: 2%;
            right: 5%;
        }
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }

    >.carousel-container {
        width: 100%;
        height: 100%;
        list-style: none;
        padding: unset;
        margin: unset;

        >.item {
            flex: 1 0 0;
            max-width: 100%;
            min-width: 100%;
            aspect-ratio: 1.74 / 1;

            .image {
                background-color: #FFFFFF;
                width: 100%;
                aspect-ratio: 1.74 / 1;
            }
        }
    }
}
</style>
