<script setup lang="ts">
const formOptions = {
    id: 'queryInput',
    namespace: 'productSearch',
};

const form = useForm();
const {
    searchResults,
    searchByString,
} = useSearch();

const query = computed(() => form.getValue(formOptions));

const redirectAndSearch = () => {
    if (!query.value) return;

    (document.activeElement as HTMLElement)?.blur();

    useRouter().push({
        path: `/search/products`,
        query: {
            query: query.value
        }
    });
}

watchDebounced(query, async (newQuery) => {
    if (!newQuery || newQuery.length <= 2) return;

    const results = await searchByString(newQuery);

    searchResults.value = results;
}, { debounce: 300 });
</script>

<template>
    <div class="search-results">
        <form
            class="search-form"
            @submit.prevent="redirectAndSearch"
        >
            <CommonFormInput
                type="search"
                placeholder="Назва товару"
                class="input-search"
                :id="formOptions.id"
                :namespace="formOptions.namespace"
                :minlength="3"
                :validators="false"
            />

            <Transition name="fade">
                <button
                    v-if="query"
                    type="button"
                    class="cancel-button"
                    aria-label="Очистити поле пошуку"
                    @click.prevent="form.resetValue(formOptions)"
                >
                    <IconCross class="icon"/>
                </button>
            </Transition>

            <button
                class="search-submit"
                type="submit"
                aria-label="Пошук товарів"
            >
                <IconSearch class="icon" />
            </button>
        </form>

        <div class="search-dropdown">
            <ul
                v-if="searchResults.length"
                class="search-items-list"
            >
                <HeaderSearchItem
                    v-for="item in searchResults"
                    :item="item"
                    :key="`search-item-${item.id}`"
                    class="search-item"
                />
            </ul>

            <span
                v-else
                class="search-status"
            >
                {{ !query ? "Введіть назву товару" : "На жаль за вашим запитом немає товарів" }}
            </span>

            <NuxtLink
                v-if="searchResults.length"
                :to="{
                    path: `/search/products`,
                    query: {
                        query: query
                    }
                }"
                class="more-results"
            >
                Всі результати пошуку
            </NuxtLink>
        </div>
    </div>
</template>

<style lang="scss">
@use '~/assets/styles/mixins.scss';

.search-results {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex-basis: 100%;
    height: 100%;
    margin-right: 2.25em;

    >.search-form {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex: 100%;
        z-index: 5;
        background: #FFFFFF;
        border-radius: 1.5em;
        border-bottom: 1px solid transparent;
        transition: border-radius .35s, border-bottom-color .35s;

        @include mixins.respond-to(phone) {
            z-index: 3;
        }

        .input-search {
            background: transparent;
            padding: 0;
            line-height: 120%;
            font-weight: 500;
            color: #484B4C;
            border: 0;
            margin: 0 .5em 0 clamp(1em, 3vw, 2em);
            flex-basis: 100%;

            >input {
                margin: unset;
            }
        }

        >.cancel-button {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin: .75em 0;
            border: 1px solid transparent;
            border-left-color: #E7E9E9;
            padding: 0 .25em 0 .625em;
            z-index: 0;

            @include mixins.respond-to(phone) {
                margin: .5em 0;
            }

            >.icon {
                width: 1.125em;
                height: 1.125em;

                path {
                    stroke-width: 3;
                    stroke: #484B4C;
                }
            }

            &.fade-enter-active, &.fade-leave-active {
                >.icon {
                    transition: transform .35s ease;
                }
                transition: opacity .35s ease, padding .35s ease;
            }

            &.fade-enter-from, &.fade-leave-to {
                >.icon {
                    transform: translateX(1.625em) rotate(90deg);
                }
                padding-left: 2em;
                opacity: 0;
            }
        }

        >.search-submit {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background: #4BC2C6;
            border-radius: 10em;
            border: 0;
            margin: .25em;
            height: calc(100% - .5em);
            aspect-ratio: 1/1;
            z-index: 1;

            >.icon {
                width: 1.25em;
                height: 1.25em;

                * {
                    fill: #FFFFFF !important;
                }
            }
        }
    }

    >.search-dropdown {
        position: absolute;
        top: 30%;
        left: 0;
        width: 100%;
        background-color: white;
        z-index: 4;
        overflow: hidden;
        max-height: 0;
        border-radius: 1.5em;
        color: #484B4C;
        transition:
            max-height .35s cubic-bezier(0.25, 0.46, 0.45, 0.94),
            top .35s cubic-bezier(0.25, 0.46, 0.45, 0.94),
            border-radius 0.35s ease-out 0.1s,
            padding-top .35s cubic-bezier(0.25, 0.46, 0.45, 0.94),
            padding-bottom .35s cubic-bezier(0.25, 0.46, 0.45, 0.94);

        @include mixins.respond-to(phone) {
            z-index: 2;
        }

        &:not(:has(.search-items-list)) {
            padding: 0 clamp(1em, 3vw, 2em);
        }

        >.search-items-list {
            display: flex;
            flex-flow: column nowrap;
            gap: 1.25em;
            max-height: 50vh;
            overflow-x: hidden;
            overflow-y: auto;
            margin: unset;
            padding: 1em clamp(1em, 3vw, 2em);

            @include mixins.respond-to(tablet) {
                gap: 1.5em;
            }

            > li {
                font-weight: normal;
            }
        }

        >.more-results {
            position: relative;
            display: block;
            padding: 1em;
            font-weight: 500;
            font-size: 1em;
            line-height: 115%;
            color: #333333;
            border: 2px solid #4BC2C6;
            border-radius: .75em;
            text-align: center;
            margin: 1em 2em;

            &::before {
                content: "";
                position: absolute;
                top: -1em;
                left: -2em;
                width: calc(100% + 4em);
                height: 1px;
                background-color: transparent;
                opacity: 0;
                transition: opacity .35s ease-out .35s, background-color .25s ease-out 0s;
            }

            &:hover {
                color: #FFFFFF;
                background: #4BC2C6;
            }
        }
    }

    @mixin expanded-dropdown {
        >.search-form {
            border-radius: 1.5em 1.5em 0 0;
            border-bottom-color: #E7E9E9;
        }

        >.search-dropdown {
            top: 100%;
            max-height: 100vh;
            border-radius: 0 0 1.5em 1.5em;

            &:not(:has(.search-items-list)) {
                padding-top: 1em;
                padding-bottom: 1em;
            }

            >.search-items-list, >.more-results {
                &::before {
                    opacity: 1;
                    background-color: #E7E9E9;
                }
            }
        }
    }

    &:has(.search-form:focus-within) {
        @include expanded-dropdown;
    }

    @include mixins.respond-to(desktop) {
        &:has(.search-dropdown:hover) {
            @include expanded-dropdown;
        }
    }
}
</style>
