<script setup lang="ts">
const {
    defaultCity,
    setSettings,
    fetchSettings,
    fetchDefaultCity,
} = useApp();

const {
    rootCategories,
    fetchRootCategories,
} = useCategory();

const {
    footerItems,
    fetchFooterItems,
} = useFooter();

const [
    defaultCityPromise,
    settingsPromise,
    rootCategoriesPromise,
    footerItemsPromise,
] = await Promise.allSettled([
    useAsyncData('fetchDefaultCity', fetchDefaultCity),
    useAsyncData('fetchSettings', fetchSettings),
    useAsyncData('fetchRootCategories', fetchRootCategories),
    useAsyncData('fetchFooterData', fetchFooterItems),
]);

if (defaultCityPromise.status === 'fulfilled' && defaultCityPromise.value.data.value) {
    defaultCity.value = defaultCityPromise.value.data.value;
}

if (settingsPromise.status === 'fulfilled' && settingsPromise.value.data.value) {
    setSettings(settingsPromise.value.data.value);
}

if (rootCategoriesPromise.status === 'fulfilled' && rootCategoriesPromise.value.data.value) {
    rootCategories.value = rootCategoriesPromise.value.data.value;
}

if (footerItemsPromise.status === 'fulfilled' && footerItemsPromise.value.data.value) {
    footerItems.value = footerItemsPromise.value.data.value;
}
</script>

<template>
    <Header />

    <slot></slot>

    <Footer :items="[...footerItems, rootCategories]" />
</template>
