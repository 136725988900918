<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 47 32"
    >
        <g fill="#fff">
            <path d="M29.74 24.82H13.23v-2.2c0-1.84 1.5-3.34 3.34-3.34h16.51v2.2c0 1.84-1.5 3.34-3.34 3.34Zm0 0" />
            <path d="M20.39 32V15.45c0-1.84 1.5-3.35 3.34-3.35h2.2v16.55c0 1.85-1.5 3.35-3.35 3.35Zm0 0" />
            <path
                d="M12.2 30.31a32.22 32.22 0 0 1-5.54-3.9 23.03 23.03 0 0 1-4.41-5.3l-.43-.77-.37-.79A17 17 0 0 1 .07 14.4a13.9 13.9 0 0 1 1.14-7.13l.42-.83.5-.8.02-.05.06-.08.06-.09.13-.17.24-.34.65-.74a13 13 0 0 1 2.93-2.3A14.09 14.09 0 0 1 13.34 0c.31 0 .62.03.94.04l.88.1c.32.06.65.1.96.17.3.07.6.13.9.22 1.19.33 2.34.8 3.41 1.38a17.8 17.8 0 0 1 5.54 4.72l-2.16-.16a15.83 15.83 0 0 1 5.78-3.45c2.14-.7 4.45-.98 6.72-.62.28.03.56.1.84.16.28.05.56.13.83.21l.41.12.06.02h.02l.08.03.09.04.18.06.36.14.18.07.2.1c.27.13.55.26.81.4l.74.43a12.63 12.63 0 0 1 5.23 6.51c.38 1.07.6 2.2.66 3.33v.86l-.01.1v.09l-.01.04-.02.2-.04.38a13.2 13.2 0 0 1-1.63 4.7c-.54.95-1.17 1.82-1.89 2.63a22.38 22.38 0 0 1-4.87 4 55.97 55.97 0 0 1-5.46 2.92c1.7-1.17 3.42-2.3 5.02-3.57a22.7 22.7 0 0 0 4.24-4.23c.58-.8 1.1-1.63 1.53-2.5a11.7 11.7 0 0 0 1.1-4.08l.01-.38v-.2l.01-.05v-.02.02-.01l-.03-.78a10.13 10.13 0 0 0-2.07-5.05 11.84 11.84 0 0 0-3.1-2.7l-.6-.32-.59-.26-.14-.07-.18-.06-.37-.13-.18-.06-.1-.04c-.05-.01.06.02.04.02h-.02l-.04-.02-.33-.08a4.8 4.8 0 0 0-.66-.15c-.23-.04-.45-.1-.68-.11-1.8-.24-3.68.02-5.42.62a12.8 12.8 0 0 0-4.69 2.85l-1.16 1.1-1-1.26a15.38 15.38 0 0 0-4.62-3.96c-.9-.49-1.83-.89-2.8-1.17-.24-.08-.48-.13-.73-.2l-.7-.13-.78-.1-.74-.05c-2-.06-4.02.4-5.8 1.35a10.1 10.1 0 0 0-2.41 1.75l-.48.5-.5.62-.42.61-.38.65c-.47.89-.8 1.86-1.01 2.87a14.82 14.82 0 0 0 .63 7.75l.3.77.33.73c.46.98 1 1.92 1.63 2.82.62.9 1.33 1.75 2.07 2.57a39.12 39.12 0 0 0 4.95 4.51Zm0 0"
            />
        </g>
    </svg>
</template>
