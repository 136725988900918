<script setup lang='ts'>
const modalVisible = defineModel();
</script>

<template>
    <Modal v-model="modalVisible">
        <template #body>
          Дякуємо за звернення. Ми зателефонуємо вам найближчим часом.
        </template>
    </Modal>
</template>
