<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 32 32"
        version="1.1"
    >
        <path
            style=" stroke:none;fill-rule:nonzero;fill:rgb(0%,0%,0%);fill-opacity:1;"
            d="M 16 27.644531 C 15.464844 27.644531 15.023438 27.464844 14.667969 27.109375 L 4.265625 16.710938 C 3.554688 16 3.023438 15.199219 2.667969 14.222656 C 2.3125 13.246094 2.132812 12.265625 2.132812 11.289062 C 2.132812 10.3125 2.398438 9.332031 2.84375 8.355469 C 3.289062 7.464844 3.820312 6.667969 4.621094 5.957031 C 7.464844 3.378906 12.179688 3.644531 15.023438 6.488281 L 16 7.554688 L 17.246094 6.3125 C 17.957031 5.601562 18.753906 5.066406 19.734375 4.710938 C 20.710938 4.355469 21.6875 4.179688 22.667969 4.179688 C 23.644531 4.179688 24.621094 4.445312 25.601562 4.890625 C 26.488281 5.332031 27.289062 5.867188 28 6.667969 C 30.578125 9.511719 30.3125 14.222656 27.464844 17.066406 L 17.332031 27.109375 C 16.976562 27.464844 16.535156 27.644531 16 27.644531 Z M 9.332031 5.957031 C 8 5.957031 6.753906 6.398438 5.6875 7.289062 C 5.15625 7.820312 4.621094 8.445312 4.355469 9.066406 C 4.089844 9.6875 3.820312 10.488281 3.820312 11.289062 C 3.820312 12.089844 3.910156 12.800781 4.179688 13.511719 C 4.445312 14.222656 4.890625 14.84375 5.421875 15.378906 L 15.820312 25.777344 L 16 25.777344 L 26.132812 15.644531 C 28.355469 13.421875 28.535156 9.867188 26.578125 7.644531 C 26.042969 7.109375 25.421875 6.578125 24.800781 6.3125 C 24.089844 5.957031 23.378906 5.777344 22.578125 5.777344 C 21.777344 5.777344 21.066406 5.867188 20.355469 6.132812 C 19.644531 6.398438 19.023438 6.84375 18.488281 7.378906 L 16.621094 9.246094 C 16.265625 9.601562 15.734375 9.601562 15.378906 9.246094 L 13.777344 7.644531 C 12.535156 6.578125 10.933594 5.957031 9.332031 5.957031 Z M 9.332031 5.957031 "
        />
    </svg>
</template>
