<script setup lang="ts">
const { loggedIn } = useUser();

const props = defineProps({
    product: {
        type: Object as PropType<Products.Product>,
        required: true,
    },
    availability: {
        type: Boolean as PropType<boolean>,
        default: false,
    },
    price: {
        type: Boolean as PropType<boolean>,
        default: true,
    },
    sizes: {
        type: String as PropType<`${'xs'}:${number} ${'sm'}:${number} ${'md'}:${number} ${'lg'}:${number}`>,
        required: false,
    },
});

const productRemnantCount = computed(() => {
    return props.product.productRemnants?.reduce((count, remnant) => {
        return count += Math.floor(remnant.quantity);
    }, 0);
})

const getTakeTodayMessage = () => {
    const pharmacyCount = props.product.productRemnants.length || 0;
    return `Забрати сьогодні в ${pharmacyCount} ${pharmacyCount === 1 ? 'аптеці' : 'аптеках'}`;
}

const productBadges = computed<{
    id: string,
    title: string,
}[]>(() => {
    let result = [];

    for (const discount of props.product?.discounts || []) {
        switch (discount.type) {
            case 1:
                result.push({
                    id: `d-${discount.id}`,
                    title: `-${discount.value} грн`,
                });
                break;
            case 2:
                result.push({
                    id: `d-${discount.id}`,
                    title: `-${discount.value}%`,
                });
                break;
        }
    }

    return result;
});
</script>

<template>
    <div class="product-card">
        <div class="badges">
            <div
                v-if="productBadges.length"
                v-for="badge of productBadges"
                :key="`badge-${product.id}-${badge.id}`"
                class="badge"
            >
                {{ badge.title }}
            </div>
        </div>

        <CommonAddToFavourites
            v-if="loggedIn"
            :product="props.product"
        />

        <slot name="buttons"></slot>

        <NuxtLink
            :to="'/product/' + props.product?.id"
            class="product-image"
            :aria-label="`Перейти до продукту «${props.product?.name}»`"
        >
            <template v-if="product.cdnData?.thumbnail_url || product.cdnData?.url">
                <NuxtImg
                    format="webp"
                    loading="lazy"
                    :sizes="sizes || `xs:250 sm:250 md:320 lg:200`"
                    :src="product.cdnData.thumbnail_url || product.cdnData.url"
                    :alt="`Фото товару «${product.name}»`"
                />
            </template>
            <template v-else>
                <IconProductFallback />
            </template>
        </NuxtLink>

        <div class="review-container">
            <CommonRatingStars :rating="props.product?.rating" />

            <NuxtLink
                v-if="props.product.reviewsCount"
                :to="props.product.cdnData?.url"
                class="comments"
            >
                <IconMessage />
                <span class="value">
                    {{ props.product.reviewsCount }}
                </span>
            </NuxtLink>
        </div>

        <NuxtLink
            :to="'/product/' + props.product?.id"
            class="product-title"
        >
            {{ props.product?.shortName || props.product?.name }}
        </NuxtLink>

        <NuxtLink
            v-if="availability && product.productRemnants?.length"
            :to="'/product/' + props.product?.id"
            class="take-today"
        >
            <IconCheckBox class="icon" />
            <span class="value">{{ getTakeTodayMessage() }}</span>
        </NuxtLink>

        <div
            v-if="props.product?.inStoke"
            class="product-price"
        >
            <template v-if="props.product?.price">
                <CommonPriceBlock
                    v-if="price"
                    class="price"
                    :product="props.product"
                />
                <CommonAddToCart
                    class="add-to-cart-button"
                    :product="props.product"
                />
            </template>
            <template v-else>
                Ціну уточнюйте
            </template>
        </div>
        <div
            v-else
            class="empty-stock"
        >
            Немає в наявності
        </div>

        <div
            v-if="availability && product.productRemnants?.length"
            class="remnants"
        >
            Залишилось {{ productRemnantCount }} шт.
        </div>
    </div>
</template>

<style lang="scss">
@use '~/assets/styles/mixins.scss';

.product-card {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    background: #FFFFFF;
    padding: 2em 1.25em 1.5em 1.25em;
    box-shadow: 0px .25em .375em rgba(0, 0, 0, 0.05);
    border-radius: .75em;
    max-width: 100%;
    min-height: 0;
    min-width: 0;

    .add-to-favourites {
        position: absolute;
        width: 1.125em;
        height: 1.125em;
        top: 1em;
        right: 1em;
        z-index: 1;
    }

    .badges {
        position: absolute;
        display: flex;
        flex-flow: row wrap;
        width: calc(100% - 1.5em);
        top: .75em;
        left: .75em;
        z-index: 1;
        gap: .5em;

        >.badge {
            flex: calc(50% - .5em) 0 0;
            background-color: #4BC2C6;
            border-radius: 12px 0;
            padding: .625em 1.175em;
            font-weight: 600;
            font-size: 0.875em;
            line-height: 100%;
            color: #FFFFFF;
            text-align: center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .product-image {
        display: block;
        margin-bottom: .75em;
        overflow: hidden;
        flex: 50% 0 0;
        aspect-ratio: 1 / 1;
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: 100%;

        >img,
        svg {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .review-container {
        display: flex;
        align-items: center;
        margin-bottom: .75em;
        line-height: 100%;

        >:first-child {
            margin-right: .375em;
        }

        >.comments {
            display: flex;
            align-items: center;

            >svg {
                width: 1em;
                height: 1em;
            }

            >.value {
                font-weight: 500;
                font-size: .875em;
                margin-left: .375em;
                color: #000000;
            }
        }
    }

    .product-title {
        display: block;
        margin-top: auto;
        margin-bottom: .75em;
        font-weight: 500;
        line-height: 130%;
        color: #212E42;
        overflow: hidden;
        max-height: 2.6em;
        transition: color .4s ease;

        &:hover {
            color: #10A5AA;
        }
    }

    .product-price,
    .empty-stock {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: auto;
    }

    .product-price {
        flex-flow: row wrap;
        gap: .75em;

        >.price {
            font-size: 1.2em;
            font-weight: 700;
        }

        @include mixins.respond-to(tablet, phone) {
            justify-content: space-between;
        }
    }

    >.take-today {
        display: flex;
        align-items: center;
        gap: .75em;
        padding-bottom: .75em;
        margin-bottom: 1em;
        border-bottom: 1px solid #E7E9E9;
        text-decoration: underline;
        color: #2F80ED;

        >.icon {
            width: 1.5em;
            height: 1.5em;
        }

        >.value {
            font-weight: 500;
            font-size: .875em;
            line-height: 115%;

        }

        &:hover {
            text-decoration: none;
        }
    }

    >.remnants {
        margin-top: 1em;
        font-weight: 500;
        font-size: .875em;
        line-height: 130%;
        color: #27AE60;
    }
}
</style>
