<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
    rating: {
        type: Number,
        default: 0,
    },
    label: {
        type: Boolean,
        default: true,
    },
    form: {
        type: [Object, Boolean] as PropType<false | Form.Options>,
        default: false,
    }
});

const inputRatingValue = ref<number>(props.rating);
const hoverRatingIndex = ref<number | null>(null);

const getStyle = computed(() => {
    const rating = hoverRatingIndex.value !== null ? hoverRatingIndex.value : inputRatingValue.value;
    const integerPart = Math.floor(rating);
    const fractal = rating - integerPart;

    return (i: number) => {
        if (i <= integerPart) {
            return { background: '#FFC109' };
        }

        if (i === integerPart + 1 && fractal > 0) {
            return {
                background: `linear-gradient(to right, #ffc109 ${fractal * 100}%, #909AAA80 ${fractal * 100}%)`
            };
        }

        return { background: '#909AAA80' };
    };
});

const updateRating = (newRating: number) => {
    inputRatingValue.value = newRating;

    useForm().setValue({ ...props.form, value: newRating });
};
</script>

<template>
    <div class="rating-container">
        <template v-if="form">
            <span
                v-for="i in 5"
                :key="i"
                class="star interactive"
                :style="getStyle(i)"
                @mouseover="hoverRatingIndex = i"
                @mouseleave="hoverRatingIndex = null"
                @click="props.form && updateRating(i)"
            ></span>

            <CommonFormInput
                v-show="false"
                type="number"
                :min="0"
                :max="5"
                :validators="false"
                :id="form.id"
                :namespace="form.namespace"
                @input="updateRating"
            />
        </template>
        <template v-else>
            <span class="star" v-for="(i, index) in 5" :key="index" :style="getStyle(i)"></span>
        </template>
        <span v-if="label && rating > 0" class="average">
            {{ rating.toFixed(1) }}
        </span>
    </div>
</template>

<style lang="scss">
.rating-container {
    display: flex;
    align-items: center;
    line-height: 100%;
    gap: .25em;

    .star {
        background-color: #909AAA80;
        min-width: 1em;
        min-height: 1em;
        height: 100%;
        clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
        aspect-ratio: 1 / 1;

        &.interactive {
            cursor: pointer;
            transition: background .25s ease;
        }
    }

    .average {
        margin-left: .25em;
    }
}
</style>
