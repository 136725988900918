<script setup lang="ts">
const props = defineProps({
    product: {
        type: Object as PropType<Products.Product>,
        required: true,
    },

    order: {
        type: Object as PropType<Cart.Order | null>,
        default: null
    },
});

const baseAmountValue = defineModel('base', {
    type: Number as PropType<number>,
    default: 1,
});

const partialAmountValue = defineModel('partial', {
    type: Number as PropType<number>,
    default: 0,
});

const { getValue, setValue } = useForm();

const formOptions = {
    namespace: `productQuantity-${props.product.id}-${props.order?.id}`,
    baseId: `base`,
    partialId: `partial`,
}

const productAvailableQuantity = computed(() => {
    let result = 0;

    if (props.order && props.product.id in props.order.items) {
        result = props.order.items[props.product.id].availableQuantity;
    }

    if (!result) {
        result = props.product.productRemnants?.reduce((count, remnant) => {
            return count += Math.floor(remnant.quantity);
        }, 0)
    }

    return result;
});

const formBaseValue = computed(() => {
    const formValue = getValue({
        namespace: formOptions.namespace,
        id: formOptions.baseId,
    });

    return formValue ? parseInt(formValue) : null;
});

const formPartialValue = computed(() => {
    const formValue = getValue({
        namespace: formOptions.namespace,
        id: formOptions.partialId,
    });

    return formValue ? parseInt(formValue) : null;
});

const denominator = props.product.attributes.price.denominator;

watch(baseAmountValue, (value) => {
    if (value === 0 && partialAmountValue.value === 0) {
        setValue({
            namespace: formOptions.namespace,
            id: formOptions.partialId,
            value: (denominator - 1).toString(),
        });
    }

    if (value === Math.round(productAvailableQuantity.value)) {
        setValue({
            namespace: formOptions.namespace,
            id: formOptions.partialId,
            value: '0',
        });
    }

    setValue({
        namespace: formOptions.namespace,
        id: formOptions.baseId,
        value: value.toString(),
    });
}, { immediate: true });

watch(partialAmountValue, (value) => {
    if (value === denominator) {
        const baseValue = Math.min((baseAmountValue.value || 0) + 1, productAvailableQuantity.value);
        const partialValue = 0;

        setValue({
            namespace: formOptions.namespace,
            id: formOptions.baseId,
            value: (baseValue).toString(),
        });

        setValue({
            namespace: formOptions.namespace,
            id: formOptions.partialId,
            value: partialValue.toString(),
        });
    }

    setValue({
        namespace: formOptions.namespace,
        id: formOptions.partialId,
        value: value.toString(),
    });
}, { immediate: true });

watch([formBaseValue, formPartialValue], (value) => {
    if (typeof value[0] === 'number') {
        baseAmountValue.value = value[0];
    }

    if (typeof value[1] === 'number') {
        partialAmountValue.value = value[1];
    }
});
</script>

<template>
    <div class="product-quantity">
        <CommonFormInput
            type="number"
            class="base-amount"
            :id="formOptions.baseId"
            :namespace="formOptions.namespace"
            :label="
                product.attributes.price.partName && product.attributes.price.denominator > 1
                ? product.attributes.price.wholeName
                : ''
            "
            :min="product.attributes.price.denominator <= 1 || !product.attributes.price.denominator ? 1 : 0"
            :max="productAvailableQuantity"
        />

        <CommonFormInput
            v-if="product.attributes.price.partName && product.attributes.price.denominator > 1"
            type="number"
            class="partial-amount"
            :id="formOptions.partialId"
            :namespace="formOptions.namespace"
            :label="product.attributes.price.partName"
            :min="baseAmountValue && baseAmountValue > 0 ? 0 : 1"
            :max="baseAmountValue && baseAmountValue >= productAvailableQuantity ? 0 : product.attributes.price.denominator"
        />
    </div>
</template>

<style lang="scss">
.product-quantity {
    >.base-amount, >.partial-amount {
        >label {
            text-align: center;
            grid-column: 1 / 4;
            color: #484B4C;
            font-size: 1em;
            opacity: 1;
        }
    }
}
</style>
