export function useCart() {
    const orders = useState<{
        [key in Cart.Order['id']]: Cart.Order
    }>('cartOrders', () => ({}));

    const createOrder = async (type: Cart.Order['type'] = 'default') => {
        const orderId = Date.now();

        orders.value[orderId] = {
            id: orderId,
            type: type,
            pharmacy: null,
            items: {},
            get price() {
                return Object.values(this.items).reduce((acc, item) => acc + item.price, 0);
            }
        };

        return orders.value[orderId] || null;
    };

    const addProduct = (order: Cart.Order, cartItem: Omit<Cart.Item, 'price' | 'quantity' | 'availableQuantity'>): void => {
        const existingItem = order.items[cartItem.product.id];
        
        if (existingItem) {
            existingItem.product = cartItem.product;
            existingItem.baseQuantity += cartItem.baseQuantity;
            existingItem.partialQuantity += cartItem.partialQuantity;            
        } else {
            order.items[cartItem.product.id] = {
                ...cartItem,
                get price() {
                    let price = this.product.price * this.quantity;

                    if (this.product.discounts?.[0]) {
                        price = this.product.discounts[0].discountPrice * this.quantity;
                    }

                    return price;
                },

                get quantity() {
                    const productBasePartialRatio = 1 / this.product.attributes.price.denominator;
                    return this.baseQuantity + (this.partialQuantity * productBasePartialRatio);
                },

                get availableQuantity() {
                    if (!order.pharmacy) return 0;

                    return this.product.productRemnants.find(remnant => remnant.storeId === order.pharmacy?.id)?.quantity || 0;
                },
            };
        }
    };

    return {
        orders,
        addProduct,
        createOrder,
    };
}
