<script setup lang="ts">
const { menusState } = useApp();

const {
    banners,
    blogArticles,
    saleProducts,
    popularProducts,
    fetchBanners,
    fetchPopularProducts,
    fetchSaleProducts,
    fetchBlogArticles,
} = useMainPage();

const [
    bannersPromise,
    popularProductsPromise,
    saleProductsPromise,
    blogArticlesPromise,
] = await Promise.allSettled([
    useAsyncData(fetchBanners),
    useAsyncData(`fetchPopularProducts`, fetchPopularProducts),
    useAsyncData(fetchSaleProducts),
    useAsyncData(fetchBlogArticles),
]);

if (bannersPromise.status === 'fulfilled' && bannersPromise.value.data.value) {
    banners.value = bannersPromise.value.data.value;
}

if (popularProductsPromise.status === 'fulfilled' && popularProductsPromise.value.data.value) {
    popularProducts.value = popularProductsPromise.value.data.value;
}

if (saleProductsPromise.status === 'fulfilled' && saleProductsPromise.value.data.value) {
    saleProducts.value = saleProductsPromise.value.data.value;
}

if (blogArticlesPromise.status === 'fulfilled' && blogArticlesPromise.value.data.value) {
    blogArticles.value = blogArticlesPromise.value.data.value;
}
</script>

<template>
    <div class="container-fluid">
        <div class="menu-section">
            <CategoryMenuList :class="{ active: menusState.catalog }" />
            <HomeCarousel :banners="banners"/>
        </div>

        <ProductCarousel
            v-if="popularProducts"
            :title="'Популярні товари'"
            :products="popularProducts"
        />

        <HomeAskPharmacist class="container-inner-fluid"/>

        <ProductCarousel
            v-if="saleProducts"
            :title="'Акційні пропозиції'"
            :products="saleProducts"
        />

        <div class="main-blog" v-if="blogArticles.length">
            <div class="header">
                <h1>Блог здоров’я</h1>
                <NuxtLink to="/blog" class="see-more">
                    <span class="value">Переглянути всі</span>
                    <IconDoubleRightCaret />
                </NuxtLink>
            </div>

            <BlogGrid :articles="blogArticles" />
        </div>
    </div>
</template>

<style lang="scss">
@use '~/assets/styles/mixins.scss';

.menu-section {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;

    >.main-menu {
        position: relative;

        @include mixins.respond-to(desktop) {
            display: block;
        }

        @include mixins.respond-to(tablet, phone) {
            display: none;
        }

        >.list-group {
            height: 100%;
        }

        >.menu-dropdown {
            position: absolute;
            top: 0;
            left: 100%; // 100% = flex 25% of first menu-section child
            width: 300%; // 300% = flex 75% for second menu-section child
            height: 100%;
            z-index: 2;
        }
    }

    @include mixins.respond-to(desktop) {
        > :first-child {
            align-self: stretch;
            flex: 25% 0 0;

            &.active {
                z-index: 10;
            }
        }

        > :nth-child(2) {
            flex: 75% 0 1;
            margin: 1.25em 0 0 1.25em;
        }
    }

    @include mixins.respond-to(tablet) {
        > :nth-child(2) {
            flex: 100% 0 0;
            min-height: 10vh;
        }
    }
}

.main-blog {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 1.875em 0;

    @mixin full-bg-item {
        >:first-child {
            margin-right: unset;
        }

        .item-image {
            height: 100%;
            transition: filter .6s ease;

            @include mixins.respond-to(phone) {
                position: absolute !important;
                top: 0;
                left: 0;
                width: 100%;
            }

            &::before {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                width: 100%;
                background: linear-gradient(180deg, rgba(46, 100, 102, 0.06) 30.52%, rgba(31, 72, 73, 0.6) 64.96%, rgba(31, 72, 73, 0.92) 100%);
            }
        }

        .item-info {
            @include mixins.respond-to(desktop, tablet) {
                position: absolute;
                left: 2.5em;
                right: 5.25em;
                bottom: 1.875em;
            }

            @include mixins.respond-to(phone) {
                margin: 3em 3em 1.5em 1.5em;
                z-index: 1;
            }

            .title {
                font-size: 1.375em;
                margin-bottom: .5em;
            }

            .meta .value,
            .meta .date,
            .title,
            .author .author-name {
                color: #FFFFFF;
            }

            .author .author-photo {
                border: 2px solid #FFFFFF;
                width: 2.75em;
                height: 2.75em;

                @include mixins.respond-to(phone) {
                    width: 2.25em;
                    height: 2.25em;
                }
            }

            .meta .comments>svg *,
            .author .author-photo>* {
                fill: #FFFFFF;
            }
        }

        &:hover {
            >.item-image {
                filter: grayscale(50%);
            }
        }
    }

    .header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1em;
        flex-flow: row wrap;
        gap: .5em;

        >h1 {
            line-height: 120%;
            font-weight: bold;
            color: #212E42;
            margin: 0;

            @include mixins.respond-to(desktop) {
                font-size: 3em;
            }

            @include mixins.respond-to(tablet) {
                font-size: clamp(1.625em, 2vw + 1em, 3em);
            }

            @include mixins.respond-to(phone) {
                font-size: 1.625em;
            }
        }

        .see-more {
            display: inline-flex;
            align-items: center;
            transition: color .4s ease;
            cursor: pointer;

            > :first-child {
                margin-right: .75em;
            }

            >.value {
                line-height: 100%;
                font-weight: 500;
            }

            >svg {
                width: 1em;
                height: 1em;

                * {
                    fill: #4bc2c6;
                }
            }

            &:hover {
                color: #4bc2c6;
            }
        }
    }

    .blog-items {
        flex: 100%;

        >.blog-item {
            width: 100%;
            flex-direction: row;
            min-width: 0;

            @include mixins.respond-to(desktop) {
                >.item-image, >.item-info {
                    flex: 50%;
                }

                >:first-child {
                    margin-right: 2.25em;
                }
            }

            @include mixins.respond-to(tablet) {
                > :first-child {
                    margin-right: 1.5em;
                }

                > :nth-child(2) {
                    padding: 3% 0;
                }
            }

            @include mixins.respond-to(tablet, phone) {
                > :first-child {
                    flex: 40%;
                }

                > :nth-child(2) {
                    flex: 60%;
                }
            }

            .item-image {
                margin-bottom: 0;
            }

            .item-info {
                margin-top: unset;
                align-self: center;

                .meta {
                    order: 1;
                }

                .tags {
                    order: 2;
                }

                .title {
                    order: 3;
                    font-size: 1.125em;
                    margin-bottom: 1em;

                    @include mixins.respond-to(phone) {
                        font-size: 1em;
                    }
                }

                .author {
                    order: 4;
                }
            }
        }

        @include mixins.respond-to(desktop, tablet) {
            >:first-child {
                @include full-bg-item;
            }
        }

        @include mixins.respond-to(phone) {
            >.blog-item {
                @include full-bg-item;
            }
        }

        @include mixins.respond-to(desktop) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
            column-gap: 2.875em;
            row-gap: 2em;

            >:first-child {
                grid-column: 1;
                grid-row: 1 / 3;
            }

            >:nth-child(2) {
                grid-column: 2;
                grid-row: 1 / 2;
            }

            >:nth-child(3) {
                grid-column: 2;
                grid-row: 2 / 3;
            }
        }

        @include mixins.respond-to(tablet, phone) {
            display: flex;
            flex-flow: column nowrap;
            column-gap: unset;
            row-gap: unset;

            >:not(:last-child) {
                margin-bottom: 2em;
            }
        }
    }
}
</style>
