<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32 32"
    >
        <path
            fill="#27ae60"
            d="M0 16a16 16 0 1 1 32 0 16 16 0 0 1-32 0Zm0 0"
        />
        <path
            fill="#fff"
            d="M22.75 11.04c-.48-.5-1.28-.5-1.76 0l-7.08 7.21-2.89-2.98a1.26 1.26 0 0 0-1.78.02c-.47.5-.47 1.3.01 1.8l3.73 3.8.06.06a1.2 1.2 0 0 0 1.76 0l7.95-8.12c.48-.5.48-1.3 0-1.8Zm0 0"
        />
    </svg>
</template>
