<script setup lang="ts">
const modalVisible = ref(false);
</script>

<template>
    <div class="question-container">
        <IconHeartCross class="icon" />

        <div class="content">
            <h1 class="header">Запитай професійного фармацевта</h1>

            <button
                class="ask-button"
                @click.prevent="modalVisible = true"
            >
                Задати питання
            </button>

            <NuxtLink
                to="/page/postavte-zapytannya-farmatsevtu-yak-tse-pratsyuye"
                class="how-it-works"
            >
                Як це працює?
            </NuxtLink>
        </div>

        <NuxtImg
            format="webp"
            loading="lazy"
            class="image"
            sizes="md:640 lg:790"
            src="/images/pharmacist.png"
            alt="Фото фармацевта"
        />

        <ModalAskPharmacist v-model="modalVisible" />
    </div>
</template>

<style lang="scss">
@use '~/assets/styles/mixins.scss';

.question-container {
    $background-color: linear-gradient(90deg, #27ACAF 21.51%, #4BC2C6 58.65%);

    background: $background-color;
    position: relative;
    width: 100vw;
    margin-left: calc(50% - 50vw);
    display: flex;
    align-items: center;
    padding-top: 8%;
    padding-bottom: 8%;

    @include mixins.respond-to(tablet, phone) {
        justify-content: space-around;
    }

    >.icon {
        position: absolute;
        width: auto;
        height: 60%;
        left: 2%;
        top: 15%;
        opacity: .1;

        @include mixins.respond-to(tablet) {
            height: 80%;
        }

        @include mixins.respond-to(phone) {
            left: 5%;
            top: 20%;
            height: 100%;
        }
    }

    >.content {
        position: relative;
        z-index: 1;
        column-gap: 0.875em;
        
        @include mixins.respond-to(desktop) {
            width: 55%;
        }

        >.header {
            font-weight: bold;
            font-size: clamp(1.625em, 5vw, 3em);
            margin-bottom: .625em;
            line-height: 130%;
            color: #FFFFFF;

            @include mixins.respond-to(tablet, phone) {
                text-align: center;
            }
        }

        >.ask-button,
        >.how-it-works {
            display: inline-block;
            border-radius: 1em;
            line-height: 100%;
            font-weight: 500;
            text-align: center;
            border: 2px solid #FFFFFF;
            padding: 1.125em 2em;
            width: auto;

            @include mixins.respond-to(phone) {
                width: 100%;
            }
        }

        >.ask-button {
            background: #FFFFFF;
            color: #212E42;
            cursor: pointer;

            @include mixins.respond-to(desktop, tablet) {
                margin-right: 0.875em;
            }

            @include mixins.respond-to(phone) {
                margin-bottom: 1em;
            }

            &:hover {
                background: #e6e6e6;
                border-color: #e6e6e6;
            }
        }

        >.how-it-works {
            background: transparent;
            color: #FFFFFF;

            &:hover {
                background: #fff;
                color: #212E42;
            }
        }
    }

    >.image {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        color: #FFFFFF;
        font-size: 1.75em;

        @include mixins.respond-to(tablet) {
            opacity: .5;
        }

        @include mixins.respond-to(phone) {
            display: none;
        }
    }
}
</style>
