export function useMainPage() {
    const { public: { apiBase: APIPrefix } } = useRuntimeConfig();

    const banners = useState<Banners.Banner[]>('banners', () => []);
    const popularProducts = useState<Products.Product[]>('popularProducts', () => []);
    const saleProducts = useState<Products.Product[]>('saleProducts', () => []);
    const blogArticles = useState<Blog.Article[]>('blogArticles', () => []);

    const fetchBanners = async () => {
        return await $fetch<Banners.Banner[]>(
            `${APIPrefix}/banners`,
            {
                method: 'GET',
            }
        );
    };

    const fetchPopularProducts = async () => {
        return await $fetch<Products.Product[]>(
            `${APIPrefix}/popular-products`,
            {
                method: 'GET',
            }
        );
    };

    const fetchSaleProducts = async () => {
        return await $fetch<Products.Product[]>(
            `${APIPrefix}/sale-products`,
            {
                method: 'GET',
            }
        );
    };

    const fetchBlogArticles = async () => {
        const articles = await $fetch<Blog.Article[]>(
            `${APIPrefix}/blog-main`,
            {
                method: 'GET',
            }
        );

        for (const article of articles) {
            Object.assign(article, {
                publishedAt: new Intl.DateTimeFormat('uk', {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                }).format(new Date(article.publishedAt)),
            });
        }

        return articles;
    };

    return {
        banners,
        popularProducts,
        saleProducts,
        blogArticles,
        fetchBanners,
        fetchPopularProducts,
        fetchSaleProducts,
        fetchBlogArticles,
    };
}
