<template>
    <svg
        viewBox="0 0 1183 322"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M219.68 34.55V46.12C219.68 50.75 223.44 54.51 228.07 54.51H239.64C244.27 54.51 248.03 50.75 248.03 46.12V34.55C248.03 29.92 244.27 26.16 239.64 26.16H228.07C223.43 26.16 219.68 29.91 219.68 34.55Z"
            fill="#ADDCE5"
        />
        <path
            d="M273.19 23.77H266.34C263.66 23.77 261.48 25.94 261.48 28.63V35.48C261.48 38.16 263.65 40.34 266.34 40.34H273.19C275.87 40.34 278.05 38.17 278.05 35.48V28.63C278.05 25.94 275.87 23.77 273.19 23.77Z"
            fill="#ADDCE5"
        />
        <path
            d="M257.17 0.350006H249.24C246.86 0.350006 244.92 2.28 244.92 4.67V12.6C244.92 14.98 246.85 16.92 249.24 16.92H257.17C259.55 16.92 261.49 14.99 261.49 12.6V4.67C261.48 2.28 259.55 0.350006 257.17 0.350006Z"
            fill="#C9FAFB"
        />
        <path
            d="M236.36 70.51H213.34C208.55 70.51 204.68 74.39 204.68 79.17V102.19C204.68 106.98 208.56 110.85 213.34 110.85H236.36C241.15 110.85 245.02 106.97 245.02 102.19V79.17C245.02 74.39 241.15 70.51 236.36 70.51Z"
            fill="#74BFBB"
        />
        <path
            d="M265.91 84.51H281.71C286.49 84.51 290.37 80.63 290.37 75.85V60.05C290.37 55.27 286.49 51.39 281.71 51.39H265.91C261.13 51.39 257.25 55.27 257.25 60.05V75.85C257.25 80.64 261.13 84.51 265.91 84.51Z"
            fill="#74BFBB"
        />
        <path
            d="M216.09 140.68H180.67V105.26C180.67 99.33 175.86 94.52 169.93 94.52H149.52C143.59 94.52 138.78 99.33 138.78 105.26V140.68H103.36C97.4299 140.68 92.6199 145.49 92.6199 151.42V171.83C92.6199 177.76 97.4299 182.57 103.36 182.57H138.78V218C138.78 223.93 143.59 228.74 149.52 228.74H169.93C175.86 228.74 180.67 223.93 180.67 218V182.58H216.09C222.02 182.58 226.83 177.77 226.83 171.84V151.43C226.83 145.49 222.02 140.68 216.09 140.68Z"
            fill="url(#paint0_linear_1_99)"
        />
        <path
            d="M307.28 146.19H265.36C263 146.19 261.81 149.05 263.48 150.72L274.39 161.63L159.72 276.31L45.0399 161.63L159.72 46.95L170.08 57.31C171.96 59.19 175.16 57.86 175.16 55.21V14.22C175.16 13.43 174.85 12.67 174.29 12.11L167.46 5.27999C163.18 0.999992 156.25 0.999992 151.98 5.27999L3.36991 153.89C-0.910088 158.17 -0.910088 165.1 3.36991 169.37L151.98 317.99C156.26 322.27 163.19 322.27 167.46 317.99L316.08 169.37C320.36 165.09 320.36 158.16 316.08 153.89L309.16 146.97C308.66 146.47 307.98 146.19 307.28 146.19Z"
            fill="url(#paint1_linear_1_99)"
        />
        <path
            d="M401.56 221.21V254.57H393.58V227.83H380.8L380.48 234.45C380.27 238.86 379.85 242.56 379.23 245.54C378.61 248.52 377.57 250.87 376.1 252.6C374.63 254.33 372.62 255.19 370.06 255.19C368.87 255.19 367.4 254.96 365.65 254.5L366.03 247.94C366.63 248.07 367.14 248.13 367.56 248.13C369.18 248.13 370.39 247.5 371.2 246.22C372.01 244.95 372.53 243.43 372.76 241.66C372.99 239.89 373.2 237.36 373.37 234.07L373.94 221.2H401.56V221.21Z"
            fill="#3C3C3B"
        />
        <path
            d="M444.16 214.37C443.18 213.47 442.69 212.36 442.69 211.03C442.69 209.7 443.18 208.58 444.16 207.69C445.14 206.8 446.35 206.35 447.8 206.35C449.25 206.35 450.46 206.78 451.44 207.63C452.42 208.48 452.91 209.56 452.91 210.85C452.91 212.22 452.43 213.38 451.47 214.32C450.51 215.26 449.29 215.73 447.8 215.73C446.35 215.71 445.14 215.27 444.16 214.37ZM443.78 221.21H451.76V254.57H443.78V221.21Z"
            fill="#3C3C3B"
        />
        <path
            d="M508.23 241.2H502.03V254.57H494.05V221.21H502.03V234.7H508.42L518.51 221.21H527.07L514.68 237.26L527.9 254.56H518.51L508.23 241.2Z"
            fill="#3C3C3B"
        />
        <path
            d="M565.01 221.21H573V243.2L591.84 221.21H599.12V254.57H591.14V232.58L572.36 254.57H565.01V221.21Z"
            fill="#3C3C3B"
        />
        <path
            d="M723.16 221.21V254.57H715.24V227.83H698.44V254.57H690.46V221.21H723.16Z"
            fill="#3C3C3B"
        />
        <path
            d="M771.33 252.82C768.56 251.36 766.4 249.33 764.85 246.73C763.29 244.13 762.52 241.18 762.52 237.89C762.52 234.6 763.3 231.66 764.85 229.08C766.4 226.5 768.56 224.48 771.33 223.02C774.1 221.56 777.21 220.83 780.66 220.83C784.15 220.83 787.28 221.56 790.05 223.02C792.82 224.48 794.98 226.5 796.53 229.08C798.08 231.66 798.86 234.6 798.86 237.89C798.86 241.18 798.08 244.13 796.53 246.73C794.97 249.33 792.81 251.36 790.05 252.82C787.28 254.28 784.15 255.01 780.66 255.01C777.2 255.01 774.09 254.28 771.33 252.82ZM787.93 245.45C789.85 243.53 790.8 241.01 790.8 237.89C790.8 234.77 789.84 232.25 787.93 230.33C786.01 228.42 783.59 227.46 780.65 227.46C777.71 227.46 775.29 228.42 773.4 230.33C771.5 232.25 770.56 234.77 770.56 237.89C770.56 241.01 771.51 243.53 773.4 245.45C775.29 247.37 777.71 248.32 780.65 248.32C783.59 248.32 786.02 247.37 787.93 245.45Z"
            fill="#3C3C3B"
        />
        <path
            d="M901.5 241.2H895.3V254.57H887.32V221.21H895.3V234.7H901.69L911.78 221.21H920.34L907.95 237.26L921.17 254.56H911.78L901.5 241.2Z"
            fill="#3C3C3B"
        />
        <path
            d="M989.2 221.21V254.57H981.22V227.83H968.45L968.13 234.45C967.92 238.86 967.5 242.56 966.88 245.54C966.26 248.52 965.22 250.87 963.75 252.6C962.28 254.33 960.27 255.19 957.71 255.19C956.52 255.19 955.05 254.96 953.3 254.5L953.68 247.94C954.28 248.07 954.79 248.13 955.21 248.13C956.83 248.13 958.04 247.5 958.85 246.22C959.66 244.95 960.18 243.43 960.41 241.66C960.64 239.89 960.85 237.36 961.02 234.07L961.59 221.2H989.2V221.21Z"
            fill="#3C3C3B"
        />
        <path
            d="M1031.81 214.37C1030.83 213.47 1030.34 212.36 1030.34 211.03C1030.34 209.7 1030.83 208.58 1031.81 207.69C1032.79 206.8 1034 206.35 1035.45 206.35C1036.9 206.35 1038.11 206.78 1039.09 207.63C1040.07 208.48 1040.56 209.56 1040.56 210.85C1040.56 212.22 1040.08 213.38 1039.12 214.32C1038.16 215.26 1036.94 215.73 1035.45 215.73C1034 215.71 1032.78 215.27 1031.81 214.37ZM1031.42 221.21H1039.4V254.57H1031.42V221.21Z"
            fill="#3C3C3B"
        />
        <path
            d="M1095.87 241.2H1089.67V254.57H1081.69V221.21H1089.67V234.7H1096.06L1106.15 221.21H1114.71L1102.32 237.26L1115.54 254.56H1106.15L1095.87 241.2Z"
            fill="#3C3C3B"
        />
        <path
            d="M1182.93 221.21L1166.96 257.25C1165.47 260.87 1163.66 263.42 1161.53 264.9C1159.4 266.38 1156.82 267.12 1153.8 267.12C1152.1 267.12 1150.41 266.85 1148.75 266.31C1147.09 265.77 1145.73 265.02 1144.66 264.06L1147.85 258.31C1148.62 259.02 1149.52 259.58 1150.56 260C1151.6 260.42 1152.66 260.62 1153.72 260.62C1155.13 260.62 1156.28 260.27 1157.2 259.56C1158.12 258.85 1158.96 257.66 1159.72 256L1160.29 254.69L1145.41 221.21H1153.71L1164.44 245.88L1175.24 221.21H1182.93Z"
            fill="#3C3C3B"
        />
        <path
            d="M389.77 145.98C392.8 157.23 401.2 162.77 414.95 162.77C423.7 162.77 430.49 159.74 434.95 153.84L453.53 164.56C444.78 177.24 431.74 183.67 414.59 183.67C399.77 183.67 387.98 179.21 379.05 170.28C370.12 161.35 365.66 150.09 365.66 136.52C365.66 123.13 370.12 111.88 378.87 102.94C387.62 93.83 399.06 89.37 412.81 89.37C425.85 89.37 436.74 93.83 445.13 102.94C453.71 112.05 457.99 123.13 457.99 136.52C457.99 139.55 457.63 142.59 457.1 145.99H389.77V145.98ZM434.96 128.12C432.28 115.98 423.35 110.08 412.82 110.08C400.49 110.08 392.1 116.69 389.42 128.12H434.96Z"
            fill="#3C3C3B"
        />
        <path
            d="M559.63 56.14V79.72H525.87V183.65L501.23 183.66V79.72H467.65V56.14H559.63V56.14Z"
            fill="#3C3C3B"
        />
        <path
            d="M625.72 91.86H648.76V183.65H625.72V170.62C618.75 179.37 609.11 183.66 596.61 183.66C584.64 183.66 574.46 179.2 565.89 170.09C557.49 160.98 553.21 149.73 553.21 136.51C553.21 123.3 557.5 112.22 565.89 103.11C574.46 94 584.64 89.36 596.61 89.36C609.11 89.36 618.75 93.65 625.72 102.4V91.86ZM600.89 161.7C608.04 161.7 613.93 159.38 618.57 154.73C623.39 149.91 625.71 143.84 625.71 136.51C625.71 129.18 623.39 123.12 618.57 118.47C613.93 113.65 608.03 111.32 600.89 111.32C593.75 111.32 587.85 113.64 583.21 118.47C578.57 123.11 576.24 129.18 576.24 136.51C576.24 143.84 578.56 149.9 583.21 154.73C587.86 159.37 593.75 161.7 600.89 161.7Z"
            fill="#3C3C3B"
        />
        <path
            d="M754.14 103.11C762.72 112.22 767 123.3 767 136.51C767 149.73 762.71 160.98 754.14 170.09C745.75 179.2 735.39 183.66 723.42 183.66C710.92 183.66 701.28 179.37 694.49 170.62V181.16H671.45V56.14H694.49V102.4C701.28 93.65 710.92 89.36 723.42 89.36C735.39 89.36 745.75 94 754.14 103.11ZM719.14 161.7C726.29 161.7 732.18 159.38 736.82 154.73C741.64 149.91 743.96 143.84 743.96 136.51C743.96 129.18 741.64 123.12 736.82 118.47C732.18 113.65 726.28 111.32 719.14 111.32C712 111.32 706.1 113.64 701.46 118.47C696.82 123.11 694.49 129.18 694.49 136.51C694.49 143.84 696.81 149.9 701.46 154.73C706.1 159.37 711.99 161.7 719.14 161.7Z"
            fill="#3C3C3B"
        />
        <path
            d="M785.05 183.65V56.14H808.09V183.65H785.05Z"
            fill="#3C3C3B"
        />
        <path
            d="M850.42 145.98C853.45 157.23 861.85 162.77 875.6 162.77C884.35 162.77 891.14 159.74 895.6 153.84L914.18 164.56C905.43 177.24 892.39 183.67 875.24 183.67C860.42 183.67 848.63 179.21 839.7 170.28C830.77 161.35 826.31 150.09 826.31 136.52C826.31 123.13 830.77 111.88 839.52 102.94C848.27 93.83 859.71 89.37 873.46 89.37C886.5 89.37 897.39 93.83 905.78 102.94C914.36 112.05 918.64 123.13 918.64 136.52C918.64 139.55 918.28 142.59 917.75 145.99H850.42V145.98ZM895.61 128.12C892.93 115.98 884 110.08 873.47 110.08C861.14 110.08 852.75 116.69 850.07 128.12H895.61Z"
            fill="#3C3C3B"
        />
        <path
            d="M984.92 114.01H964.74V151.16C964.74 160.81 971.71 160.99 984.92 160.27V183.65C953.29 184.28 941.7 175.63 941.7 151.16V114.01H926.16V91.86H941.7V73.82L964.74 66.85V91.85H984.92V114.01Z"
            fill="#3C3C3B"
        />
        <path
            d="M1085.3 183.65H1058.51L1026.01 140.62V183.65L1002.97 183.68V56.14H1026V131.16L1056.72 91.87H1084.23L1048.33 135.99L1085.3 183.65Z"
            fill="#3C3C3B"
        />
        <path
            d="M1159.96 91.86H1183V183.65H1159.96V170.62C1153 179.37 1143.35 183.66 1130.85 183.66C1118.88 183.66 1108.7 179.2 1100.13 170.09C1091.73 160.98 1087.45 149.73 1087.45 136.51C1087.45 123.3 1091.74 112.22 1100.13 103.11C1108.7 94 1118.88 89.36 1130.85 89.36C1143.35 89.36 1153 93.65 1159.96 102.4V91.86ZM1135.14 161.7C1142.29 161.7 1148.18 159.38 1152.82 154.73C1157.64 149.91 1159.96 143.84 1159.96 136.51C1159.96 129.18 1157.64 123.12 1152.82 118.47C1148.18 113.65 1142.28 111.32 1135.14 111.32C1127.99 111.32 1122.1 113.64 1117.46 118.47C1112.82 123.11 1110.49 129.18 1110.49 136.51C1110.49 143.84 1112.81 149.9 1117.46 154.73C1122.1 159.37 1127.99 161.7 1135.14 161.7Z"
            fill="#3C3C3B"
        />
        <defs>
            <linearGradient
                id="paint0_linear_1_99"
                x1="92.6115"
                y1="161.633"
                x2="226.832"
                y2="161.633"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#74BFBB" />
                <stop
                    offset="0.4729"
                    stop-color="#74BFBB"
                />
                <stop
                    offset="1"
                    stop-color="#ADDCE5"
                />
            </linearGradient>
            <linearGradient
                id="paint1_linear_1_99"
                x1="0.160212"
                y1="161.633"
                x2="319.283"
                y2="161.633"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#E73A38" />
                <stop
                    offset="0.5088"
                    stop-color="#E73A38"
                />
                <stop
                    offset="1"
                    stop-color="#EF7881"
                />
            </linearGradient>
        </defs>
    </svg>
</template>
