<template>
    <svg 
        xmlns="http://www.w3.org/2000/svg" 
        viewBox="0 0 32 32"
    >
        <path 
            d="M25.3 29H6.7c-.4 0-.802-.2-.9-.602-.1-.398-.1-.796.2-1.097l14.7-14.7a.966.966 0 0 1 1.402 0l6.699 6.7c.199.199.3.398.3.699v5.3c-.101 2.098-1.703 3.7-3.8 3.7ZM9.103 27h16.296c.903 0 1.704-.7 1.704-1.7v-4.902L21.398 14.7ZM4 26.3c-.602 0-1-.402-1-1V6.7C3 4.601 4.602 3 6.7 3h18.698c2 0 3.704 1.602 3.704 3.7v8c0 .6-.403 1-1 1-.602 0-1-.4-1-1v-8C27 5.7 26.3 5 25.3 5H6.699C5.7 5 5 5.7 5 6.7v18.698c0 .5-.398.903-1 .903Zm7.3-12c-1.698 0-3-1.3-3-3 0-1.698 1.302-3 3-3 1.7 0 3 1.302 3 3 0 1.7-1.3 3-3 3Zm0-4c-.6 0-1 .4-1 1 0 .598.4 1 1 1 .598 0 1-.402 1-1 0-.6-.402-1-1-1Zm0 0"
        />
    </svg>
</template>
