<script setup lang="ts">
import 'keen-slider/keen-slider.min.css';
import KeenSlider from 'keen-slider';
import type { KeenSliderInstance } from 'keen-slider/vue.es';

const props = defineProps({
    title: {
        type: String,
        default: 'Product Carousel',
    },
    products: {
        type: Array as PropType<Products.Product[]>,
        required: true,
    },
    showAllButton: {
        type: String,
        default: '',
    },
});

const expanded = ref(false);
const container = ref<HTMLElement | null>(null);
const slider = ref<KeenSliderInstance | null>(null);

onMounted(() => {
    if (!container.value) return;

    slider.value = new KeenSlider(container.value, {
        loop: props.products.length > 5,
        mode: 'free-snap',
        slides: { perView: 5 },
        breakpoints: {
            '(max-width: 1200px)': {
                slides: { perView: 3 },
                mode: 'free',
            },
            '(max-width: 700px)': {
                mode: 'snap',
            },
        },
    });
});
</script>

<template>
    <div
        v-if="products.length"
        class="products-carousel"
    >
        <div class="header">
            <h1>{{ title }}</h1>

            <NuxtLink
                v-if="!showAllButton"
                :to="showAllButton"
                class="see-more"
            >
                <span class="value">Переглянути всі</span>
                <IconDoubleRightCaret />
            </NuxtLink>
        </div>

        <Transition name="fade">
            <button
                v-if="slider"
                class="arrow left"
                aria-label="Попередній товар"
                @click.prevent="slider.prev()"
            >
                <IconCaret class="icon"/>
            </button>
        </Transition>

        <ul
            ref="container"
            class="products-list keen-slider"
            :class="{ expanded: expanded }"
        >
            <li
                v-for="product in products"
                :key="`productList${product.id}`"
                class="keen-slider__slide item"
            >
                <ProductBox
                    :product="product"
                    class="product-box"
                />
            </li>
        </ul>

        <Transition name="fade">
            <button
                v-if="slider"
                class="arrow right"
                aria-label="Наступний товар"
                @click.prevent="slider.next()"
            >
                <IconCaret class="icon"/>
            </button>
        </Transition>

        <button
            v-if="products.length > 4"
            class="show-more"
            @click.prevent="expanded = !expanded"
        >
            {{ expanded ? 'Згорнути' : 'Показати ще' }}
        </button>
    </div>
</template>

<style lang="scss">
@use '~/assets/styles/mixins';

.products-carousel {
    position: relative;
    margin: 1.875em 0;

    >.header {
        display: flex;
        justify-content: space-between;
        flex-flow: row wrap;
        gap: .5em;

        >h1 {
            line-height: 120%;
            font-weight: bold;
            color: #212E42;
            margin: 0;

            @include mixins.respond-to(desktop) {
                font-size: 3em;
            }

            @include mixins.respond-to(tablet) {
                font-size: clamp(1.625em, 2vw + 1em, 3em);
            }

            @include mixins.respond-to(phone) {
                font-size: 1.625em;
            }
        }

        .see-more {
            display: inline-flex;
            align-items: center;
            transition: color .4s ease;
            cursor: pointer;

            > :first-child {
                margin-right: .75em;
            }

            >.value {
                line-height: 100%;
                font-weight: 500;
            }

            >svg {
                width: 1em;
                height: 1em;

                * {
                    fill: #4bc2c6;
                }
            }

            &:hover {
                color: #4bc2c6;
            }
        }
    }

    >.products-list {
        display: flex;
        overflow: hidden;
        list-style: none;
        margin: unset;
        padding: unset;
        z-index: 1;

        @mixin showGrabAbove($count) {
            @for $i from 1 through $count {
                &:has(.item:first-child:nth-last-child(#{$count + $i - 1}) ~ .item) {
                    cursor: grab;

                    &:active {
                        cursor: grabbing;
                    }
                }
            }
        }

        @include mixins.respond-to(desktop) {
            @include showGrabAbove(5);
        }

        @include mixins.respond-to(tablet) {
            @include showGrabAbove(3);
        }

        @include mixins.respond-to(phone) {
            display: flex;
            flex-flow: row wrap;
            justify-content: center;

            &:not(.expanded) {
                >.item:nth-child(n+5) {
                    display: none;
                }
            }
        }

        >.item {
            display: flex;

            @include mixins.respond-to(desktop) {
                flex: 20% 0 0;
                min-width: 20%;
                max-width: 20%;
            }

            @include mixins.respond-to(tablet) {
                flex: 33.33% 0 0;
                min-width: 33.33%;
                max-width: 33.33%;
            }

            @include mixins.respond-to(phone) {
                display: block;
                transition: none !important;
                transform: none !important;
                min-width: auto !important;
                max-width: 18.75em !important;
                width: 18.75em !important;
            }

            .product-box {
                margin: 1em .5em;
                width: calc(100% - 1em);
                height: calc(100% - 2em);

                @include mixins.respond-to(phone) {
                    margin: 1em auto;
                }
            }
        }
    }

    @mixin hideArrowBelow($count) {
        @for $i from $count through 1 {
            &:has(.products-list>.item:first-child:nth-last-child(#{$i})) >.arrow {
                display: none;
            }
            &:has(.products-list>.item:first-child:nth-last-child(#{$i}) ~ .item) >.arrow {
                display: none;
            }
        }
    }

    @include hideArrowBelow(5);

    >.arrow {
        background-color: #FFFFFF;
        position: absolute;
        width: 2em;
        height: 2.5em;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0.25em 0.375em rgba(0, 0, 0, 0.05);
        transition: left 0.3s ease, border-radius 0.3s ease, width 0.3s ease, right 0.3s ease;

        @include mixins.respond-to(tablet, phone) {
            display: none;
        }

        >.icon {
            width: 1.125em;
            height: 1.125em;
            transition: transform 0.3s ease;

            >path {
                stroke: rgb(75, 194, 198);
            }
        }

        &.left {
            left: -1.25em;
            top: calc(50% - 1.25em);
            border-top-left-radius: 50%;
            border-bottom-left-radius: 50%;

            &:hover {
                width: 2.5em;
                left: -2.5em;
                border-radius: 50%;

                >.icon {
                    transform: rotate(90deg) translateY(10%);
                }
            }

            >.icon {
                transform: rotate(90deg);
            }
        }

        &.right {
            right: -1.25em;
            top: calc(50% - 1.25em);
            border-top-right-radius: 50%;
            border-bottom-right-radius: 50%;

            &:hover {
                width: 2.5em;
                right: -2.5em;
                border-radius: 50%;

                >.icon {
                    transform: rotate(-90deg) translateY(10%);
                }
            }

            >.icon {
                transform: rotate(-90deg);
            }
        }
    }

    >.show-more {
        text-align: center;
        font-weight: 500;
        font-size: 1em;
        line-height: 100%;
        color: #212e42;
        width: 100%;

        @include mixins.respond-to(desktop, tablet) {
            display: none;
        }
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: left .5s ease, right .5s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
        &.left {
            left: 0;
        }

        &.right {
            right: 0;
        }
    }
}
</style>
