export function useApp() {
    const { public: { apiBase: APIPrefix } } = useRuntimeConfig();

    const deviceType = useState<Device.Type | null>('deviceType', () => null);
    const locale = useState<General.I18NKey>('locale', () => 'uk');
    const defaultCity = useState<Locations.City | null>('defaultCity', () => null);
    const settings = useState<Settings.AppSettings | null>('settings', () => null);
    const headerMenuItems = useState<Menus.HeaderItem[]>('headerMenuItems', () => []);
    const menusState = useState<Menus.MenusStateObject>('menusState', () => ({
        catalog: false,
        city: false,
        filters: false,
    }));

    const fetchDefaultCity = async () => {
        return await $fetch<Locations.City>(`${APIPrefix}/default-city`, {
            method: 'GET',
        });
    };

    const fetchHeaderMenuItems = async () => {
        const data = await $fetch<
            { [key in string]: any } & { menuItems: Menus.HeaderItem[] }
        >(`${APIPrefix}/menu/header`, {
            method: 'GET',
        });

        return data?.menuItems || [];
    };

    const fetchSettings = async () => {
        return await $fetch<Settings.AppSetting[]>(`${APIPrefix}/site-options`, {
            method: 'GET',
        });
    };

    const setSettings = (settingsArray: Settings.AppSetting[]) => {
        let newSettingsObject: Settings.AppSettings = {};

        for (const settingItem of settingsArray) {
            if (!newSettingsObject.hasOwnProperty(settingItem?.group)) {
                newSettingsObject[settingItem?.group] = {};
            }

            (
                newSettingsObject[settingItem?.group] as {
                    [x: string]: Settings.AppSetting;
                }
            )[settingItem?.key] = settingItem;
        }

        settings.value = newSettingsObject;
    };

    const setMenuState = (
        menuLabel: Menus.MenuLabel,
        value: boolean,
        options: { overlap?: boolean; zIndex?: number } = {}
    ) => {
        menusState.value[menuLabel] = value;

        if (value) {
            if (!options.overlap) {
                for (const key in menusState.value) {
                    if (key === menuLabel) continue;
                    menusState.value[key as Menus.MenuLabel] = false;
                }
            }

            if (options.zIndex) {
                document?.body.style.setProperty(
                    '--z-index',
                    options.zIndex.toString()
                );
            }

            document?.body.setAttribute(
                'data-dimmed',
                menuLabel.concat('-', Date.now().toString(32))
            );
        } else {
            document?.body.removeAttribute('data-dimmed');
            document?.body.style.removeProperty('--z-index');
        }
    };

    return {
        deviceType,
        locale,
        defaultCity,
        settings,
        menusState,
        headerMenuItems,
        fetchDefaultCity,
        fetchHeaderMenuItems,
        fetchSettings,
        setMenuState,
        setSettings,
    };
}
