<template>
  <svg 
    viewBox="0 0 24 24" 
    fill="none" 
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.8805 5.16455H16.1477C15.7873 5.16455 15.4951 5.45673 15.4951 5.81715V7.54993C15.4951 7.91035 15.7873 8.20253 16.1477 8.20253H17.8805C18.2409 8.20253 18.5331 7.91035 18.5331 7.54993V5.81715C18.5331 5.45673 18.2409 5.16455 17.8805 5.16455Z"
      fill="#4BC2C6"
    />
    <path
      d="M17.9004 1.82227H17.0328C16.6852 1.82227 16.4033 2.10411 16.4033 2.45179V3.31933C16.4033 3.667 16.6852 3.94885 17.0328 3.94885H17.9004C18.2481 3.94885 18.5299 3.667 18.5299 3.31933V2.45179C18.5299 2.10411 18.2481 1.82227 17.9004 1.82227Z"
      fill="#4BC2C6"
    />
    <path
      d="M20.6057 1.82227H20.1017C19.9053 1.82227 19.7461 1.98148 19.7461 2.17789V2.68183C19.7461 2.87824 19.9053 3.03746 20.1017 3.03746H20.6057C20.8021 3.03746 20.9613 2.87824 20.9613 2.68183V2.17789C20.9613 1.98148 20.8021 1.82227 20.6057 1.82227Z"
      fill="#4BC2C6"
    />
    <path
      d="M19.4276 0H18.8462C18.6712 0 18.5293 0.141864 18.5293 0.316861V0.898332C18.5293 1.07333 18.6712 1.21519 18.8462 1.21519H19.4276C19.6026 1.21519 19.7445 1.07333 19.7445 0.898332V0.316861C19.7445 0.141864 19.6026 0 19.4276 0Z"
      fill="#4BC2C6"
    />
    <path
      d="M21.2355 3.94971H20.0777C19.7263 3.94971 19.4414 4.23458 19.4414 4.58599V5.7438C19.4414 6.09521 19.7263 6.38009 20.0777 6.38009H21.2355C21.5869 6.38009 21.8718 6.09521 21.8718 5.7438V4.58599C21.8718 4.23458 21.5869 3.94971 21.2355 3.94971Z"
      fill="#4BC2C6"
    />
    <path
      d="M16.2107 10.5399H13.5644V7.81391C13.5644 7.59481 13.4799 7.38468 13.3295 7.22976C13.1791 7.07483 12.9751 6.98779 12.7624 6.98779H11.2375C11.0248 6.98779 10.8208 7.07483 10.6704 7.22976C10.52 7.38468 10.4355 7.59481 10.4355 7.81391V10.5399H7.78926C7.57657 10.5399 7.37259 10.627 7.22219 10.7819C7.0718 10.9368 6.9873 11.1469 6.9873 11.366V12.9369C6.9873 13.156 7.0718 13.3661 7.22219 13.521C7.37259 13.676 7.57657 13.763 7.78926 13.763H10.4355V16.489C10.4355 16.7083 10.52 16.9186 10.6703 17.0738C10.8207 17.229 11.0247 17.3164 11.2375 17.3169H12.7624C12.9753 17.3164 13.1792 17.229 13.3296 17.0738C13.4799 16.9186 13.5644 16.7083 13.5644 16.489V13.763H16.2107C16.4234 13.763 16.6273 13.676 16.7777 13.521C16.9281 13.3661 17.0126 13.156 17.0126 12.9369V11.366C17.0126 11.1469 16.9281 10.9368 16.7777 10.7819C16.6273 10.627 16.4234 10.5399 16.2107 10.5399Z"
      fill="#4BC2C6"
    />
    <path
      d="M23.1001 10.8367H19.9546C19.9138 10.8351 19.8735 10.846 19.8391 10.8681C19.8048 10.8901 19.778 10.9222 19.7625 10.9599C19.747 10.9977 19.7436 11.0393 19.7525 11.0791C19.7615 11.1189 19.7825 11.155 19.8128 11.1824L20.6341 12.0035L12 20.6252L3.37277 12.0017L12 3.37822L12.7799 4.15778C12.811 4.18941 12.8508 4.21105 12.8943 4.21993C12.9378 4.22882 12.9829 4.22454 13.0239 4.20766C13.0649 4.19077 13.1 4.16204 13.1246 4.12514C13.1492 4.08823 13.1623 4.04483 13.1621 4.00048V0.916833C13.1617 0.85781 13.1381 0.801308 13.0963 0.759538L12.5776 0.240987C12.4229 0.0866705 12.2133 0 11.9948 0C11.7763 0 11.5667 0.0866705 11.4121 0.240987L0.241094 11.4192C0.0867106 11.5738 0 11.7833 0 12.0017C0 12.2202 0.0867106 12.4297 0.241094 12.5842L11.4207 23.759C11.5753 23.9133 11.7849 24 12.0035 24C12.222 24 12.4316 23.9133 12.5862 23.759L23.7589 12.5842C23.9133 12.4297 24 12.2202 24 12.0017C24 11.7833 23.9133 11.5738 23.7589 11.4192L23.2401 10.9007C23.2222 10.8812 23.2006 10.8656 23.1765 10.8546C23.1525 10.8436 23.1265 10.8375 23.1001 10.8367Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient id="paint0_linear" x1="0" y1="11.9979" x2="24" y2="11.9979" gradientUnits="userSpaceOnUse">
        <stop stop-color="#EE3936"></stop>
        <stop offset="0.51" stop-color="#EE3936"></stop>
        <stop offset="1" stop-color="#F17880"></stop>
      </linearGradient>
    </defs>
  </svg>
</template>
